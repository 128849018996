import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController } from 'src/app/controller/alert.controller';
import { RoutesController } from 'src/app/controller/routes.controller';
import { CompraService } from 'src/app/services/compra.service';
import { EventService } from 'src/app/services/event.service';
import { ProductService } from 'src/app/services/product.service';
import { TrasladosService } from 'src/app/services/traslados.service';
import { UserService } from 'src/app/services/user.service';

declare var $: any;

@Component({
  selector: 'app-evento-detalle',
  templateUrl: './evento-detalle.component.html'
})
export class EventoDetalleComponent implements OnInit {


  //VARIABLES DE EVENTO
  event_id: number = 0;
  event_detail: any = null;
  user_event: any = null;

  //VARIABLES DE USUARIO
  id_user_logueado: string | null = localStorage.getItem('id');  // guarda el id del usuario logueado en ese momento
  permitido_seguir_sec: string | null = localStorage.getItem('permitido'); // la variable permite seguir la secuencia si 

  //VARIABLES PARA MOSTRAR LA SECUENCIA
  url: string = ""
  url_to_move: string = ""  //guarda la path a donde me quiero mover para seguir la secuencia del evento
  url_to_move_copy: string = ""  //guarda la path a donde me quiero mover para seguir la secuencia del evento

  initi_list: any[] = []   // guarda la informacion que quiero consultar, Áreas, eventos, productos, etc
  
  array_numSecuencia: any[] = []   // guarda el numero de secuencia que quiero ver en todo momento
  array_id_anterior: any[] = []   // guarda el id_anterior al que pertenecen las Áreas, categorias, productos, etc 
  array_path: any[] = []   // guarda la path que se relaciona con la secuencia, 1 = /area, 2 = /categoria; 3 = /product
  array_actual: any[] = []      // guara el id del elmento que estoy consultando actualmente
  array_titulo_secuencia: any[] = []  // guarda el texto de la secuencia a mostrarse
  array_secuencia_ant: any[] = []  // guarda el texto de la secuencia a mostrarse
  
  
  icono_mostrar: string = ""
  tipo_icono: string = ""
  secuencia: string = ""
  index: number = 0;
  mostrar_usuarios: boolean = false; // sirve para cuando estoy mostrando usuarios
  mostrar_productos: boolean = false; // sirve para cuando estoy mostrando usuarios
  mostrar_agregar: boolean = false;
  secuencia_anterior: number = 0;

  //VARIABLES PARA ENVIAR PRODUCTOS
  array_products: any[] = []   // guarda la lista de productos a enviar

  constructor(
    private activ_route: ActivatedRoute, private router: Router,
    private event_Service: EventService,
    private product_Service: ProductService,
    private traslado_service: TrasladosService,
    private compra_Service: CompraService,
    private user_service: UserService) {

    //OBTENGO EL ID DLE EVENTO
    this.activ_route.params.subscribe(param => {
      this.event_id = param['id'];
    });

    //OBTENGO EL EVENTO MEDIANTE SU ID
    this.event_Service.get_event_by_id(this.event_id).subscribe(data => {
      //SE GUARDA LOS DATOS DEL EVENTO
      this.event_detail = data;

      //SE INICIALIZA EL USUARIO PROPIETARIO DEL EVENTO
      this.user_service.get_user_by_id(this.event_detail.usuarios_id).subscribe(data => {
        this.user_event = data;
      });

      //SE ASIGNA LA SECUENCIA INICIAL
      if (this.event_detail.eventos_tipos_id == 1) {
        this.url_to_move = '/evento/' + this.event_id + '/no-usuario'
      } else {
        this.url_to_move = '/evento/' + this.event_id + '/multiusuario'
      }

      this.url_to_move_copy = this.url_to_move

      //SE INICIALIZA LA DATA 
      this.array_path[this.index] = ""
      this.secuencia_anterior = this.event_detail.secuencias_id;

      //SE INICIALIZA LA SECUENCIA QUE SIGUE EL EVENTO
      this.init_secuence(this.event_detail.secuencias_id, this.event_id, 0);

      this.armar_ruta(this.index)
    });

  }

  ngOnInit(): void {
  }


  // FUNCION  QUE AVANZA EN LA SECUENCIA 
  avanzar(sec: any, id_anterior: number) {
    this.index++
    this.array_path[this.index] = "/" + id_anterior
    this.init_secuence(sec, id_anterior, this.secuencia_anterior)
    this.armar_ruta(this.index)
    this.secuencia_anterior = sec

  }

  //FUNCION QUE REGRESA A LA SECUENCIA ANTERIOR
  regresar() {
    if (this.index > 0) {
      this.array_titulo_secuencia[this.index] = ''
      this.index--;
    }
    this.secuencia_anterior = this.array_numSecuencia[this.index]

    this.init_secuence(this.array_numSecuencia[this.index], this.array_id_anterior[this.index], this.array_secuencia_ant[this.index])
    this.armar_ruta(this.index)
  }


  init_secuence(sec: any, id_anterior: number, secuencia_anterior: number) {

    this.array_numSecuencia[this.index] = sec
    this.array_id_anterior[this.index] = id_anterior
    this.array_secuencia_ant[this.index] = secuencia_anterior

    if (sec == '1') {
      this.set_valores("Áreas", 'ti-bookmark', 'round-info', '/area', '> Area')
    }
    if (sec == '2') {
      this.set_valores("Categorías", 'ti-layers-alt', 'round-danger', this.get_pref_ant(this.secuencia_anterior, 'categoria', this.index), '> Categoría')
    }
    if (sec == '3') {
      this.set_valores("Productos", 'ti-shopping-cart-full', 'round-success', this.get_pref_ant(this.secuencia_anterior, 'producto', this.index), '> Producto')
      this.mostrar_productos = true
    }
    if (sec == '4') {
      this.set_valores("Usuarios", 'ti-user', 'round-warning', this.get_pref_ant(this.secuencia_anterior, 'usuario', this.index), '> Usuario')
      this.mostrar_usuarios = true
    }

    //LIMPIA EL ARREGLO A MOSTRAR
    this.initi_list = []

    // TRAE TODO LO QUE NO SEA PRODUCTO
    if (!this.mostrar_productos) {
      var id = id_anterior
      /*if (this.mostrar_usuarios) {
          id = this.event_id  
      }*/
      this.event_Service.get_data_secuencia(id, sec, secuencia_anterior).subscribe(data => {
        this.initi_list = data
      })
      // TRAE LOS PRODUCTOS
    }
    else {
      this.product_Service.get_product_by_id_anterior(id_anterior, secuencia_anterior).subscribe(data => {
        this.initi_list = data
      })
    }

  }

  /* 
      ////////////////////////////////
            FUNCIONES PARA ENVIAR PRODUCTO
      /////////////////////////////// 
  */

  //FUNCION QUE GUARDA LOS PRODUCTOS EN UN LISTADO
  save_product(product: any) {
    if (!(localStorage.getItem('currentUser'))) {
      this.router.navigate(['/login'])
      return
    }
    //VERIFICA QUE EL PRODUCTO NO EXISTA CON ANTERIORIDAD EN EL ARREGLO
    if (!(this.array_products.some(p => p.productos_id == product.id))) {

      let producto_compra = {
        productos_id: product.id,
        evento_id: this.event_id,
        usuarios_id: localStorage.getItem('id'),
        total: product.precio,
        total_ant: product.precio,
        estado: 1,
        fecha: RoutesController.getInstance().get_date(),
        titulo: product.titulo,
        img: product.img,
        cantidad: 1,
        cantidad_max: product.cantidad,
        precio: product.precio,
        compras_id: 0
      }
      this.array_products.push(producto_compra)

      AlertController.getInstance().show_swall('Producto Agregado', 'El producto ' + product.titulo + ' se agregó a la lista', 'success')

    } else {
      AlertController.getInstance().show_swall('Producto Existente', 'El producto ' + product.titulo + ' ya está agregado', 'error')
    }
  }

  //ELIMINA PRODUCTOS QUE VOY A ENVIAR
  delete_product(id_product: number) {
    this.array_products = this.array_products.filter(obj => obj.productos_id !== id_product);
  }

  //HACE UN UPDATE DEL TOTAL DE PRODUCTO EN FUNCION DE CANTIDAD
  update_total(cant: any, id_product: number) {
    //verifica que la cantidad no venga nula
    if (cant == '') {
      return
    }

    this.array_products.some(p => {
      //busca coincidencias de id
      if (p.productos_id == id_product) {
        //si el precio del producto esta en cero, lo resetea
        //es decir, en el inpu se envio un cero
        if (p.total == 0) {
          p.total = p.total_ant
        }
        p.cantidad = cant;
        p.total = Number(cant) * p.total_ant
      }
    });

  }
  /* 
      ////////////////////////////////
            FUNCIONES VARIAS
      /////////////////////////////// 
  */
  //OBTIENE EL PREFIJO DE LA URL EN FUNCION DE LA SECUENCIA ANTERIOR,
  // ES DECIR, SI ME DIRIJO A PRODUCTOS, SI VENGO DE CATEGORIA, ENTONCES
  // SEC = 2 Y RETORNA LA NUEVA PORCION PARA URL, DONDE
  // name = category, area o user
  get_pref_ant(sec: any, name: string, index: number): string {
    if (index != 0) {
      if (sec == '1') {
        return '/a_' + name
      }
      if (sec == '2') {
        return '/c_' + name
      }
      if (sec == '4') {
        return '/u_' + name
      }
    } else {
      if (sec == '4') {
        return '/e' + name
      }
      return '/' + name
    }
    return '';
  }

  /*
  esta funcion se usa para guardar valores dentro de los arreglos que sirven
  para mostrar la secuencia de los productos
  */
  set_valores(sec: string, icon: string, icon_tipo: string, path: string, sec2: string) {
    this.secuencia = sec
    this.set_icono(icon, icon_tipo)
    this.array_actual[this.index] = path
    this.array_titulo_secuencia[this.index] = sec2
    this.mostrar_usuarios = false;
    this.mostrar_productos = false;

  }



  //establece el icono para mostrar en la secuencia
  set_icono(icono: string, tipo: string) {
    this.icono_mostrar = icono
    this.tipo_icono = tipo
  }

  /*SIRVE PARA AGREGAR MAS ELEMENTOS A LA SECUENCIA ACTUAL A DONDE ESTOY */
  agregar() {

    this.router.navigate([this.url_to_move])
  }

  /* SIRVE PARA ARMAR LA RUTA HACIA DONDE ME VOY A MOVER*/
  // ES DECIR, AL HACER CLICK SOBRE 'AGREGAR' SE VA A DIRIGIR A LA RUTA QUE SE ARME CON ESTA FUNCION

  armar_ruta(limit: number) {
    this.url_to_move = this.url_to_move_copy
    for (let i = 0; i <= limit; i++) {
      this.url_to_move = this.url_to_move + this.array_path[i] + this.array_actual[i];
    }

  }



  /*
    ///////////////////////////////////////////
                SECCION DE TRANSFERENCIA
    ///////////////////////////////////////////
  */

  error_tarjeta = true;
  usuario_tarjeta: any;
  total_dinero = 0
  fecha_actual = RoutesController.getInstance().get_date()
  verify_credit_card(user: string, card: any, date1: string, date2: string, cvv: string) {
    if (user == '' || card == '' || date1 == '' || date2 == '' || cvv == '') {
      AlertController.getInstance().show_swall('Error', 'Debe llenar todos los campos', 'error')
      return
    }
    this.usuario_tarjeta = { 'nombre': user, 'tarjeta': card, 'expira': date1 + '/' + date2 };

    AlertController.getInstance().show_swall('Tarjeta válida', 'Datos de tarjeta verificados correctamente', 'success')
    this.error_tarjeta = false

  }

  update_total_dinero() {
    this.total_dinero = 0
    for (let i = 0; i < this.array_products.length; i++) {
      const producto = this.array_products[i];
      this.total_dinero = Number(this.total_dinero) + Number(producto.total);
    }
  }



  send_product() {

    for (let i = 0; i < this.array_products.length; i++) {
      const product = this.array_products[i];

      // SE ENVIAN LOS PRODUCTOS A LA COMPRA
      this.compra_Service.post(JSON.stringify(product)).subscribe(
        (data: any) => {

          product.compras_id = data.id
          // SE HACE EL DETALLE DE LA COMPRA
          this.compra_Service.post_compra_detalle(JSON.stringify(product)).subscribe(
            (data) => {


            });
        },
        (err) => {
          AlertController.getInstance().show_swall('Error', 'Ha ocurrido un error, por favor, intentelo de nuevo más tarde.', 'error')
        });

      var update_product: any = {
        id: product.productos_id,
        cantidad: String(product.cantidad_max - product.cantidad),
        fecha: RoutesController.getInstance().get_date()
      }
      if (product.cantidad_max - product.cantidad == 0) {
        update_product['estado'] = 2
      }


      this.product_Service.put(update_product, product.productos_id).subscribe((data) => { console.log(data) })
    }

    //SE ACTUALIZA LA CANTIDAD RECAUDADA DEL EVENTO
    this.event_Service.put({ 'recaudado': (Number(this.event_detail.recaudado) + this.total_dinero)  }, this.event_id).subscribe(data=>{
    
    })
    var recaudado = {
      id_eventos: this.event_id,
      monto: this.total_dinero,
      fecha: RoutesController.getInstance().get_date(),
      hora: RoutesController.getInstance().get_hour(),
      estado: 1     // ESTADO 1 = RECAUDADO
    }
    this.traslado_service.post(recaudado).subscribe(data=>{
    })

    this.array_products = [];
    $('#modal_envio').modal('hide');
    AlertController.getInstance().show_swall('Productos enviados', 'Los productos seleccionados se han enviado con exito', 'success')
  }
}
