import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AlertController } from 'src/app/controller/alert.controller';
import { RoutesController } from 'src/app/controller/routes.controller';
import { AuthenticationService } from 'src/app/services/auth.service';
import { UploadFileService } from 'src/app/services/upload-file.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html'
})
export class ProfileComponent implements OnInit {
  //VARIABLES
  user_logueado: any;
  fecha_nac: string = "";
  user_form: any = {};
  file: any = null;
  strImage: any;
  img_subida: boolean = true;
  
  constructor(
    private auth_service: AuthenticationService,
    private upload_service: UploadFileService,
    private user_service: UserService) {
    this.initialize()
  }

  ngOnInit(): void {
    this.fecha_nac = (String(this.user_logueado.fecha_nac).split('T'))[0]
  }

  initialize() {
    this.user_logueado = JSON.parse(String(localStorage.getItem('currentUser')));
  }

  //CARGAR IMAGEN
  seleccionImage(archivo: any) {
    this.file = archivo.target.files[0]
    if (!(this.file)) {
      return;
    }
    let reader = new FileReader();
    reader.readAsDataURL(this.file);
    reader.onloadend = () => this.strImage = reader.result;

  }


  upload_img(){
    AlertController.getInstance().show_swall('Subiendo fotografía', 'Espere un momento por favor.', 'warning')
    this.img_subida = false;
    this.upload_service.post(this.file, 'test/categoria')
    .then((res: any) => {
      AlertController.getInstance().show_swall('Fotografía guardada', 'Su fotografía fue subida con éxito', 'success')
      this.img_subida = true;
      this.user_form['img'] = res.secure_url
    })
    .catch((err) => {
      console.log(err)
      AlertController.getInstance().show_swall('Error', 'Hubo un error al subir su fotografía', 'error')
    });
  }


  update(nombres: string, apellidos: string, email: string, password: string, telefono: string, fecha: string) {

    var fech = String(this.user_logueado.fecha_nac).split('T')

    this.user_form['nombres'] = this.replace_data(nombres, this.user_logueado.nombres);
    this.user_form['apellidos'] = this.replace_data(apellidos, this.user_logueado.apellidos);
    this.user_form['email'] = this.replace_data(email, this.user_logueado.email);
    this.user_form['numero'] = this.replace_data(telefono, this.user_logueado.numero);
    this.user_form['fecha_nac'] = this.replace_data(fecha, fech[0])
    this.user_form['fecha'] = RoutesController.getInstance().get_date()
    this.user_form['hora'] = RoutesController.getInstance().get_hour()


    if (password != '') {
      this.user_form['pass'] = password
    }
    AlertController.getInstance().show_swall('Actualizando sus datos', 'Espere un momento por favor.', 'warning')
    this.user_service.put(this.user_form, this.user_logueado.id).subscribe(
      (data) => {
        AlertController.getInstance().show_swall('Datos actualizados', 'Sus datos se han actualizado correctamente', 'success')
        this.user_service.get_user_by_id(this.user_logueado.id).subscribe(
          (data: any) => {
            this.auth_service.save_data(data, this.user_logueado.id, this.user_logueado.tipo_id)
            this.initialize();
            this.file = null;
          }
        );
      },
      (err) => {
        AlertController.getInstance().show_swall('Error', 'Se ha producido un error al actualizar datos, inténtelo de nuevo más tarde', 'error')
      }
    );

  }

  //SE ENCARGA DE VERIFICAR SI EL PARAMETRO VIENE VACIO
  //RETORNA EL DATO CON EL VALOR DE REEMPLAZO
  replace_data(data: string, data_to_replace: string): string {
    if (data == '') {
      data = data_to_replace
    }
    return data
  }
}
