<!-- ============================================================== -->
    <!-- Main wrapper - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <section id="wrapper">
        <div class="login-register" style="background-image:url(assets/images/background/recovery-pass-wall.jpg);">
            <div class="login-box card">
                <div class="card-body" *ngIf="recovery_form">
                    <form class="form-horizontal form-material" [formGroup]="recovery_form" role="form" data-parsley-validate="" novalidate="">
                        <a [routerLink]="['/login']" routerLinkActive="router-link-active"  class="text-center db"><img src="../../../assets/images/logo.png" width="50%" height="50%" alt="Home" /></a>
                        <br>
                        <h3 class="box-title m-b-20">Recuperar Contraseña</h3>
                        <br>
                        <div class="form-group">
                            <div class="col-xs-12">
                                <input class="form-control" formControlName="email" type="email" required="" placeholder="Correo">
                            </div>
                            <div *ngIf="f.email.invalid && (f.email.dirty || f.email.touched)">
                                <div *ngIf="f.email.errors.required" style="color:red">El correo es requerido</div>
                            </div>
                        </div>
                        <div class="form-group text-center p-b-10">
                            <div class="col-xs-12">
                                <button [disabled]="recovery_form.invalid" (click)="onSubmit()" class="btn btn-warning btn-lg btn-block text-uppercase waves-effect waves-light" type="submit">Recuperar</button>
                            </div>
                        </div>
                        <div class="form-group m-b-0">
                            <div class="col-sm-12 text-center">
                                <p>¿Ya tienes una cuenta? <a [routerLink]="['/login']" routerLinkActive="router-link-active"  class="text-warning m-l-5"><b>Acceder</b></a></p>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>