import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController } from 'src/app/controller/alert.controller';
import { RoutesController } from 'src/app/controller/routes.controller';
import { AreaService } from 'src/app/services/area.service';
import { UploadFileService } from 'src/app/services/upload-file.service';
//JQUERY
declare var $: any;
@Component({
  selector: 'app-area-evento',
  templateUrl: './area-evento.component.html'
})
export class AreaEventoComponent implements OnInit {
  //VARIABLES GENERALES
  id_evento: number = 0;    //evento de donde viene
  areas_list: any[] = []  //listado de areas
  opciones_ruta: any[] = []; //guarda las opciones de ruta a partir de donde estoy
  searchText: any;        //sirve para buscar areas en la vista
  area_name: string = "";
  area_id: number = 0;
  //VARIABLES DEL FORMULARIO DE AREA
  areaForm: any;
  formDataDelete: any;
  //VARIABLES PARA EDITAR AREA
  estoy_edit: number = 0; // bandera para saber si estoy editando o agregando

  file: any = null;
  strImage: any = "";
  subiendo_imagen: boolean = false;

  //CONSTRUCTOR
  constructor(
    private formBuilder: FormBuilder,
    private activ_route: ActivatedRoute,
    private upload_service: UploadFileService,
    private area_service: AreaService,
    private router: Router) {

    this.activ_route.params.subscribe(param => {
      // SE OBTIENE EL ID DEL EVENTO 
      this.id_evento = param['id_event']

      //SE INICIALIZA LA INFORMACION
      this.inicialize()

      //OPCIONES DE RUTA
      this.opciones_ruta = RoutesController.getInstance().evaluate_route(this.router.url, param)
    });
  }

  ngOnInit(): void {
    this.reset_form('', '', '', this.id_evento, 0, 'https://res.cloudinary.com/dksstmpfu/image/upload/v1609780356/test/categoria/hs7u8jzomikuupjlbn4h.jpg', 1, 0, 0)
    this.formDataDelete = new FormGroup({
      'nombreConfirm': new FormControl('', [Validators.required]),
    });
  }

  ////    SECCION INICIALIZAR DATOS
  inicialize() {

    this.area_service.get_all_area_by_event(this.id_evento).subscribe(data => {
      this.areas_list = data
      if (this.areas_list.length == 0) {
        AlertController.getInstance().show_swall("Alerta", "No hay datos disponibles, agrege áreas en '+ Nueva Área'", "warning")
      }
    });
  }

  /*//////////////////////////////////
      SECCION DE INSERSION DE DATOS
  ///////////////////////////////*/

  //FUNCION DE VALIDACION

  get f() { // Retorna los controles del formulario
    // que contiene la informacion para validar 
    return this.areaForm.controls;
  }

  //Guarda el area en la db 
  save_area() {

    if (this.estoy_edit == 0) {
      this.area_service.post_area(JSON.stringify(this.areaForm.value)).subscribe(
        (data) => {
          AlertController.getInstance().show_swall("Área agregada", "Se ha ingresado el área correctamente", "success");
          this.inicialize()
        },
        (err) => {
          AlertController.getInstance().show_error();
        });
    } else {

      this.area_service.put_area(JSON.stringify(this.areaForm.value), this.area_id).subscribe(
        (data) => {
          AlertController.getInstance().show_swall("Área Editada", "Se ha editado el área correctamente", "success");
          this.inicialize()
        },
        (err) => {
          AlertController.getInstance().show_error()
        })
    }
    $('#modal_area').modal('hide');
  }
  /*
    ///////////////////////////
      SECCION DE ELIMINACION
    //////////////////////////
  */

  data_to_delete(id: number, titulo: string) {
    this.area_id = id;
    this.area_name = titulo;
    this.formDataDelete.reset();
  }


  delete_area() {
    this.area_service.delete_area(this.area_id).subscribe(
      (res) => {
        this.inicialize()
        AlertController.getInstance().show_swall("Área Eliminada", "Se ha eliminado el área correctamente", "success");
        $('#modal-eliminar').modal('hide');
      },
      (err) => {
        AlertController.getInstance().show_error();
      })
  }
  /*
    ///////////////////////////
      SECCION DE RUTAS
    //////////////////////////
  */

  get_ruta(id_area: number, secuencia_next: number) {
    this.router.navigate([RoutesController.getInstance().get_ruta(this.router.url, id_area, secuencia_next, 'a_')])
  }

  //limpia el formulario
  reset_form(titu: string, desc: string, sec: string, busc: any, sec_ing: any, img1: string, estado: number, action: number, id_to_edit: number) {
    //limpia las variables  
    this.file = null;
    this.strImage = img1
    this.estoy_edit = action;
    this.area_id = id_to_edit
    // Inicializa el formulario
    this.areaForm = this.formBuilder.group({
      titulo: [titu, Validators.required],
      descripcion: [desc, Validators.required],
      img: [this.strImage],
      buscar_id: [busc],
      secuencias_ing_id: [sec_ing],
      secuencias_egr_id: [sec, Validators.required],
      estado: [estado],
      fecha: [RoutesController.getInstance().get_date()]
    });
  }


  //CARGAR IMAGEN
  seleccionImage(archivo: any) {

    if (!(archivo.target.files[0])) {
      return;
    }
    this.file = archivo.target.files[0]
    let reader = new FileReader();
    reader.readAsDataURL(this.file);
    reader.onloadend = () => this.strImage = reader.result;
  }
  uploadImage() {
    AlertController.getInstance().show_swall('Subiendo Imagen', 'Espere por favor', "warning")
    this.subiendo_imagen = true;
    this.upload_service.post(this.file, 'test/categoria')
      .then((res: any) => {
        AlertController.getInstance().show_swall('Imagen subida', 'La imagen se subió con éxito', "success")
        this.areaForm.get('img').setValue(res.secure_url);
        this.subiendo_imagen = false;
      })
      .catch((resp) => {
        AlertController.getInstance().show_error()
        this.subiendo_imagen = false;
      });
  }
}
