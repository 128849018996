<div class="row page-titles">
    <div class="col-md-5 align-self-center">
        <h3 class="text-themecolor">Listado de Eventos</h3>
    </div>
    <div class="col-md-7 align-self-center">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard/home']" routerLinkActive="router-link-active" >Home</a></li>
            <li class="breadcrumb-item active">Ver Eventos</li>
        </ol>
    </div>
</div>
<div class="row" *ngIf="event_list">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="d-flex">
                    <div class="align-self-center">
                        <h4 class="card-title m-t-10">Eventos Actuales</h4></div>
                    <div class="ml-auto">
                        <input type="text" id="demo-input-search2" placeholder="Buscar evento" [(ngModel)]="searchText" class="form-control"> 
                    </div>
                </div>
                <br>
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Titulo</th>
                                <th>Tipo de Evento</th>
                                <th>Secuencia</th>
                                <th>Estado</th>
                                <th>Hora Registro</th>
                                <th>Fecha Registro</th>
                                <th class="text-nowrap">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let event of event_list | filter:searchText | paginate: { itemsPerPage: 10, currentPage: p }">
                                <td>{{ event.titulo }}</td>
                                <td>
                                    <span *ngIf="event.eventos_tipos_id == 1" class="label label-info">Sin Usuarios</span>
                                    <span *ngIf="event.eventos_tipos_id == 2" class="label label-success">Multiusuario</span>
                                </td>
                                <td>
                                    <span *ngIf="event.secuencias_id == 1" class="label label-primary">Area</span>
                                    <span *ngIf="event.secuencias_id == 2" class="label label-danger">Categoria</span>
                                    <span *ngIf="event.secuencias_id == 3" class="label label-warning">Producto</span>
                                    <span *ngIf="event.secuencias_id == 4" class="label label-inverse">Usuarios</span>
                                </td>
                                <td>
                                    <span *ngIf="event.estado == 1" class="label label-warning">Activo</span>
                                    <span *ngIf="event.estado == 2" class="label label-danger">Inactivo</span>
                                </td>
                                <td>{{ event.hora }}</td>
                                <td>{{ event.fecha | date : 'medium' }}</td>
                                <td class="text-nowrap">

                                    <button type="button" class="btn btn-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="ti-settings"></i>
                                    </button>
                                    <div class="dropdown-menu">
                                        <button class="dropdown-item" (click)="detail_event(event.id)" ><i class="ti-pencil"></i> Detalles</button>
                                        <button class="dropdown-item" (click)="delete_event(event.id)"><i class="ti-trash"></i> Eliminar </button>
                                        <button class="dropdown-item" *ngIf="event.estado == 1" (click)="activ_noActiv(event.estado, event.id)"><i class="ti-reload"></i> Desactivar </button>
                                        <button class="dropdown-item" *ngIf="event.estado == 2" (click)="activ_noActiv(event.estado, event.id)"><i class="ti-reload"></i> Activar </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>                        
                    </table>
                    <div class="text-right">
                        <pagination-controls (pageChange)="p = $event"></pagination-controls>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>

