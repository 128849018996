

<div class="row" >
    <!-- Column -->
    <div class="col-lg-4 col-xlg-3 col-md-5">
        <div class="card">
            <div class="card-body">
                <div class="card-body little-profile text-center">
                    <div class="pro-img m-t-20"><img src="{{ user_logueado.img }}" alt="{{ user_logueado.nombres }}"></div>
                    <h4 class="card-title m-t-10">{{ user_logueado.nombres }} {{ user_logueado.apellidos }}</h4>
                    <h6 *ngIf="user_logueado.tipo_id == 1" class="card-subtitle">Administrador</h6>
                    <h6 *ngIf="user_logueado.tipo_id == 2" class="card-subtitle">Cliente</h6>
                            
                </div>
            </div>
            
            <div>
                <hr> </div>
            <div class="card-body"> <small class="text-muted">Dirección Email</small>
                <h6>{{ user_logueado.email }}</h6> 
                <small class="text-muted p-t-30 db">Teléfono</small>
                <h6>(502) {{ user_logueado.numero }}</h6> 
                <small class="text-muted p-t-30 db">Fecha de nacimiento</small>
                <h6> {{ user_logueado.fecha_nac | date: 'short'}}</h6>
            </div>
        </div>
    </div>
    <!-- Column -->
    <!-- Column -->
    <div class="col-lg-8 col-xlg-9 col-md-7">
        <div class="card">
            <div class="card-body">
                <form class="form-horizontal form-material">
                    <div class="row">
                        <div class="col-lg-4">
                             
                        </div>
                        <div class="col-lg-6">
                            <div class="el-element-overlay"  style="margin-bottom: 0px !important;">
                                <div class="col-md-2">
                                </div>
                                <div class="col-md-8">
                                    <div class="card" style="margin-bottom: 0px !important;">
                                        <div class="el-card-item"  style="margin-bottom: 0px !important;">
                                            <div class="el-card-avatar el-overlay-1"  style="margin-bottom: 0px !important;"> 
                                                <img *ngIf="!file"
                                                src="assets/images/default-user.png" style="width: 50 !important;"
                                                alt="Usuario" />
                                            <img *ngIf="file" src="{{strImage}}" alt="area" />
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="col-md-2">
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2">

                        </div>
                    </div>
                
                    <div class="form-group text-center">
                        <label class="col-sm-12">Foto de Perfil</label>
                        <div class="col-md-12">
                            <div class="fileupload btn btn-danger btn-rounded waves-effect waves-light"><span><i
                                class="fa fa-camera"></i> Sube tu fotografia</span>
                            <input type="file" accept="image/*"  (change)="seleccionImage($event)"  class="upload"> </div>
                        </div>
                        <hr>
                    </div>

                    <div class="form-group">
                        <label class="col-md-12">Nombres</label>
                        <div class="col-md-12">
                            <input type="text" #nombres value="{{ user_logueado.nombres }}" class="form-control form-control-line">
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="col-md-12">Apellidos</label>
                        <div class="col-md-12">
                            <input type="text" #apellidos value="{{ user_logueado.apellidos }}" class="form-control form-control-line">
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="example-email" class="col-md-12">Email</label>
                        <div class="col-md-12">
                            <input type="email" #email value="{{ user_logueado.email }}" class="form-control form-control-line" name="example-email" id="example-email">
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="col-md-12">Password</label>
                        <div class="col-md-12">
                            <input type="password" #pass placeholder="*****" class="form-control form-control-line">
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="col-md-12">Número de Teléfono</label>
                        <div class="col-md-12">
                            <input type="text" #tel value="{{ user_logueado.numero }}" class="form-control form-control-line">
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="col-md-12">Fecha de nacimiento</label>
                        <div class="col-md-12">
                            <input type="date" #fecha  value="{{ fecha_nac }}" class="form-control form-control-line">
                        </div>
                    </div>
                    
                    <div class="form-group text-center">
                        <div class="btn btn-group">
                            <button (click)="upload_img()" [disabled]="!file" class="btn btn-primary waves-effect waves-light" type="button"><span class="btn-label"><i class="ti-save-alt"></i></span>Subir Fotografía</button>                                
                            <button  [disabled]="!img_subida" (click)="update(nombres.value, apellidos.value, email.value, pass.value, tel.value, fecha.value)" class="btn btn-warning waves-effect waves-light" type="button"><span class="btn-label"><i class="ti-reload"></i></span>Actualizar Perfil</button>                                
                     
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <!-- Column -->
</div>