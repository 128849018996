import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertController } from 'src/app/controller/alert.controller';
import { EventService } from 'src/app/services/event.service';

declare var $: any;

@Component({
  selector: 'app-mis-eventos',
  templateUrl: './mis-eventos.component.html'
})
export class MisEventosComponent implements OnInit {

  //VARIABLES
  event_list:     any[] = [] //variable que guarda los eventos propios
  searchText:     any    //variable usada para buscar los eventos
  evento_name:    string = ""
  evento_id:      number = 0;
  formDataDelete: any;
  constructor(
    private event_service: EventService,
    private router: Router) {
    this.inicialize()
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm(){
    this.formDataDelete = new FormGroup({
      'nombreConfirm': new FormControl('', [Validators.required]),
    });
  }
  inicialize() { //Inicializa la data de los eventos
    this.event_service.get_my_events(Number(localStorage.getItem('id'))).subscribe(data => {
      this.event_list = data
      if (this.event_list.length == 0) {
        AlertController.getInstance().show_swall('No hay datos disponibles','No se ha creado ningún evento','warning')
      }
    });
  }

  /* BORRAR EVENTO*/
  data_to_delete(id: number, titulo: string){
    this.evento_id = id;
    this.evento_name = titulo;
    this.formDataDelete.reset();
  }

  delete_event() {
    $('#modal-eliminar').modal('hide');
    if (this.evento_id != 0) {
      this.event_service.delete_by_id(this.evento_id).subscribe(
        (res) => {
  
          this.inicialize()
          AlertController.getInstance().show_swall("Evento Eliminado", "El evento se eliminó correctamente", "success");
  
        },
        (err) => {
          AlertController.getInstance().show_error();
        })
    }
  }

  /*EDITAR EVENTO*/
  update_event(id_evento: number) {
    this.router.navigate(['evento/', id_evento])
  }
}
