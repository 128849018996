import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertController } from 'src/app/controller/alert.controller';
import { RoutesController } from 'src/app/controller/routes.controller';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-lista-usuarios',
  templateUrl: './lista-usuarios.component.html'
})
export class ListaUsuariosComponent implements OnInit {

  //VARIABLES
  user_list:      any[] = []
  user_asig_list: any[] = []
  es_admin:       boolean = false;
  searchText:     any;
  id_sec:       number = 0;
  id_ant:         number = 0;

  constructor(
    private activ_route: ActivatedRoute,
    private user_service: UserService) {
    if (localStorage.getItem('admin') == "1") {
      this.es_admin = true;
    }
    this.activ_route.params.subscribe(param => {
      this.id_sec = param['id_sec']
      this.id_ant = param['id_ant']
      console.log(this.id_ant)
    })
    //INICIALIZA LA DATA
    this.initialize()
  }


  ngOnInit(): void {
  }

  initialize() {
    this.user_list = [];
    //OBTENGO LOS USUARIOS
    this.user_service.get_all().subscribe(
      (data: any[]) => {
        data.filter(p => {

          //FILTRA LOS USUARIOS POR TIPO Y ESTADO
          var condition = false;
          if (this.es_admin) {
            condition = p.tipo_id != 1
          } else {
            condition = p.tipo_id != 1 && p.estado == 1
          }
          if (condition) {
            this.user_list.push(p)
          }
        })
      }
    );
      //TRAE LAS ASIGNACIONES
    this.get_asignations();
  }

  get_asignations(){
     //TRAE LAS ASIGNACIONES DE LA DB
     this.user_service.get_all_user_asig().subscribe(data => {
      this.user_asig_list = data;
    });
  }


  /*
    ///////////////////////////////////////////////////////
              
                SECCION DE ASIGNACION DE SUARIOS 
    
    ///////////////////////////////////////////////////////
    */
  asignate_user(id_user: number, secuence: number) {
    var asig = {
      usuarios_id: id_user,
      buscar_id: this.id_ant,
      secuencias_ing_id: this.id_sec,
      secuencias_egr_id: secuence,
      estado: 1,
      fecha: RoutesController.getInstance().get_date()
    }
    var existe = false;
    this.user_asig_list.some(p => {
      if (p.usuarios_id == id_user && p.buscar_id == this.id_ant) {
        existe = true;
      }
    })

    if (!existe) {
      this.user_service.post_user_asig(asig).subscribe(
        (data) => {
          this.get_asignations();
          AlertController.getInstance().show_swall('Usuario asignado', 'El usuario se asignó al evento seleccionado', 'success')
        },
        (err) => {
          AlertController.getInstance().show_swall('Error', 'No se ha podido completar la asigación', 'error')
        })
    } else {
      AlertController.getInstance().show_swall('Usuario ya asignado', 'El usuario ya ha sido asignado al evento seleccionado', 'warning')
    }
  }

  /*///////////////////////////////////////////////////////        
              SECCION DE ELIMINACION DE DATOS 
  ///////////////////////////////////////////////////////*/

  delete_user(id_user: number) {
    this.user_service.delete(id_user, 0).subscribe(
      (data) => {
        this.initialize()
        AlertController.getInstance().show_swall("Usuario Eliminado", "El usuario se eliminó correctamente", "success");
      },
      (err) => {
        AlertController.getInstance().show_error()
      })
  }

}
