import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError} from 'rxjs/operators';



@Injectable()
export class UserService {

    //VARIABLES
    private URI_USUARIO = 'https://foxylabs-eventos.herokuapp.com/api/usuarios';
    private URI_USUARIO_TIPOS = 'https://foxylabs-eventos.herokuapp.com/api/usuarios_tipos'
    private URI_USUARIO_ASIGNACION = 'https://foxylabs-eventos.herokuapp.com/api/usuarios_asignacion'
    private URI_USUARIO_MULTIUSUARIO = 'https://foxylabs-eventos.herokuapp.com/api/secuencia/secuencia/4'
    


    constructor(private http: HttpClient) {

    }

    //HTTP OPTIONS
    private httpOptions = {

        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
    };

    //HANDLE ERROR
    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
        console.error('Un error ha ocurrido:', error.error.message);
        } else {
        console.error(
        `Backend returned code ${error.status}, ` +
        `body was: `, error.error);
        }
        return throwError(
        error);
    };




    // SECCION USUARIO

    get_all(){
        return this.http.get<any>(`${this.URI_USUARIO}/get_all`).pipe(map(data => {
            return data;
        }));
    }
    get_user_by_id_anterior(id_anterior: number, secuencia_ingreso: number)
    {
        return this.http.get<any>(`${ this.URI_USUARIO_MULTIUSUARIO }/secuencia_anterior/${ secuencia_ingreso }/buscar/${ id_anterior }`).pipe(map(data => {
            return data;
        }));    
    }

    get_user_by_id(id_user:number){

        return this.http.get<any>(`${this.URI_USUARIO}/get_${id_user}`).pipe(map(data => {
            return data;
        }));    
    }
   
    post(data:any) {
              
        return this.http.post(`${this.URI_USUARIO}/add`, data, this.httpOptions)
        .pipe(map(
            (data) => {
                return data;
            }, (err: any)=> {
                catchError(this.handleError)
        }));
    }

    put(data:any, id_user: number) {
              
        return this.http.put(`${this.URI_USUARIO}/update_${ id_user }`, data, this.httpOptions)
        .pipe(map(
            (data) => {
                return data;
            }, (err: any)=> {
                catchError(this.handleError)
        }));
    }

    //DELETE
    delete(id: number, tipo: number) {
        var uri = this.URI_USUARIO
        if (tipo != 0) {
            uri = this.URI_USUARIO_ASIGNACION
        }
        return this.http.delete(`${uri}/delete_${id}`, this.httpOptions).pipe(map(data => {
            return data;
        }));
    }
    // SECCION USUARIO TIPO



    // SECCION USUARIO-ASIGNACION
    post_user_asig(data: any){
        return this.http.post(`${this.URI_USUARIO_ASIGNACION}/add`, data, this.httpOptions)
        .pipe(map(
            (data) => {
                return data;
            }, (err: any)=> {
                catchError(this.handleError)
        }));

    } 
    get_all_user_asig(){
        return this.http.get<any>(`${this.URI_USUARIO_ASIGNACION}/get_all`).pipe(map(data => {
            return data;
        }));
    }

}