import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError} from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';

import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database';

@Injectable()
export class AuthenticationService {

    
    URI = 'https://foxylabs-eventos.herokuapp.com/api/usuarios/login';
    URI_RECOVERY = 'https://foxylabs-eventos.herokuapp.com/api/usuarios/req_pass'
    
    constructor(private http: HttpClient, private auth: AngularFireAuth) {
    }

    //HTTP OPTIONS
    private httpOptions = {

        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    //HANDLE ERROR
    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('Un error ha ocurrido:', error.error.message);
        } else {
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: `, error.error);
        }
        return throwError(
            error);
    };
    
    login(post_email: string, post_pass: string) {
        //PETICION POST 
        return this.http.post<any>( this.URI, { email: post_email, pass: post_pass })
            .pipe(map(user => {
                this.save_data(user, user.id, user.tipo_id)
                return user;
            }));            
    }

    recovery_password(email: string){
        
        return this.http.put(`${this.URI_RECOVERY}`, email, this.httpOptions)
        .pipe(map(
            (data) => {
                return data;
            }, 
            (err: any)=> {
                catchError(this.handleError)
        }));
    }

    logout() {
        // limpia la sesion
        localStorage.clear();
    }



    login_with_google() {
        return firebase.default.auth().signInWithPopup(new firebase.default.auth.GoogleAuthProvider())
    }

    

    login_with_facebook() {
        return firebase.default.auth().signInWithPopup(new firebase.default.auth.FacebookAuthProvider())
    }
      

    save_data(user: any, id: any, type: any){
        localStorage.clear();
        localStorage.setItem('currentUser', JSON.stringify(user));
        localStorage.setItem('id', id);
        if (type == 1) {
            localStorage.setItem('admin', '1')
        } else {
            localStorage.setItem('admin', '2')    
        }
    }


    login_with_token(data: any) {
        return this.http.get<any>('https://foxylabs-eventos.herokuapp.com/api/usuarios/tocken_get', data).pipe(map(data => {
            return data;
        }));    
    }
}