<div class="row">
    <div class="col-12">
        <div class="card">
            <!-- .left-right-aside-column-->
            <div class="event-page-aside">
                <div class="right-aside ">
                    <div class="right-page-header">
                        <div class="d-flex">
                            <div class="align-self-center">
                                <h4 class="card-title m-t-10">Lista de áreas </h4>
                            </div>
                            <div class="ml-auto">
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Buscar área"
                                        [(ngModel)]="searchText">
                                    <span class="input-group-btn">
                                        <button type="button" class="btn btn-warning" (click)="estoy_edit = 0; reset_form('', '', '', this.id_evento, 0, 'https://res.cloudinary.com/dksstmpfu/image/upload/v1609780356/test/categoria/hs7u8jzomikuupjlbn4h.jpg', 1, 0, 0)"
                                            data-toggle="modal" data-target="#modal_area"><i class="ti-plus"></i> Nueva Área</button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- /.left-right-aside-column-->
                </div>
            </div>
        </div>
    </div>
</div>



<div class="row">
    <div class="col-12 m-t-30">
        <div class="card-columns el-element-overlay">
            <div *ngFor="let area of areas_list | filter:searchText">
                <div class="card">
                    <div class="el-card-item">
                        <div class="el-card-avatar el-overlay-1">
                            <a class="image-popup-vertical-fit"> <img src="{{ area.img }}" alt="Area" /> </a>
                            <div class="el-overlay">
                                <ul class="el-info">
                                    <li><a class="btn default btn-outline" (click)="data_to_delete(area.id, area.titulo)" data-toggle="modal" data-target="#modal-eliminar"><i
                                                class="icon-trash"></i></a></li>
                                    <li>
                                        <a class="btn default btn-outline" data-toggle="modal" data-target="#modal_area"
                                            (click)="reset_form(area.titulo, area.descripcion, area.secuencias_egr_id, area.buscar_id, area.secuencias_ing_id, area.img, area.estado, 1, area.id)">
                                            <i class="icon-magnifier-add"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="el-card-content">
                            <h4> {{ area.titulo }} </h4>
                            <div class="card-body">
                                {{ area.descripcion }}
                            </div>
                            <small *ngIf="area.estado == 1"><span
                                class="label label-warning label-rounded">Habilitada</span></small>
                            <small *ngIf="area.estado == 2"><span
                                    class="label label-danger label-rounded">Deshabilitada</span></small>
                            <br>
                            
                            <hr>
                            <button class="btn btn-warning btn-sm" (click)="get_ruta(area.id, area.secuencias_egr_id)"
                                *ngIf="area.secuencias_egr_id == 2"><i class="ti-arrow-circle-right"></i> Ir a
                                Categorías</button>
                            <button class="btn btn-warning btn-sm" (click)="get_ruta(area.id, area.secuencias_egr_id)"
                                *ngIf="area.secuencias_egr_id == 3"><i class="ti-arrow-circle-right"></i> Ir a
                                Productos</button>
                            <button class="btn btn-warning btn-sm" (click)="get_ruta(area.id, area.secuencias_egr_id)"
                                *ngIf="area.secuencias_egr_id == 4"><i class="ti-arrow-circle-right"></i> Ir a
                                Usuarios</button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>





<!-- ============================================================== -->
<!--                  MODAL RESPONSIVE AGREGAR                      -->
<!-- ============================================================== -->
<div id="modal_area" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"
    style="display: none;">
    <div *ngIf="areaForm">
        <form class="modal-dialog modal-lg" [formGroup]="areaForm">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="modal_areaLabel1">Formulario de Área</h4>
                    <button type="button" class="close" (click)="this.reset_form('','','', id_evento, 0, 'https://res.cloudinary.com/dksstmpfu/image/upload/v1609780356/test/categoria/hs7u8jzomikuupjlbn4h.jpg', 1, 0, 0)"
                        data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="row el-element-overlay" style="margin-bottom: 0px !important;">
                                <div class="col-md-2">
                                </div>
                                <div class="col-md-8">
                                    <div class="card" style="margin-bottom: 0px !important;">
                                        <div class="el-card-item" style="margin-bottom: 0px !important;">
                                            <div *ngIf="estoy_edit == 0" class="el-card-avatar el-overlay-1"
                                                style="margin-bottom: 0px !important;">
                                                <img *ngIf="!file"
                                                    src="https://res.cloudinary.com/dksstmpfu/image/upload/v1609780356/test/categoria/hs7u8jzomikuupjlbn4h.jpg"
                                                    alt="area" />
                                                <img *ngIf="file" src="{{strImage}}" alt="area" />
                                            </div>
                                            <div *ngIf="estoy_edit == 1" class="el-card-avatar el-overlay-1"
                                                style="margin-bottom: 0px !important;">
                                                <img src="{{strImage}}" alt="area" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                </div>
                            </div>
                            <div class="form-group text-center">
                                <label>Foto del área (opcional)</label>
                                <div class="fileupload btn btn-danger btn-rounded waves-effect waves-light"><span><i
                                    class="fa fa-camera"></i> Seleccionar Imagen</span>
                                    <input type="file" accept="image/*"  (change)="seleccionImage($event)"  class="upload"> 
                                </div>

                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label for="area_name" class="control-label">Título:</label>
                                <input type="text" formControlName="titulo" class="form-control" id="area_name1"
                                maxlength="25"
                                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                    placeholder="Máximo 25 caractereres"
                                    onkeypress="return (event.charCode != 34 && event.charCode != 39) "
                                    >
                                <div *ngIf="f.titulo.invalid && (f.titulo.dirty || f.titulo.touched)">
                                    <div *ngIf="f.titulo.errors.required" style="color:red">El título es requerido</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="area_estado" class="control-label">Estado:</label>
                                <br>
                                <select formControlName="estado" class="form-control" id="area_est">
                                    <option selected disabled>Select</option>
                                    <option value="1">Habilitada</option>
                                    <option value="2">No Habilitada</option>
                                </select>
                                <div *ngIf="f.estado.invalid && (f.estado.dirty || f.estado.touched)">
                                    <div *ngIf="f.estado.errors.required" style="color:red">El estado es requerido
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="area_descripcion" class="control-label">Descripción:</label>
                                <textarea class="form-control" formControlName="descripcion" 
                                    maxlength="150"
                                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                    placeholder="Máximo 150 caracteres"
                                    onkeypress="return (event.charCode != 34 && event.charCode != 39)  "

                                    id="area_descripcion1"></textarea>
                                <div *ngIf="f.descripcion.invalid && (f.descripcion.dirty || f.descripcion.touched)">
                                    <div *ngIf="f.descripcion.errors.required" style="color:red">La descripción es
                                        requerida
                                    </div>
                                </div>
                            </div>
                            <div class="form-group" *ngIf="estoy_edit == 0">
                                <label for="area_sec" class="control-label">Secuencia:</label>
                                <select formControlName="secuencias_egr_id" class="form-control" id="area_sec">
                                    <option selected disabled>Select</option>
                                    <option *ngFor="let op of opciones_ruta[0]" value="{{op.value}}">{{op.option}}
                                    </option>
                                </select>
                                <div
                                    *ngIf="f.secuencias_egr_id.invalid && (f.secuencias_egr_id.dirty || f.secuencias_egr_id.touched)">
                                    <div *ngIf="f.secuencias_egr_id.errors.required" style="color:red">La secuencua es
                                        requerida
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="modal-footer">
                    <div class="btn btn-group ">
                        <button (click)="this.reset_form('','','', id_evento, 0, 'https://res.cloudinary.com/dksstmpfu/image/upload/v1609780356/test/categoria/hs7u8jzomikuupjlbn4h.jpg', 1, 0, 0)" class="btn btn-outline-secondary waves-effect waves-light" type="button" data-dismiss="modal"><span class="btn-label"><i class="ti-close"></i></span>Cancelar</button>
                        <button (click)="uploadImage()" [disabled]="!file" class="btn btn-info waves-effect waves-light" type="button"><span class="btn-label"><i class="ti-gallery"></i></span>Subir Foto</button>
                        <button (click)="save_area()" [disabled]="!areaForm.valid || subiendo_imagen" class="btn btn-warning waves-effect waves-light" type="button"><span class="btn-label"><i class="ti-save"></i></span>Guardar</button>
                    </div>

                </div>
            </div>
        </form>
    </div>
</div>
<!-- ============================================================== -->
<!--                  MODAL RESPONSIVE AGREGAR                      -->
<!-- ============================================================== -->


<!-- ============================================================== -->
<!--                  MODAL RESPONSIVE ELIMINAR                     -->
<!-- ============================================================== -->


<div id="modal-eliminar" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="display: none;">
    <form class="modal-dialog" [formGroup]="formDataDelete">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="mySmallModalLabel">Eliminar Área</h4>
                <button type="button" (click)="area_id = 0; area_name = '';" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="alert alert-warning" role="alert">
                <a href="#" class="alert-link">¡Cosas inesperadas sucederán si no lees esto!</a>
            </div>
            <div class="modal-body">
                <strong>Esta acción no se puede deshacer.</strong>
                <br>
                <p>Esto eliminará permanentemente el área y todo lo relacionado
                    con ella dejará de ser accesible.</p>
                <p>Escriba "<strong *ngIf="area_name">{{area_name}}</strong>" para confirmar.</p>
                <div class="form-group">
                    <input type="text" class="form-control" id="recipient-name" formControlName="nombreConfirm"
                        name="nombreConfirm">
                    <div *ngIf="formDataDelete.controls.nombreConfirm.invalid && (formDataDelete.controls.nombreConfirm.dirty || formDataDelete.controls.nombreConfirm.touched)">
                        <div class="alert alert-danger" role="alert" *ngIf="formDataDelete.controls.nombreConfirm.errors.required">
                            El título es requerido
                        </div>
                    </div>
                    <div *ngIf="!formDataDelete.controls.nombreConfirm.invalid && formDataDelete.controls.nombreConfirm.value != area_name">
                        <div class="alert alert-danger" role="alert">
                            Los nombres no coinciden
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="modal-footer">
                <button type="button" (click)="area_id = 0; area_name = '';" class="btn btn-secondary text-uppercase btn-rounded"
                    data-dismiss="modal">Cerrar</button>
                <button type="submit" (click)="delete_area()" [disabled]="formDataDelete.controls.nombreConfirm.value != area_name"
                    class="btn btn-warning text-uppercase btn-rounded">Eliminar</button>
            </div>
        </div>
    </form>
</div>


<div>
    <br *ngFor="let i of [].constructor(10)">
</div>

<!-- ============================================================== -->
<!--                  MODAL RESPONSIVE ELIMINAR                     -->
<!-- ============================================================== -->
