    <!-- ============================================================== -->
<!-- Stats box -->
<!-- ============================================================== -->
<div class="row">
    <div class="col-lg-3">
        <div class="card bg-info">
            <div class="card-body">
                <div class="d-flex no-block">
                    <div class="m-r-20 align-self-center"><img src="../assets/images/icon/income-w.png" alt="Income" />
                    </div>
                    <div class="align-self-center">
                        <h6 class="text-white m-t-10 m-b-0">Total Income</h6>
                        <h2 class="m-t-0 text-white">953,000</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-3">
        <div class="card bg-success">
            <div class="card-body">
                <div class="d-flex no-block">
                    <div class="m-r-20 align-self-center"><img src="../assets/images/icon/expense-w.png" alt="Income" />
                    </div>
                    <div class="align-self-center">
                        <h6 class="text-white m-t-10 m-b-0">Total Expense</h6>
                        <h2 class="m-t-0 text-white">236,000</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-3">
        <div class="card bg-primary">
            <div class="card-body">
                <div class="d-flex no-block">
                    <div class="m-r-20 align-self-center"><img src="../assets/images/icon/assets-w.png" alt="Income" />
                    </div>
                    <div class="align-self-center">
                        <h6 class="text-white m-t-10 m-b-0">Total Assets</h6>
                        <h2 class="m-t-0 text-white">987,563</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-3">
        <div class="card bg-danger">
            <div class="card-body">
                <div class="d-flex no-block">
                    <div class="m-r-20 align-self-center"><img src="../assets/images/icon/staff-w.png" alt="Income" />
                    </div>
                    <div class="align-self-center">
                        <h6 class="text-white m-t-10 m-b-0">Total Staff</h6>
                        <h2 class="m-t-0 text-white">987,563</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- ============================================================== -->
<!-- Sales overview chart -->
<!-- ============================================================== -->
<div class="row">
    <div class="col-lg-6 col-md-12">
        <div class="card">
            <div class="card-body">
                <div class="d-flex">
                    <div>
                        <h3 class="card-title m-b-5"><span class="lstick"></span>Sales Overview </h3>
                    </div>
                    <div class="ml-auto">
                        <select class="custom-select b-0">
                            <option selected="">January 2017</option>
                            <option value="1">February 2017</option>
                            <option value="2">March 2017</option>
                            <option value="3">April 2017</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="bg-theme stats-bar">
                <div class="row">
                    <div class="col-lg-4 col-md-4">
                        <div class="p-20 active" style="background-color: #C67118">
                            <h6 class="text-white">Total Sales</h6>
                            <h3 class="text-white m-b-0">$10,345</h3>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4">
                        <div class="p-20">
                            <h6 class="text-white">This Month</h6>
                            <h3 class="text-white m-b-0">$7,589</h3>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4">
                        <div class="p-20">
                            <h6 class="text-white">This Week</h6>
                            <h3 class="text-white m-b-0">$1,476</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div id="sales-overview2" class="p-relative" style="height:330px;"></div>
            </div>
        </div>
    </div>
    <div class="col-lg-3 col-md-6">
        <div class="card">
            <img class="card-img-top img-fluid auto-height" src="https://www.cognodata.com/wp-content/uploads/2019/07/estadistica-descriptiva-e-inferencial.jpg" alt="Card image cap">
            <div class="card-body">
                <h3 class="font-normal">Business development of rules 2017</h3>
                <span class="label label-info label-rounded">Technology</span>
                <p class="m-b-0 m-t-20">Titudin venenatis ipsum aciat. Vestibu ullamer quam. nenatis ipsum ac feugiat.
                    Ibulum ullamcorper.</p>
                <div class="d-flex m-t-20">
                    <button class="btn p-l-0 btn-link ">Read more</button>
                    <div class="ml-auto align-self-center">
                        <a href="javascript:void(0)" class="link m-r-10"><i class="fa fa-heart-o"></i></a>
                        <a href="javascript:void(0)" class="link m-r-10"><i class="fa fa-share-alt"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- ============================================================== -->
    <!-- visit charts-->
    <!-- ============================================================== -->
    <div class="col-lg-3 col-md-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title"><span class="lstick"></span>Visit Separation</h4>
                <div id="visitor" style="height:280px; width:100%;"></div>
                <table class="table vm font-14 m-b-0">
                    <tr>
                        <td class="b-0">Mobile</td>
                        <td class="text-right font-medium b-0">38.5%</td>
                    </tr>
                    <tr>
                        <td>Tablet</td>
                        <td class="text-right font-medium">30.8%</td>
                    </tr>
                    <tr>
                        <td>Desktop</td>
                        <td class="text-right font-medium">7.7%</td>
                    </tr>
                    <tr>
                        <td>Other</td>
                        <td class="text-right font-medium">23.1%</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>


<!-- ============================================================== -->
<!-- End Page Content -->
<!-- ============================================================== -->
