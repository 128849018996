import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError} from 'rxjs/operators';

@Injectable()
export class CompraService {

    //VARIABLES
    URI_COMPRA = 'https://foxylabs-eventos.herokuapp.com/api/compras';
    URI_COMPRA_DETALLE = 'https://foxylabs-eventos.herokuapp.com/api/compras_detalle'
    
    //HTTP OPTIONS
    private httpOptions = {

        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    //HANDLE ERROR
    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('Un error ha ocurrido:', error.error.message);
        } else {
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: `, error.error);
        }
        return throwError(
            error);
    };



    constructor(private http: HttpClient) {

    }

    /*
        /////////////
            GET
        ////////////
    */
    // COMPRAS DETALLE
    get_compra_detalle() {
        return this.get_data_by_uri(this.URI_COMPRA_DETALLE);
    }
    //COMPRAS
    get_all() {
        return this.get_data_by_uri(this.URI_COMPRA);
    }

    get_data_by_uri(uri: string) : any{
        return this.http.get<any>(`${ uri }/get_all`).pipe(map(data => {
            return data;
        }));
    }




    get_compra_by_id(id_Compra: number) {
        return this.http.get<any>(`${this.URI_COMPRA}/get_${id_Compra}`).pipe(map(data => {
            return data;
        }));
    }
    
    //CREATE
    post(data: any) {

        return this.http.post(`${this.URI_COMPRA}/add`, data, this.httpOptions)
        .pipe(map(
            (data) => {
                return data;
            }, 
            (err: any)=> {
                catchError(this.handleError)
        }));
    }
    //UPDATE
    put( data: any, id_Compra: number ) {
        return this.http.put(`${this.URI_COMPRA}/update_${ id_Compra }`, data, this.httpOptions)
        .pipe(map(
            (data) => {
                console.log(data)
                return data;
            }, 
            (err: any)=> {
                catchError(this.handleError)
        }));
    }


    //DELETE
    delete(id: number) {
        return this.http.delete(`${this.URI_COMPRA}/delete_${id}`, this.httpOptions).pipe(map(data => {
            return data;
        }));
    }

    




    //CREATE
    post_compra_detalle(data: any) {

        return this.http.post(`${this.URI_COMPRA_DETALLE}/add`, data, this.httpOptions)
        .pipe(map(
            (data) => {
                return data;
            }, 
            (err: any)=> {
                catchError(this.handleError)
        }));
    }
    //DELETE
    delete_compra_detail(id: number) {
        return this.http.delete(`${this.URI_COMPRA_DETALLE}/delete_${id}`, this.httpOptions).pipe(map(data => {
            return data;
        }));
    }
}