import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError} from 'rxjs/operators';

@Injectable()
export class AreaService {

    //VARIABLES
    URI_AREA = 'https://foxylabs-eventos.herokuapp.com/api/areas';
    
    URI_AREA_EVENTO = 'https://foxylabs-eventos.herokuapp.com/api/secuencia/secuencia/1/secuencia_anterior/0/buscar'
    
    
    //HTTP OPTIONS
    private httpOptions = {

        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    //HANDLE ERROR
    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('Un error ha ocurrido:', error.error.message);
        } else {
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: `, error.error);
        }
        return throwError(
            error);
    };



    constructor(private http: HttpClient) {

    }

    get_all() {
        return this.http.get<any>(`${this.URI_AREA}/get_all`).pipe(map(data => {
            return data;
        }));
    }

    get_area_by_id(id_AREA: number) {
        return this.http.get<any>(`${this.URI_AREA}/get_${id_AREA}`).pipe(map(data => {
            return data;
        }));
    }
    get_all_area_by_event(event_id: number){
        return this.http.get<any>(`${ this.URI_AREA_EVENTO }/${ event_id }`).pipe(map(data => {
            return data;
        }));
    }
    //CREATE
    post_area(data: any) {

        return this.http.post(`${this.URI_AREA}/add`, data, this.httpOptions)
        .pipe(map(
            (data) => {
                return data;
            }, 
            (err: any)=> {
                catchError(this.handleError)
        }));
    }
    //UPDATE
    put_area( data: any, area_id: number ) {
        return this.http.put(`${this.URI_AREA}/update_${ area_id }`, data, this.httpOptions)
        .pipe(map(
            (data) => {
                console.log(data)
                return data;
            }, 
            (err: any)=> {
                catchError(this.handleError)
        }));
    }


    //DELETE
    delete_area(area_id: number) {
        return this.http.delete(`${this.URI_AREA}/delete_${ area_id }`, this.httpOptions).pipe(map(data => {
            return data;
        }));
    }




}