import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'app-manager-eventos-generales',
  templateUrl: './manager-eventos-generales.component.html'
})
export class ManagerEventosGeneralesComponent implements OnInit {

  searchText: any //variable que sirve para buscar los eventos
  event_list:any[] = [] // contiene la lista de eventos traidos de la api
  constructor(private event_service: EventService, private router:Router) {
    this.inicialize(); 
  }
  ngOnInit(): void {
  }

  inicialize(){ //Inicializa la data de los eventos
    this.event_service.get_all().subscribe(data => {
      this.event_list = data
    });
  }
  
  view_event(id_event:number){
    localStorage.setItem('permitido', "0")
    this.router.navigate(['evento/', id_event])
  }

  log_in(){
    if (localStorage.getItem('currentUser')) {
      this.router.navigate(['/eventos-generales'])      
    } else {
      this.router.navigate(['/login'])
    }
  }
}
