


<div id="main-wrapper">
    <!-- ============================================================== -->
    <!-- Page wrapper  -->
    <!-- ============================================================== -->
    <div class="page-wrapper-no-sidebar">
        
        <!-- ============================================================== -->
        <!-- Container fluid  -->
        <!-- ============================================================== -->
        <div class="container-fluid">
        
        
            <!-- ============================================================== -->
            <!-- Start Page Content -->
            <!-- ============================================================== -->
            <div class="row card-no-border">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="row pricing-plan">
                                <div class="col-md-4 col-xs-12 col-sm-6 no-padding">
                                    <div class="pricing-box">
                                        <div class="pricing-body">
                                            <div class="pricing-header">
                                                <h4 class="price-lable text-white bg-warning"> Normal </h4>
                                                <h4 class="text-center">Precio</h4>
                                                <h2 class="text-center"><span class="price-sign">$</span>24</h2>
                                                <p class="uppercase">per month</p>
                                            </div>
                                            <div class="price-table-content">
                                                <div class="price-row"><i class="icon-user"></i> 3 Members</div>
                                                <div class="price-row"><i class="icon-screen-smartphone"></i> Single Device</div>
                                                <div class="price-row"><i class="icon-drawar"></i> 50GB Storage</div>
                                                <div class="price-row"><i class="icon-refresh"></i> Monthly Backups</div>
                                                <div class="price-row">
                                                    <button class="btn btn-warning waves-effect waves-light m-t-20">Sign up</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-xs-12 col-sm-6 no-padding">
                                    <div class="pricing-box">
                                        <div class="pricing-body">
                                            <div class="pricing-header">
                                                <h4 class="price-lable text-white bg-warning"> Plata </h4>
                                                <h4 class="text-center">Precio</h4>
                                                <h2 class="text-center"><span class="price-sign">$</span>34</h2>
                                                <p class="uppercase">per month</p>
                                            </div>
                                            <div class="price-table-content">
                                                <div class="price-row"><i class="icon-user"></i> 5 Members</div>
                                                <div class="price-row"><i class="icon-screen-smartphone"></i> Single Device</div>
                                                <div class="price-row"><i class="icon-drawar"></i> 80GB Storage</div>
                                                <div class="price-row"><i class="icon-refresh"></i> Monthly Backups</div>
                                                <div class="price-row">
                                                    <button class="btn btn-warning waves-effect waves-light m-t-20">Sign up</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                
                                <div class="col-md-4 col-xs-12 col-sm-6 no-padding">
                                    <div class="pricing-box">
                                        <div class="pricing-body">
                                            <div class="pricing-header">
                                                <h4 class="price-lable text-white bg-warning"> Oro </h4>
                                                <h4 class="text-center">Precio</h4>
                                                <h2 class="text-center"><span class="price-sign">$</span>54</h2>
                                                <p class="uppercase">per month</p>
                                            </div>
                                            <div class="price-table-content">
                                                <div class="price-row"><i class="icon-user"></i> 15 Members</div>
                                                <div class="price-row"><i class="icon-screen-smartphone"></i> Single Device</div>
                                                <div class="price-row"><i class="icon-drawar"></i> 1TB Storage</div>
                                                <div class="price-row"><i class="icon-refresh"></i> Monthly Backups</div>
                                                <div class="price-row">
                                                    <button class="btn btn-warning waves-effect waves-light m-t-20">Sign up</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
        </div>
        <!-- ============================================================== -->
        <!-- End Container fluid  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- footer -->
        <!-- ============================================================== -->
        <footer class="footer">
            © 2017 Admin Pro by wrappixel.com
        </footer>
        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Page wrapper  -->
    <!-- ============================================================== -->
</div>


