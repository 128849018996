<div id="main-wrapper">
  <!--   NAV SUPERIOR  -->
  <header class="topbar">
    <nav class="navbar navbar-expand-lg navbar-light">
      <!--  Show this only on mobile to medium screens  -->
      <a class="navbar-brand d-lg-none" [routerLink]="['/tok-tok-eventos']" routerLinkActive="router-link-active"><img
          src="assets/images/logo.png" width="110px" height="60px" alt="homepage" class="dark-logo" />
      </a>

      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggle"
        aria-controls="navbarToggle" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <!--  Use flexbox utility classes to change how the child elements are justified  -->
      <div class="collapse navbar-collapse justify-content-between" id="navbarToggle">

        <!--   Show this only lg screens and up   -->
        <a class="navbar-brand d-none d-lg-block" [routerLink]="['/tok-tok-eventos']" routerLinkActive="router-link-active"><img
            src="assets/images/logo.png" width="110px" height="60px" alt="homepage" class="dark-logo" />
        </a>

        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link  waves-effect waves-dark" [routerLink]="['/tok-tok-eventos']"
              routerLinkActive="router-link-active" aria-expanded="false"> <i class="fa fa-calendar"></i>
              Eventos
            </a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle waves-effect waves-dark" href="" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false"><i class="fa fa-user"></i>Usuario</a>
            <div class="dropdown-menu dropdown-menu-right animated flipInY">
              <ul class="dropdown-user">
                <li><a (click)="log_in()" routerLinkActive="router-link-active"><i class="ti-unlock"></i> Acceder </a>
                </li>
                <li><a [routerLink]="['/signin']" routerLinkActive="router-link-active"><i class="ti-pencil"></i> Crear
                    Cuenta</a></li>
                <li><a [routerLink]="['/buyapp']" routerLinkActive="router-link-active"><i class=" ti-credit-card"></i>
                    Comprar App</a></li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </header>
  <!-- END NAV SUPERIOR -->

  <!-- PAGE CONTENT -->
  <div class="page-wrapper">
    <!-- ============================================================== -->
    <!-- Start Page Content -->
    <!-- ============================================================== -->
    <div class="container-fluid">
      <br>
      <br>
      <div class="container">
        <app-evento-detalle></app-evento-detalle>
      </div>
    </div>
    <!-- ============================================================== -->
    <!-- End PAge Content -->
    <!-- ============================================================== -->

    <!-- ============================================================== -->
    <!-- footer -->
    <!-- ============================================================== -->
    <footer class="footer">
      © 2020 FoxyLabs
    </footer>
    <!-- ============================================================== -->
    <!-- End footer -->
    <!-- ============================================================== -->
  </div>
  <!-- END PAGE CONTENT -->
</div>