<br>
<div class="row page-titles" *ngIf="event_detail != null">
    <div class="col-md-7 align-self-center">
        <h3 *ngIf="es_envio" class="text-themecolor">Detalle de Envíos</h3>
        <h3 *ngIf="!es_envio" class="text-themecolor">Recaudaciones y Transferencias</h3>
    </div>
    <div class="col-md-5 align-self-center">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLinkActive="router-link-active">Home</a></li>
            <li class="breadcrumb-item">Evento {{event_detail.titulo}} </li>
            <li class="breadcrumb-item" *ngIf="es_envio" active>Envíos</li>
            <li class="breadcrumb-item" *ngIf="!es_envio" active>Recaudaciones</li>
        </ol>
    </div>
</div>




<div class="row" *ngIf="!es_envio && event_detail != null">
    <!-- Column -->
    <div class="col-lg-4 col-md-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Cantidad de Productos</h4>
                <div class="text-right"> <span class="text-muted">Productos recibidos</span>
                    <h1 class="font-light">{{ total_product }}</h1>
                </div>
            </div>
        </div>
    </div>
    <!-- Column -->
    <!-- Column -->
    <div class="col-lg-4 col-md-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Dinero recaudado</h4>
                <div class="text-right"> <span class="text-muted">Total completo</span>
                    <h1 class="font-light"><sup><i class="ti-arrow-up text-info"></i></sup> Q{{ event_detail.recaudado }}</h1>
                </div>
            </div>
        </div>
    </div>
    <!-- Column -->
    <!-- Column -->
    <div class="col-lg-4 col-md-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Dinero trasferido</h4>
                <div class="text-right"> <span class="text-muted">Total completo</span>
                    <h1 class="font-light"><sup><i class="ti-arrow-down text-danger"></i></sup> Q{{ event_detail.transferido }}</h1>
                </div>
            </div>
        </div>
    </div>
    <!-- Column -->
</div>


<div class="row" *ngIf="es_envio">
    <!-- Column -->
    <div class="col-lg-4 col-md-6">
        <div class="card">
            <div class="card-body">
                <div class="row p-t-10 p-b-10">
                    <!-- Column -->
                    <div class="col p-r-0">
                        <h1 class="font-light">{{ total_product }}</h1>
                        <h6 class="text-muted">Productos enviados</h6>
                    </div>
                    <!-- Column -->
                    <div class="col text-right align-self-center">
                        <div data-label="100%" class="css-bar m-b-0 css-bar-primary css-bar-20"><i
                                class="ti-shopping-cart"></i></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Column -->
    <!-- Column -->
    <div class="col-lg-3 col-md-6">
        <div class="card">
            <div class="card-body">
                <div class="row p-t-10 p-b-10">
                    <!-- Column -->
                    <div class="col p-r-0">
                        <h1 class="font-light">{{ array_detalle.length }}</h1>
                        <h6 class="text-muted">Registros realizados</h6>
                    </div>
                    <!-- Column -->
                    <div class="col text-right align-self-center">
                        <div data-label="20%" class="css-bar m-b-0 css-bar-danger css-bar-20"><i
                                class="ti-stats-up"></i></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Column -->
    <!-- Column -->
    <div class="col-lg-5 col-md-6">
        <div class="card">
            <div class="card-body">
                <div class="row p-t-10 p-b-10">
                    <!-- Column -->
                    <div class="col p-r-0">
                        <h1 class="font-light">Q {{ total_money }}</h1>
                        <h6 class="text-muted">Dinero invertido</h6>
                    </div>
                    <!-- Column -->
                    <div class="col text-right align-self-center">
                        <div data-label="20%" class="css-bar m-b-0 css-bar-success css-bar-20"><i class="ti-wallet"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row" *ngIf="event_detail != null">
    <!-- Column -->
    <div class="col-lg-4 col-xlg-3 col-md-5">
        <div class="card">
            <div class="card-body">
                <div class="card-body little-profile text-center">
                    <div class="pro-img m-t-20"><img src="{{ event_detail.img }}" alt="Evento"></div>
                    <h3 class="m-b-0">{{ event_detail.titulo }}</h3>
                    <h6 class="text-muted"> {{ event_detail.descripcion }}</h6>
                    <button *ngIf="event_detail.usuarios_id == user_id" class="btn btn-warning btn-sm" data-toggle="modal" data-target="#transaction-modal"><i
                            class="ti-plus"></i> Nueva transaccion</button>
                </div>
            </div>
            <div>
                <hr>
            </div>
            <div class="card-body">
                <small class="text-muted">Inicio </small>
                <h6>{{event_detail.fech_ini | date: 'medium' }}</h6>
                <small class="text-muted">Finalizacion </small>
                <h6>{{event_detail.fech_fin | date: 'medium' }}</h6>
                <small class="text-muted">Tipo </small>
                <h6 *ngIf="event_detail.eventos_tipos_id == 2">Multiusuarios</h6>
                <h6 *ngIf="event_detail.eventos_tipos_id == 1">Sin Usuarios</h6>
                <small class="text-muted">Secuencia Inicial </small>
                <h6 *ngIf="event_detail.secuencias_id == 1">Área</h6>
                <h6 *ngIf="event_detail.secuencias_id == 2">Categoría</h6>
                <h6 *ngIf="event_detail.secuencias_id == 3">Productos</h6>
                <h6 *ngIf="event_detail.secuencias_id == 4">Usuarios</h6>

            </div>
        </div>
    </div>
    <!-- Column -->
    <!-- Column -->
    <div class="col-lg-8 col-xlg-9 col-md-7">
        <div class="card">
            <div class="card-body">
                <div id="accordion_envios" class="accordion" role="tablist" aria-multiselectable="true" *ngIf="array_compras_by_date.length != 0">

                    <div class="card" *ngFor="let compra of array_compras_by_date; let i = index">
                        <div class="card-header" style="background-color: white;" role="tab" id="headingThree">
                            <h5 class="mb-0">
                                <a class="collapsed" *ngIf="!es_envio" (click)="get_products(compra.compras)"
                                    data-toggle="collapse" data-parent="#accordion_envios" href="#collapse{{i}}"
                                    aria-expanded="false" aria-controls="collapseexaThree">
                                    Productos recibidos con fecha {{ compra.fecha}}
                                </a>
                                <a class="collapsed" *ngIf="es_envio" (click)="get_products(compra.compras)"
                                    data-toggle="collapse" data-parent="#accordion_envios" href="#collapse{{i}}"
                                    aria-expanded="false" aria-controls="collapseexaThree">
                                    Productos enviados con fecha {{ compra.fecha}}
                                </a>
                            </h5>
                        </div>
                        <div id="collapse{{i}}" class="collapse" role="tabpanel" aria-labelledby="headingThree">
                            <div class="card-body">

                                <div class="card">
                                    <div class="card-body">
                                        <h4 class="card-title">Listado de Productos</h4>
                                        <div class="table-responsive">
                                            <table class="table stylish-table">
                                                <thead>
                                                    <tr>
                                                        <th style="width:90px;">Producto</th>
                                                        <th>Nombre</th>
                                                        <th>Cantidad</th>
                                                        <th>Precio</th>
                                                        <th>Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of array_products">
                                                        <td>
                                                            <div class="user-img">
                                                                <img src="{{ item.product.img }}" alt="user" width="50"
                                                                    height="50" class="img-circle">
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <h6><a  class="link">{{ item.product.titulo }}</a></h6>
                                                        </td>
                                                        <td>
                                                            <h5>{{ item.cantidad }}</h5>
                                                        </td>
                                                        <td>
                                                            <h5>Q {{ item.product.precio }}</h5>
                                                        </td>
                                                        <td>
                                                            <h5>Q {{ item.product.precio * item.cantidad }}</h5>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="array_compras_by_date.length == 0">
                    <h1>No hay datos para mostrar</h1>
                </div>
            </div>
        </div>
    </div>
    <!-- Column -->
</div>


<div id="transaction-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
    aria-hidden="true" style="display: none;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Modal Content is Responsive</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <div>
                    <article class="card">
                        <div class="card-body">

                            <ul class="nav bg-light nav-pills rounded nav-fill mb-3" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" data-toggle="pill" href="#nav-tab-bank">
                                        <i class="fa fa-university"></i> Transferencia bancaria</a></li>
                            </ul>

                            <div class="tab-content">
                                <div class="tab-pane fade show active" id="nav-tab-card">
                                    <form role="form">
                                        <div class="form-group">
                                            <div class="form-group">
                                                <label for="exampleInputuname2">Nombre de la cuenta</label>
                                                <div class="input-group">
                                                    <input #nombre type="text" class="form-control" id="exampleInputuname2" >
                                                    <div class="input-group-addon"><i class="ti-user"></i></div>
                                                </div>
                                            </div>
                                        </div> <!-- form-group.// -->
                                        <div class="form-group">
                                            <div class="form-group">
                                                <label for="exampleInputuname2">Número de cuenta </label>
                                                <div class="input-group">
                                                    <input #cuenta type="number" class="form-control" id="exampleInputuname2" >
                                                    <div class="input-group-addon"><i class="ti-money"></i></div>
                                                </div>
                                            </div>
                                        </div> <!-- form-group.// -->
                                        <div class="form-group">
                                            <div class="form-group">
                                                <label for="exampleInputuname2">Total a transferir </label>
                                                <div class="input-group">
                                                    <input #total type="number" class="form-control" >
                                                    <div class="input-group-addon"><i class="ti-money"></i></div>
                                                </div>
                                            </div>
                                        </div> <!-- form-group.// -->
                                        <div class="form-group">
                                            <div class="form-group">
                                                <label for="exampleInputuname2">Banco </label>
                                                <div class="input-group">
                                                    <select #banco name="" class="form-control" id="">
                                                        <option value="1">Banrural</option>
                                                        <option value="2">G&T</option>
                                                        <option value="3">BI</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div> <!-- form-group.// -->
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-default waves-effect"
                                                data-dismiss="modal"> <i class="ti-close"></i> Cerrar</button>
                                            <button type="button" (click)="send_transaction(nombre.value, cuenta.value, total.value, banco.value)" class="btn btn-danger waves-effect waves-light">Enviar transacción</button>
                                        </div>
                                    </form>
                                </div> <!-- tab-pane.// -->

                            </div> <!-- tab-content .// -->

                        </div> <!-- card-body.// -->
                    </article> <!-- card.// -->
                </div>
            </div>
        </div>
    </div>
</div>