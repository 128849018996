import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-manager-evento-detalle',
  templateUrl: './manager-evento-detalle.component.html'
})
export class ManagerEventoDetalleComponent implements OnInit {

   constructor(private router:Router) {
   }
 
   ngOnInit(): void {
   }

   log_in(){
    if (localStorage.getItem('currentUser')) {
      this.router.navigate(['/eventos-generales'])      
    } else {
      this.router.navigate(['/login'])
    }
  }
}
