import { NgModule } from '@angular/core';
import { Routes, RouterModule, NoPreloading } from '@angular/router';
import { ManagerEventoDetalleComponent } from './page/manager-evento-detalle/manager-evento-detalle.component';
import { ManagerEventosGeneralesComponent } from './page/manager-eventos-generales/manager-eventos-generales.component';
import { PageComponent } from './page/page.component';
import { AuthGuardService } from './services/auth.guard.service';
import { LoginComponent } from './session/login/login.component';
import { PasswordRecoveryComponent } from './session/password-recovery/password-recovery.component';
import { SigninComponent } from './session/signin/signin.component';
import { NopagefoundComponent } from './shared/nopagefound/nopagefound.component';

const routes: Routes = [
  //PAGINA INICIAL
  { path: 'tok-tok-eventos', component: ManagerEventosGeneralesComponent },
  { path: 'tok-tok-evento/:id', component: ManagerEventoDetalleComponent },

  //INICIO DE SESION
  { path: 'login', component: LoginComponent },
  { path: 'signin', component: SigninComponent },
  { path: 'recuperar-password', component: PasswordRecoveryComponent },
  { path: '', component: PageComponent, canActivate: [ AuthGuardService ], loadChildren: './page/page.module#PageModule' },
  //NO SE ENCUENTRA LA PAGINA
  { path: '**', component: NopagefoundComponent},

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: NoPreloading, useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { 

}
