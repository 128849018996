import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EventService } from 'src/app/services/event.service';
import { UploadFileService } from 'src/app/services/upload-file.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html'
})
export class NavbarComponent implements OnInit {

   //VARIABLES
   user_logged: any = null; 
   es_admin: boolean = false;
  
   constructor(private router: Router, private evento: EventService, private service: UploadFileService) { 
     this.user_logged = JSON.parse(String(localStorage.getItem('currentUser')))
     
     
     if (localStorage.getItem('admin') == "1") {
       this.es_admin = true
     }
     this.user_logged.img = service.path_exist(this.user_logged.img);
   }
 
   ngOnInit(): void {
   }
   //sale del sistema
   log_out(){
     localStorage.clear();
     this.router.navigate(['/login']);
   }
}
