<div id="main-wrapper">
    <!--   NAV SUPERIOR  -->

    <header class="topbar">

        <nav class="navbar top-navbar navbar-light text-light py-3   navbar-expand-md">
            <div class="container-nav topheader">
                <div class="d-flex justify-content-center">
                    <div >
                        <img src="../../../../../../assets/images/logo.png" width="120px" height="70px" alt="">
                    </div>
                </div>
            </div>
        </nav >
        <div class="bottom sticky-top shadow-sm" style="background-color: #242a33;">
            <nav class="container-nav" >
                <ul  class="nav nav-bottom nav-fill nav-list">
                    <li class="nav-item">
                        <a class="nav-link text-light active" style="background-color: #242a33 !important"  [routerLink]="['/tok-tok-eventos']" routerLinkActive="router-link-active"    ><i class="ti-home"></i><span class="d-none d-sm-inline-block d-md-inline-block">Home</span></a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link text-light"  style="background-color: #242a33!important" [routerLink]="['/tok-tok-eventos']" routerLinkActive="router-link-active"   ><i class="fa fa-calendar"></i><span class="d-none d-sm-inline-block d-md-inline-block">Eventos</span></a>
                    </li>
                    
                    <li class="nav-item">
                        <a class="nav-link text-light"   ><i class="fa fa-ticket"></i><span class="d-none d-sm-inline-block d-md-inline-block">Productos</span></a>
                    </li>
                    
                    <li class="nav-item">
                        <a class="nav-link text-light"  ><i class="fa fa-tags"></i><span class="d-none d-sm-inline-block d-md-inline-block">Promociones</span></a>
                    </li>
                    

                    <li class="nav-item dropdown">
                        <a class="nav-link text-light dropdown-toggle" href="#" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="ti ti-user"></i> <span class="d-none d-sm-inline-block d-md-inline-block"> Usuario</span>
                        </a>
                          <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                            <ul class="dropdown-user">
                                <li><a type="button" (click)="log_in()" ><i class="ti-unlock"></i> Acceder </a></li>
                                <li><a [routerLink]="['/signin']" routerLinkActive="router-link-active"  ><i class="ti-pencil"></i> Crear Cuenta</a></li>
                                <li><a [routerLink]="['/buyapp']" routerLinkActive="router-link-active"  ><i class=" ti-credit-card"></i> Comprar App</a></li>
                            </ul>
                          </div>
                    </li>
                </ul>
            </nav>
        </div>
    </header>
    <!-- END NAV SUPERIOR -->

    <!-- PAGE CONTENT -->
    <div class="page-wrapper">


        <div class="row">
            <div class="col-lg-12">
                <div id="carouselExampleIndicators3"  class="carousel slide" data-ride="carousel">
                    <ol class="carousel-indicators">
                        <li data-target="#carouselExampleIndicators3" data-slide-to="0" class="active"></li>
                        <li data-target="#carouselExampleIndicators3" data-slide-to="1" class=""></li>
                        <li data-target="#carouselExampleIndicators3" data-slide-to="2" class=""></li>
                    </ol>
                    <div class="carousel-inner" role="listbox">
                        <div class="carousel-item">
                            <img class="img-responsive"
                            src="https://www.todoticket.com/images/eventos/slideshow/topanavidad_slider.png"
                            alt="First slide">
                            <div class="carousel-caption d-none d-md-block">
                                <h3 class="text-white">First title goes here</h3>
                                <p>this is the subcontent you can use this</p>
                            </div>
                        </div>
                        <div class="carousel-item active">
                            <img class="img-responsive" 
                            src="https://www.todoticket.com/images/eventos/slideshow/spotdrivein_slider.png" 
                            alt="Second slide">
                            <div class="carousel-caption d-none d-md-block">
                                <h3 class="text-white">Second title goes here</h3>
                                <p>this is the subcontent you can use this</p>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <img class="img-responsive" src="https://www.todoticket.com/images/eventos/slideshow/montaner2_slider.png" alt="Third slide">
                            <div class="carousel-caption d-none d-md-block">
                                <h3 class="text-white">Third title goes here</h3>
                                <p>this is the subcontent you can use this</p>
                            </div>
                        </div>
                    </div>
                    <a class="carousel-control-prev" href="#carouselExampleIndicators3" role="button" data-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next" href="#carouselExampleIndicators3" role="button" data-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                    </a>
                </div>
            </div>
        </div>
       




        <!-- ============================================================== -->
        <!-- Start Page Content -->
        <!-- ============================================================== -->

        <div class="container-fluid">

            <div class="row">
                <div class=" fix-header card-no-border col-12">
                    <div class="card">
                        <!-- .left-right-aside-column-->
                        <div class="event-page-aside">
                            <div class="right-aside ">
                                <div class="right-page-header">
                                    <div class="d-flex">
                                        <div class="align-self-center">
                                            <h4 class="card-title m-t-10">Próximos Eventos</h4>
                                        </div>
                                        <div class="ml-auto">
                                            <input type="text" [(ngModel)]="searchText"
                                                placeholder="Buscar Evento" class="form-control"> </div>
                                    </div>
                                </div>

                            </div>
                            <!-- /.left-right-aside-column-->
                        </div>
                    </div>
                </div>
            </div>
            <!-- ============================================================== -->
            <!-- Start Page Content -->
            <!-- ============================================================== -->
            <!-- .row -->
            <div class="row card-no-border" style="background: #f4f6f9">
                <br> <br>
                <div class="container">

                    <!-- ============================================================== -->
                    <!-- SRART CARDS EVENTOS  -->
                    <!-- ============================================================== -->

                    <div class="card-columns el-element-overlay">
                        <div *ngFor="let event of event_list | filter:searchText">
                            <div class="card">
                                <div class="el-card-item">
                                    <div class="el-card-avatar el-overlay-1">
                                        <a class="image-popup-vertical-fit"> <img src="{{event.img}}" alt="user" /> </a>
                                        <div class="el-overlay">
                                            <ul class="el-info">
                                                <li><a [routerLink]="['/tok-tok-evento', event.id]" routerLinkActive="router-link-active"   class="btn default btn-outline"><i
                                                            class="icon-link"></i>Ver Evento</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="el-card-content">
                                        <h3 class="box-title">{{event.titulo}}</h3> <small>{{event.descripcion}}</small>
                                        <br /> <small>{{event.fecha | date: 'short' }}</small>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <!-- ============================================================== -->
                    <!-- End CARD EVENTOS -->
                    <!-- ============================================================== -->
                </div>
            </div>
            <!-- /.row -->
            <!-- ============================================================== -->
            <!-- End PAge Content -->
            <!-- ============================================================== -->

        </div>
        <!-- ============================================================== -->
        <!-- End PAge Content -->
        <!-- ============================================================== -->

        <!-- ============================================================== -->
        <!-- footer -->
        <!-- ============================================================== -->
        <footer class="footer">
            © 2020 FoxyLabs
        </footer>
        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->
    </div>
    <!-- END PAGE CONTENT -->
</div>
