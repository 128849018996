import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from 'src/app/controller/alert.controller';
import { RoutesController } from 'src/app/controller/routes.controller';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'app-eventos-generales',
  templateUrl: './eventos-generales.component.html'
})
export class EventosGeneralesComponent implements OnInit {

//VARIABLES
  event_list: any = [] 
  searchText:any    //variable usada para buscar los eventos

  constructor(
    private event_service: EventService,
    private router: Router) {
    this.inicialize()

  }

  ngOnInit(): void {
  }

  inicialize() { //Inicializa la data de los eventos
    this.event_service.get_all().subscribe(
      (data: any[]) => {
        if (data.length == 0) {
          AlertController.getInstance().show_swall('Eventos no encontrados', 'No hay información disponible para mostrar', 'warning')
        } else {
          data.forEach((element: any) => {
            let fecha = (String(element.fech_fin).split('T'))[0]
            console.log(fecha)
            console.log(RoutesController.getInstance().get_date())
            if (element.estado == 1 && fecha >= RoutesController.getInstance().get_date() ) {
              this.event_list.push(element)
            }
          });
        }
      });
  }

}
