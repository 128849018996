import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController } from 'src/app/controller/alert.controller';
import { RoutesController } from 'src/app/controller/routes.controller';
import { CategoriaService } from 'src/app/services/categoria.service';
import { UploadFileService } from 'src/app/services/upload-file.service';

//JQUERY
declare var $: any;

@Component({
  selector: 'app-categoria-evento',
  templateUrl: './categoria-evento.component.html'
})
export class CategoriaEventoComponent implements OnInit {
  //VARIABLES GENERALES
  category_list: any = []    // guarda la lista de categorias pertenecientes a un evento, category o usuario
  opciones_ruta: any = []    // guarda las opciones de ruta segun la ruta actual que tengo
  id_anterior: number = 0; // id de donde vengo, puede ser evento, category o usuario
  secuencia_ant: any        // guarda el numero de secuencia desde donde vengo
  searchText: any;        // sirve para buscar categorias
  category_id: number = 0; // guarda el id de la categoria a eliminar o editar 
  category_name: string = "";// guarda el nombre de la categoria a eliminar  

  //VARIABLES DE FORMULARIO DE CATEGORIA
  categoryForm: any;
  formDataDelete: any;
  //VARIABLES FORMULARIO DE EDICION
  estoy_edit: number = 0;       // variable bandera, indica si estoy editando o agregando;  0 = agregar; 1  = editar

  file: any = null;
  strImage: any;
  subiendo_imagen: boolean = false;



  constructor(
    private formBuilder: FormBuilder,
    private upload_service: UploadFileService,
    private activ_route: ActivatedRoute,
    private router: Router,
    private categoria_service: CategoriaService

  ) {
    this.activ_route.params.subscribe(params => {
      this.opciones_ruta = RoutesController.getInstance().evaluate_route(this.router.url, params);
      //OBTENGO EL ID de la secuencia anterior
      this.id_anterior = this.opciones_ruta[1].id_anterior
      this.secuencia_ant = this.opciones_ruta[2].secuencia_ant
    });
    //SE INICIALIZA LA INFORMACION
    this.inicialize()
  }

  ngOnInit(): void {
    // Inicializa el formulario
    this.reset_form('', '', this.secuencia_ant, '', 1, 'https://res.cloudinary.com/dksstmpfu/image/upload/v1609777772/test/categoria/ihswb9x5tunj2dgzpyxf.jpg', 0, 0);
    this.formDataDelete = this.formBuilder.group({
      'nombreConfirm': ['', Validators.required],
    });
  }

  // SECCION PARA INICIALZAR LAS CATEGORIAS DEL EVENTO / AREA / USUARIO 
  inicialize() {

    this.categoria_service.get_categoria_by_id_anterior(this.id_anterior, this.secuencia_ant).subscribe(data => {
      this.category_list = data
      if (this.category_list.length == 0) {
        AlertController.getInstance().show_swall("Alerta", "No hay datos disponibles, agrege categorias en '+ Nueva Categoria'", "warning")
      }
    });
  }
  /*//////////////////////////////////
      SECCION DE INSERSION DE DATOS
  ///////////////////////////////*/

  //FUNCION DE VALIDACION
  get f() { // Retorna los controles del formulario
    // que contiene la informacion para validar 
    return this.categoryForm.controls;
  }


  // GUARDAR CATEGORIA
  save_category() {

    // verifica si estoy agregando o actualizando
    if (this.estoy_edit == 0) { // 0 = agregar
      //ENVIA LA CATEGORIA AL SERVICIO
      this.categoria_service.post(JSON.stringify(this.categoryForm.value)).subscribe(
        (data) => {
          //INICIALIZA LOS DATOS OTRA VEZ PARA VER LOS CAMBIOS
          this.inicialize()
          AlertController.getInstance().show_swall("Categoría agregada", "Se ha ingresado la categoría correctamente", "success");
        },
        (err) => {
          console.log(err)
          AlertController.getInstance().show_swall("Error", "Ha ocurrido un error, por favor intentelo de nuevo más tarde", "error")
        });
    } else {
      // ENVIA LA CATEGORIA A EDITAR AL SERVICIO
      this.categoria_service.put(JSON.stringify(this.categoryForm.value), this.category_id).subscribe(
        (data) => {
          //INICIALIZA LOS DATOS OTRA VEZ PARA VER LOS CAMBIOS
          this.inicialize()
          AlertController.getInstance().show_swall("Categoría Actualizada", "Se ha actualizado la categoría correctamente", "success");
        },
        (err) => {
          AlertController.getInstance().show_swall("Error", "Ha ocurrido un error, por favor intentelo de nuevo más tarde", "error")
        }
      );
    }
    $('#modal_categoria').modal('hide');
  }
  /*
    ///////////////////////////
      SECCION DE ELIMINACION
    //////////////////////////
  */
  data_to_delete(id: number, titulo: string) {
    this.category_id = id;
    this.category_name = titulo;
    this.formDataDelete.reset();
  }
  delete_category() {
    this.categoria_service.delete(this.category_id).subscribe(
      (res) => {
        this.inicialize()
        AlertController.getInstance().show_swall("Categoría Eliminada", "La categoría se eliminó correctamente", "success");
        $('#modal-eliminar').modal('hide');
      },
      (err) => {
        AlertController.getInstance().show_swall("Error", "Ha ocurrido un error, intentelo de nuevo más tarde", "error");
      })
  }

  /*
    ///////////////////////////
      SECCION DE RUTAS
    //////////////////////////
  */

  get_ruta(id_category: number, id_opcion: number) {
    this.router.navigate([RoutesController.getInstance().get_ruta(this.router.url, id_category, id_opcion, 'c_')])
  }

  //hace un reset del formulario, es decir, lo limpia
  reset_form(titu: string, desc: string, sec1: number, sec: any, est: number, img: any, action: number, id_to_edit: number) {
    this.estoy_edit = action;
    this.strImage = img;
    this.category_id = id_to_edit
    this.file = null

    // Inicializa el formulario
    this.categoryForm = this.formBuilder.group({
      titulo: [titu, Validators.required],
      descripcion: [desc, Validators.required],
      img: [img, Validators.required],
      buscar_id: [this.id_anterior],
      secuencias_ing_id: [sec1],
      secuencias_egr_id: [sec, Validators.required],
      estado: [est],
      fecha: [RoutesController.getInstance().get_date()]
    });
  }

  //CARGAR IMAGEN
  seleccionImage(archivo: any) {
    this.file = archivo.target.files[0]
    if (!(this.file)) {
      return;
    }
    let reader = new FileReader();
    let urlImagenTemp = reader.readAsDataURL(this.file);
    reader.onloadend = () => this.strImage = reader.result;
  }

  uploadImage() {
    AlertController.getInstance().show_swall('Subiendo Imagen', 'Espere por favor', "warning")
    this.subiendo_imagen = true;
    this.upload_service.post(this.file, 'test/categoria')
      .then((res: any) => {
        AlertController.getInstance().show_swall('Imagen subida', 'La imagen se subió con éxito', "success")
        this.categoryForm.get('img').setValue(res.secure_url)
        this.subiendo_imagen = false;
      })
      .catch((resp) => {
        this.subiendo_imagen = false;
        AlertController.getInstance().show_error()
      });
  }
}
