<section id="wrapper" class="login-register login-sidebar"
    style="background-image:url(assets/images/background/signin-wallpaper.jpg);">
    <div class="login-box card">
        <div class="card-body" *ngIf="signinForm">
            <form class="form-horizontal form-material" [formGroup]="signinForm" role="form" data-parsley-validate=""
                novalidate="">
                <a class="text-center db"><img style="height: auto; width: 60%;" src="assets/images/logo.png"
                        alt="Home" />
                </a>
                <br>
                <div class="form-group">
                    <div class="row">

                        <div class="col-md-12 text-center">
                            <button class="btn btn-danger btn-rounded waves-effect waves-light" data-toggle="modal"
                                data-target="#modal-photo">
                                <span><i class="fa fa-camera"></i> Sube tu fotografía (opcional)</span>
                            </button>
                        </div>
                    </div>

                </div>
                <div class="form-group ">
                    <div class="row">
                        <div class="col-md-6">
                            <input class="form-control" formControlName="nombres" type="text" required=""
                                placeholder="Nombres">
                            <div *ngIf="f.nombres.invalid && (f.nombres.dirty || f.nombres.touched)">
                                <div *ngIf="f.nombres.errors.required" style="color:red">Dato requerido</div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <input class="form-control" type="text" formControlName="apellidos" required=""
                                placeholder="Apellidos">
                            <div *ngIf="f.apellidos.invalid && (f.apellidos.dirty || f.apellidos.touched)">
                                <div *ngIf="f.apellidos.errors.required" style="color:red">Dato requerido</div>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="form-group ">
                    <div class="row">
                        <div class="col-md-6">
                            <input class="form-control" type="text" required="" formControlName="email"
                                placeholder="Correo">
                            <div *ngIf="f.email.invalid && (f.email.dirty || f.email.touched)">
                                <div *ngIf="f.email.errors.required" style="color:red">Dato requerido</div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <input class="form-control" type="password" required="" formControlName="pass"
                                placeholder="Contraseña">
                            <div *ngIf="f.pass.invalid && (f.pass.dirty || f.pass.touched)">
                                <div *ngIf="f.pass.errors.required" style="color:red">Dato requerido</div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="form-group ">
                    <div class="row">
                        <div class="col-md-12">
                            <input class="form-control" type="number" required="" formControlName="numero"
                                placeholder="Telefono">
                            <div *ngIf="f.numero.invalid && (f.numero.dirty || f.numero.touched)">
                                <div *ngIf="f.numero.errors.required" style="color:red">Dato requerido</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group text-center">
                    <div class="btn btn-group">
                        <button (click)="onSubmit()" [disabled]="!signinForm.valid" class="btn btn-warning "
                            type="submit">Crear Cuenta</button>
                        <button (click)="signin_with_google()" class="btn btn-warning " type="submit"> <i
                                class="ti-google"> </i> Crear con Google</button>
                    </div>
                </div>

                <div class="form-group m-b-0">
                    <div class="col-sm-12 text-center">
                        <p>¿Ya tienes una cuenta? <a [routerLink]="['/login']" routerLinkActive="router-link-active"
                                class="text-warning m-l-5"><b>Acceder</b></a></p>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>


<div id="modal-photo" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
    style="display: none;" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Foto de perfil</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <form action="">
                    <div class="col-lg-12">
                        <div class="row el-element-overlay">
                            <div class="col-md-2">
                            </div>
                            <div class="col-md-8">
                                <div class="card" style="margin-bottom: 0px !important;">
                                    <div class="el-card-item" style="margin-bottom: 0px !important;">
                                        <div class="el-card-avatar el-overlay-1" style="margin-bottom: 0px !important;">
                                            <img *ngIf="!file"
                                                src="https://res.cloudinary.com/dksstmpfu/image/upload/v1610211147/test/categoria/sshhivfnkjoqjo13cenf.jpg"
                                                alt="area" />
                                            <img *ngIf="file" src="{{strImage}}" alt="Usuario" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                            </div>
                        </div>

                    </div>
                    <br>
                    <div class="form-group text-center">
                        <div class="fileupload btn btn-danger btn-rounded waves-effect waves-light"><span><i
                                    class="fa fa-camera"></i> Seleccionar Imagen</span>
                            <input type="file" accept="image/*" (change)="seleccionImage($event)" class="upload">
                        </div>
                    </div>
                </form>

            </div>
            <div class="modal-footer">
                <button data-dismiss="modal" class="btn btn-secondary waves-effect waves-light" type="button"><span
                        class="btn-label"><i class="ti-close"></i></span>Cerrar</button>
                <button [disabled]="!file" (click)="uploadImage()" class="btn btn-warning waves-effect waves-light" type="button"><span
                        class="btn-label"><i class="ti-save-alt"></i></span>Guardar Foto</button>
            </div>
        </div>
    </div>
</div>