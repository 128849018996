<div class="row">
    <div class="col-12">
        <div class="card">
            <!-- .left-right-aside-column-->
            <div class="event-page-aside">
                <div class="right-aside ">
                    <div class="right-page-header">
                        <div class="d-flex">
                            <div class="align-self-center">
                                <h4 class="card-title m-t-10">Listado de Eventos</h4>
                            </div>
                            <div class="ml-auto">
                                <input type="text" id="demo-input-search2" [(ngModel)]="searchText" placeholder="Buscar Evento"
                                    class="form-control"> </div>
                        </div>
                    </div>

                </div>
                <!-- /.left-right-aside-column-->
            </div>
        </div>
    </div>
</div>
<!-- ============================================================== -->
<!-- Start Page Content -->
<!-- ============================================================== -->




<div class="row">


        <div class="col-md-4" *ngFor="let event of event_list | filter:searchText">
            <div class="card" >
                <div class="card-body little-profile text-center">
                    <div class="pro-img m-t-20"><img src="{{event.img}}" alt="event"></div>
                    <h3 class="m-b-0">{{event.titulo}}</h3>
                    <h6 class="text-muted">{{event.descripcion}}</h6>
                    <br>
                    <small *ngIf="event.eventos_tipos_id == 2"><span
                        class="label label-warning label-rounded">MultiUsuario</span></small>
                    <small *ngIf="event.eventos_tipos_id == 1"><span
                            class="label label-danger label-rounded">Sin Usuarios</span></small>
                </div>
                <div class="text-center bg-light">
                    <div class="row">
                        <div class="col-6  p-20 b-r">
                            <h5 class="m-b-0 font-medium">{{event.fech_ini | date: 'short' }}</h5><small>Inicio</small></div>
                        <div class="col-6  p-20">
                            <h5 class="m-b-0 font-medium">{{event.fech_fin | date: 'short'}}</h5><small>Fin</small></div>
                    </div>
                </div>
                <div class="card-body text-center">
                    <a type="button" class="m-t-10 m-b-20 waves-effect waves-dark btn-md btn-rounded btn btn-warning" [routerLink]="['/evento/detalle', event.id]" routerLinkActive="router-link-active"    ><i class="fa fa-plus"></i> Ver Evento</a>
                </div>
            </div>
        </div>
    
    
    <!--Little Profile widget-->
</div>

<div *ngIf="event_list.length == 0">
    <br *ngFor="let item of [].constructor(16)">
</div>
