
    <!-- ============================================================== -->
    <!-- Main wrapper - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <section id="wrapper" class="login-register login-sidebar" style="background-image:url(assets/images/background/login-wallpaper.jpg);">
        <div class="login-box card">
            <div class="card-body" *ngIf="loginForm">
                <form class="form-horizontal form-material" [formGroup]="loginForm" role="form" data-parsley-validate="" novalidate="" >
                    <a class="text-center db"><img style="width: 60%; height: 60%;" src="../../../assets/images/logo.png" alt="Home" /></a>
                    <div class="form-group m-t-40">
                        <div class="col-xs-12">
                            <input class="form-control" formControlName="email" type="email" required="" placeholder="Correo">
                        </div>
                        <div *ngIf="f.email.invalid && (f.email.dirty || f.email.touched)">
                            <div *ngIf="f.email.errors.required" style="color:red">El correo es requerido</div> 
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-xs-12">
                            <input class="form-control" formControlName="password" type="password" required="" placeholder="Contraseña">
                        </div>
                        <div *ngIf="f.password.invalid && (f.password.dirty || f.password.touched)">
                            <div *ngIf="f.password.errors.required" style="color:red">La contraseña es requerida</div> 
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-12">
                            <a [routerLink]="['/recuperar-password']" routerLinkActive="router-link-active"  class="text-dark pull-right"><i class="fa fa-lock m-r-5"></i> Recuperar contraseña</a> </div>
                    </div>
                    <div class="text-center m-t-20" *ngIf="error">
                        <div style="color:red">{{ mensaje }}</div> 
                    </div>
                    <div class="form-group text-center m-t-20">
                        <div class="col-xs-12">
                            <button  (click)="login_with_email()" [disabled]="!loginForm.valid"  class="btn btn-warning btn-lg btn-block text-uppercase btn-rounded" type="submit">Log In</button>
                        </div>
                    </div>
                    <div class="form-group text-center m-t-20">
                        <div class="col-xs-12">
                            <button  (click)="login_with_google()"  class="btn btn-warning btn-lg btn-block text-uppercase btn-rounded" type="submit"> <i class="ti-google"> </i> LogIn con Google</button>
                        </div>
                    </div>
                    
                    <div class="form-group m-b-0">
                        <div class="col-sm-12 text-center">
                            ¿No tienes una cuenta? <a [routerLink]="['/signin']" routerLinkActive="router-link-active"  class="text-warning m-l-5"><b>Registrate</b></a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </section>