import { RouterModule, Routes } from "@angular/router";
import { RoutesController } from "../controller/routes.controller";
import { ProfileComponent } from "../shared/profile/profile.component";
import { AdminHomeComponent } from "./admin-home/admin-home.component";
import { AdminListadoEventosComponent } from "./admin-listado-eventos/admin-listado-eventos.component";
import { AdminTipoProductoComponent } from "./admin-tipo-producto/admin-tipo-producto.component";
import { ComprarAppComponent } from "./comprar-app/comprar-app.component";
import { EnviosDetalleComponent } from "./envios-detalle/envios-detalle.component";
import { EnviosComponent } from "./envios/envios.component";
import { EventoDetalleComponent } from "./evento-detalle/evento-detalle.component";
import { EventosAsignadosComponent } from "./eventos-asignados/eventos-asignados.component";
import { EventosGeneralesComponent } from "./eventos-generales/eventos-generales.component";
import { ListaUsuariosComponent } from "./lista-usuarios/lista-usuarios.component";
import { MisEventosComponent } from "./mis-eventos/mis-eventos.component";
import { AreaEventoComponent } from "./nuevo-evento/area-evento/area-evento.component";
import { CategoriaEventoComponent } from "./nuevo-evento/categoria-evento/categoria-evento.component";
import { NuevoEventoComponent } from "./nuevo-evento/nuevo-evento.component";
import { ProductoEventoComponent } from "./nuevo-evento/producto-evento/producto-evento.component";
import { UsuarioEventoComponent } from "./nuevo-evento/usuario-evento/usuario-evento.component";

const pagesRoutes: Routes = [
    { path: 'buyapp', component: ComprarAppComponent },

    /*//////////////////////
        RUTAS USUARIO
    /////////////////////   
    */
    
    { path: 'eventos-generales', component: EventosGeneralesComponent },
    { path: 'mis-eventos', component: MisEventosComponent },

    { path: 'evento/:id', component: NuevoEventoComponent },
    { path: 'evento/detalle/:id', component: EventoDetalleComponent },
    
    /* 
    /////////////////////////////
      RUTAS EVENTO MULTIUSUARIO
    /////////////////////////////   
  */
  { path: 'evento/:id_event/multiusuario/area', component: AreaEventoComponent },
  
  /*  MULTIUSUARIO  AREA - PRODUCTO */
  { path: 'evento/:id_event/multiusuario/area/:id_area/a_producto', component: ProductoEventoComponent },

  /*  MULTIUSUARIO  AREA - USUARIO */
  { path: 'evento/:id_event/multiusuario/area/:id_area/a_usuario', component: UsuarioEventoComponent },
  { path: 'evento/:id_event/multiusuario/area/:id_area/a_usuario/:id_user/u_categoria', component: CategoriaEventoComponent },
  { path: 'evento/:id_event/multiusuario/area/:id_area/a_usuario/:id_user/u_categoria/:id_category/c_producto', component: ProductoEventoComponent },
  { path: 'evento/:id_event/multiusuario/area/:id_area/a_usuario/:id_user/u_product', component: ProductoEventoComponent },

  /*  MULTIUSUARIO AREA - CATEGORIA */
  { path: 'evento/:id_event/multiusuario/area/:id_area/a_categoria', component: CategoriaEventoComponent },
  { path: 'evento/:id_event/multiusuario/area/:id_area/a_categoria/:id_category/c_producto', component: ProductoEventoComponent },
  { path: 'evento/:id_event/multiusuario/area/:id_area/a_categoria/:id_category/c_usuario', component: UsuarioEventoComponent },
  { path: 'evento/:id_event/multiusuario/area/:id_area/a_categoria/:id_category/c_usuario/:id_user/u_categoria', component: CategoriaEventoComponent },
  { path: 'evento/:id_event/multiusuario/area/:id_area/a_categoria/:id_category/c_usuario/:id_user/u_categoria/:id_category2/c_producto', component: ProductoEventoComponent },



  //SECUENCIA CATEGORIA
  { path: 'evento/:id_event/multiusuario/categoria', component: CategoriaEventoComponent },
  { path: 'evento/:id_event/multiusuario/categoria/:id_category/c_producto', component: ProductoEventoComponent },
  
  //SECUENCIA USUARIOS
  { path: 'evento/:id_event/multiusuario/eusuario', component: UsuarioEventoComponent },
  { path: 'evento/:id_event/multiusuario/eusuario/:id_user/u_categoria', component: CategoriaEventoComponent },
  { path: 'evento/:id_event/multiusuario/eusuario/:id_user/u_categoria/:id_category/c_producto', component: ProductoEventoComponent },
  { path: 'evento/:id_event/multiusuario/eusuario/:id_user/u_producto', component: ProductoEventoComponent },



  /*////////////////////////////
    RUTAS EVENTO SIN USUARIO
  ////////////////////////////*/
  // RUTAS USUARIO-AREA

  { path: 'evento/:id_event/no-usuario/area', component: AreaEventoComponent },
  
  // RUTAS NO USUARIO - AREA - CATEGORIA
  { path: 'evento/:id_event/no-usuario/area/:id_area/a_categoria', component: CategoriaEventoComponent },
  { path: 'evento/:id_event/no-usuario/area/:id_area/a_categoria/:id_category/c_producto', component: ProductoEventoComponent },
  // RUTAS NO USUARIO - AREA - PRODUCTO
  { path: 'evento/:id_event/no-usuario/area/:id_area/a_producto', component: ProductoEventoComponent },
  
  
  //RUTAS USUARIO-CATEGORIA
  { path: 'evento/:id_event/no-usuario/categoria', component: CategoriaEventoComponent },
  { path: 'evento/:id_event/no-usuario/categoria/:id_category/c_producto', component: ProductoEventoComponent },


  //RUTAS USUARIO-PRODUCTO
  { path: 'evento/:id_event/no-usuario/producto', component: ProductoEventoComponent },
  
  /*///////////////////////////////////
      ASIGNACION USUARIOS AGREGADOS 
    /////////////////////////////////*/
  {path: 'evento/multiusuario/asignar-usuario/:id_ant/sec/:id_sec', component: ListaUsuariosComponent},
  {path: 'eventos-asignados', component: EventosAsignadosComponent},

  
  /*/////////////////
      RUTAS ADMIN
  ////////////////*/
  
  {path: 'home', component: AdminHomeComponent},
  {path: 'tipo-productos', component: AdminTipoProductoComponent},
  {path: 'listado-eventos', component: AdminListadoEventosComponent},
  {path: 'lista-usuarios', component: ListaUsuariosComponent},

  /*/////////////////
      RUTAS AMBOS USUARIOS
  ////////////////*/
  
  
  /*///////////////////////////////////
                 ENVIOS 
    /////////////////////////////////*/
  {path: 'envios', component: EnviosComponent},
  {path: 'envios/evento/:id_event', component: EnviosDetalleComponent},
    /*///////////////////////////////////
                 Recaudaciones 
    /////////////////////////////////*/
  {path: 'evento/:id_event/recaudaciones', component: EnviosDetalleComponent},

    /*///////////////////////////////////
                 PERFIL 
    /////////////////////////////////*/

  { path: 'mi-perfil', component: ProfileComponent },

  
  { path: '', redirectTo: RoutesController.getInstance().get_route(), pathMatch: 'full' }


];


export const PAGES_ROUTES = RouterModule.forChild( pagesRoutes );
