import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController } from 'src/app/controller/alert.controller';
import { RoutesController } from 'src/app/controller/routes.controller';
import { EventService } from 'src/app/services/event.service';
import { UploadFileService } from 'src/app/services/upload-file.service';

@Component({
  selector: 'app-nuevo-evento',
  templateUrl: './nuevo-evento.component.html'
})
export class NuevoEventoComponent implements OnInit {

  //VARIABLES GLOBALES
  mensaje:      string = 'Nuevo Evento'
  //VARIABLES FORMULARIO
  evento_form:  any;
  pago_form:    any;
  strImage:     any;
  file:         any;
  fecha_inicio: any;
  // VARIABLES PARA DETERMINAR EL TIPO DE EVENTO Y LA SECUENCIA
  tipo_evento_seleccionado: number = 0;
  secuencia_seleccionada:   number = 0;

  // VARIABLES PARA EDITAR
  id_event:       any;
  estoy_editando: boolean = false;
  mostrar_form:   boolean = false;
  subiendo_imagen:boolean = false;

  constructor(private upload_service: UploadFileService, private event_service: EventService,
    private formBuilder: FormBuilder, private activ_route: ActivatedRoute, private router: Router) {

    this.activ_route.params.subscribe(param => {
      //VERIFICO SI LO QUE ESTOY HACIENDO ES CREAR O EDITAR        
      this.id_event = param['id']

      if (this.id_event == 'create') {
        // Inicializa el formulario
        this.reset_form('', 'https://res.cloudinary.com/dksstmpfu/image/upload/v1609965264/test/categoria/sldl1o85ruzeockszmth.jpg', '', '', '', '', '', Number(localStorage.getItem('id')), 1, 0, 0);
        this.reset_form_pago();

      } else {
        this.estoy_editando = true;
        this.mostrar_form = true;
        //mando a buscar el evento 
        this.event_service.get_event_by_id(this.id_event).subscribe(
          (data: any) => {
            this.mensaje = 'Edición de Evento: ' + data.titulo
            // Inicializa el formulario con los datos a editar
            this.reset_form(data.titulo, data.img, data.descripcion, (String(data.fech_ini).split('T'))[0], (String(data.fech_fin).split('T'))[0], data.eventos_tipos_id, data.secuencias_id,
              data.usuarios_id, data.estado, data.recaudado, data.transferido);
          });
      }
    });
  }

  ngOnInit(): void {
  }

  reset_form(tit: string, imga: any, des: string, fec1: string, fech2: string, tip: string,
    sec: any, user: any, estado: number, rec: number, transf: number) {
    this.strImage = imga
    this.secuencia_seleccionada = sec;
    this.evento_form = this.formBuilder.group({
      titulo:           [tit, Validators.required],
      img:              [imga, Validators.required],
      descripcion:      [des, Validators.required],
      fech_ini:         [fec1, Validators.required],
      fech_fin:         [fech2, Validators.required],
      eventos_tipos_id: [tip, Validators.required],
      secuencias_id:    [sec, Validators.required],
      usuarios_id:      [user],
      estado:           [estado],
      fecha:            [RoutesController.getInstance().get_date()],
      hora:             [RoutesController.getInstance().get_hour()],
      eventos_precio:   [0],
      recaudado:        [rec],
      transferido:      [transf]
    });
  }

  reset_form_pago() {
    this.pago_form = this.formBuilder.group({
      nombre:       ['', Validators.required],
      tarjeta:      ['', Validators.required],
      estado:       [1],
      fecha:        [RoutesController.getInstance().get_date()],
      mes:          ['', Validators.required],
      year:         ['', Validators.required],
      cvv:          ['', Validators.required],
      titulo:       [''],
      descripcion:  [''],
      valor:        [100]
    });

  }


  //OBTIENE EL ID DE LA SECUENCIA, AREA = 0; CATEGORIA = 1; PRODUCTO = 2; USUARIO = 3
  get_secuencia(sec: any) {
    this.secuencia_seleccionada = sec.target.value

  }
  // SE OBTIENE EL TIPO DE EVENTO, USUARIO = 1; MULTIUSUARIO = 2
  select_tipo_evento(tipo: any) {
    this.tipo_evento_seleccionado = tipo.target.value
  }
  // SE OBTIENE LA FECHA DE INICIO
  get_fecha_inicio(fecha: any) {
    this.fecha_inicio = fecha.target.value
  }

  /*//////////////////////////////////
      SECCION DE INSERSION DE DATOS
  ///////////////////////////////*/

  get f() { // Retorna los controles del formulario que contiene la informacion para validar 
    return this.evento_form.controls;
  }


  //CARGAR IMAGEN
  seleccionImage(archivo: any) {

    if (!(archivo.target.files[0])) {
      return;
    }
    this.file = archivo.target.files[0]
    let reader = new FileReader();
    reader.readAsDataURL( this.file );
    reader.onloadend = () => this.strImage = reader.result;
    this.uploadImage();
  }
  uploadImage(){
    this.subiendo_imagen = true;
    this.upload_service.post(this.file, 'test/categoria')
    .then((res: any) => {
      AlertController.getInstance().show_swall('Imagen subida', 'La imagen se subió con éxito', "success")
      this.evento_form.get('img').setValue(res.secure_url);
      this.subiendo_imagen = false;
    })
    .catch((resp) => {
      AlertController.getInstance().show_error()
      this.subiendo_imagen = false;
    });
  }


  //CREA EL EVENTO
  create_event() {
    //VERIFICA SI EL FORMULARIO ES VALIDO

    // Se detiene aqui si la secuencia es invalida
    if (this.secuencia_seleccionada == 0) {
      AlertController.getInstance().show_swall("Error", "Debe llenar todos los campos", "error");
      return;
    }


    //VERIFICA QUE SE PUEDA CARGAR LA MAGEN

    if (!this.estoy_editando) { // si la bandera es falsa, significa que estoy agrengando

      // SE ENVIA EL PAGO
      this.pago_form.get('titulo').setValue(this.evento_form.value.titulo)
      this.pago_form.get('descripcion').setValue(this.evento_form.value.descripcion)

      this.event_service.post_evento_precio(this.pago_form.value).subscribe(
        (data: any) => {

          //SE GUARDA EN EL EVENTO EL REGISTRO DEL PAGO
          this.evento_form.get('eventos_precio').setValue(data.id)


          this.event_service.post(JSON.stringify(this.evento_form.value)).subscribe(
            (res: any) => { //VERIFICA QUE NO HAYA ERROR AL HACER POST DEL EVENTO

              let uri = "/evento/" + res.datos.id

              if (this.tipo_evento_seleccionado == 2) {
                uri = uri + "/multiusuario/"
              } else {
                uri = uri + "/no-usuario/"
              }

              if (this.secuencia_seleccionada == 1) {
                uri = uri + "/area"
              }
              else if (this.secuencia_seleccionada == 2) {
                uri = uri + "/categoria"
              }
              else if (this.secuencia_seleccionada == 3) {
                uri = uri + "/producto"
              }
              else if (this.secuencia_seleccionada == 4) {
                uri = uri + "/eusuario"
              }

              AlertController.getInstance().show_swall("Evento Creado", "El evento se ha creado con exito", "success");

              setTimeout(() => {
                this.router.navigate([uri]);
              }, 3000)

            },
            (err) => {
              AlertController.getInstance().show_swall("Error", "Ha ocurrido un error, por favor intentelo de nuevo mas tarde", "error");
            }
          );

        },
        (err) => {
          AlertController.getInstance().show_swall('Error', 'Ha ocurrido un error al efectuarl el pago, intentelo de nuevo más tarde', 'error')
        });
    } else {
      this.event_service.put(JSON.stringify(this.evento_form.value), this.id_event).subscribe(
        (res) => {
          AlertController.getInstance().show_swall("Evento Editado", "El evento se ha editado con exito", "success");
          this.router.navigate(['mis-eventos'])
        },
        (err) => {
          console.log(err)
          AlertController.getInstance().show_swall("Error", "Ha ocurrido un error, por favor intentelo de nuevo mas tarde", "error");
        })
    }
  }

  /**
   * ///////////////////////////////
   *    SECCION DE PAGO DE EVENTO
   * //////////////////////////////
   */

  get g() { // Retorna los controles del formulario de pago
    return this.pago_form.controls;
  }

  validate_card() {

    AlertController.getInstance().show_swall('Tarjeta Válida', 'La tarjeta fue validada de forma exitosa, el pago se va a transferir al guardar el evento', 'success')
    this.mostrar_form = true

  }
}
