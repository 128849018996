<br>
<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                

                <div class="d-flex">
                    <div class="align-self-center">
                        <h4 class="card-title m-t-10">Tipos Actuales</h4></div>
                    <div class="ml-auto">
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="Buscar tipo" [(ngModel)]="searchText">
                            <button type="button" (click)="reset_form('','', 0, 1)" data-toggle="modal" data-target="#modal_tipo" class="btn btn-sm btn-warning btn-create">Nuevo tipo</button>
                        </div>
                    </div>
                </div>
                <br>
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Titulo</th>
                                <th>Descripción</th>
                                <th>Estado</th>
                                <th>Fecha de registro</th>
                                <th class="text-nowrap">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let tipo of tipo_list | filter:searchText | paginate: { itemsPerPage: 10, currentPage: p }">
                                <td>{{ tipo.titulo }}</td>
                                <td>{{ tipo.descripcion }}</td>
                                <td>
                                    <span *ngIf="tipo.estado == 1" class="label label-primary">Disponible</span>
                                    <span *ngIf="tipo.estado == 2" class="label label-danger">No disponible</span>
                                </td>
                                <td>{{ tipo.fecha | date : 'short' }}</td>
                                <td class="text-nowrap">
                                    <a type="button" (click)="get_tipo_to_edit(tipo)" data-toggle="modal" data-target="#modal_tipo" > <i class="fa fa-pencil text-inverse m-r-10"></i> </a>
                                    <a type="button" (click)="delete_tipo(tipo.id)"> <i class="fa fa-trash text-danger"></i> </a>
                                </td>
                            </tr>
                        </tbody>                        
                    </table>
                   
                    <div class="text-right">
                        <pagination-controls (pageChange)="p = $event"></pagination-controls>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>

<div>
    <br *ngFor="let i of saltos">
</div>

<div id="modal_tipo" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal_tipoLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="modal_tipoLabel">Detalles de Tipo</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <form [formGroup]="tipo_form" role="form" data-parsley-validate="" novalidate=""> 
                    <div class="form-group">
                        <label for="tipo_name" class="control-label">Título:</label>
                        <input type="text" formControlName="titulo" class="form-control" id="tipo_name1">
                        <div  *ngIf="submitted && f.titulo.errors">
                            <div *ngIf="f.titulo.errors.required" style="color:red">El título es requerido</div> 
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="tipo_descripcion" class="control-label">Descripción:</label>
                        <textarea class="form-control" formControlName="descripcion" id="tipo_descripcion1"></textarea>
                        <div  *ngIf="submitted && f.descripcion.errors">
                            <div *ngIf="f.descripcion.errors.required" style="color:red">La descripción es requerida</div> 
                        </div>
                    </div>
                    
                    <div class="form-group">
                        <label for="tipo_estado" class="control-label">Estado:</label>
                        <br>
                        <span class="switchery switchery-default" id="tipo_estado"  (click)="estado = 2"
                                *ngIf="estado == 1"
                                style="background-color: #ea7118; border-color: #ea7118; box-shadow: #ea7118 0px 0px 0px 16px inset; transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s, background-color 1.2s ease 0s;">
                                <small
                                    style="left: 20px; transition: background-color 0.4s ease 0s, left 0.2s ease 0s; background-color: rgb(255, 255, 255);"></small></span>
                            <span id="tipo_estado" class="switchery switchery-default" (click)="estado = 1"
                                *ngIf="estado == 2"
                                style="background-color: rgb(255, 255, 255); border-color: rgb(223, 223, 223); box-shadow: rgb(223, 223, 223) 0px 0px 0px 0px inset; transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s;"><small
                                    style="left: 0px; transition: background-color 0.4s ease 0s, left 0.2s ease 0s;"></small></span>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="reset_form('','', 0, 1)"  class="btn btn-default" data-dismiss="modal">Cancelar</button>
                <button type="button" (click)="save_tipo()" [disabled]="loading"  class="btn btn-warning">Guardar Tipo</button>
            
            </div>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>