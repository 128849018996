export class RoutesController{

    //GLOVAL VARIABLES
    today:       Date;
    currentDate: string = "";
    currentTime: string = "";



    //SINGLETON
    private static instance: RoutesController;

    private constructor() {
        this.today = new Date();
     }

    public static getInstance(): RoutesController {
        if (!RoutesController.instance) {
            RoutesController.instance = new RoutesController();
        }
        return RoutesController.instance;
    }


    evaluate_route(route: string, ids: any) {
        //MULTIUSUARIO
        if (route.includes('/multiusuario')) {
            
            //AREA
            if (route.includes('/area')) {
                
                if ( !route.includes('/a_categoria') && !route.includes('/a_usuario') && !route.includes('/a_producto')) {
                    return [ [{value: 2, option: 'Categoría'},{value: 3, option: 'Producto'} , {value: 4, option: 'Usuario'}], {'id_anterior' : ids.id_event }, {'secuencia_ant' : 0}]
                }
                
                // AREA - PRODUCTO
                if ( route.includes('/a_producto') && !route.includes('/a_categoria') && !route.includes('/a_usuario')) {
                    return [ [], {'id_anterior' : ids.id_area }, {'secuencia_ant' : 1}]
                }
                
                // AREA - USUARIO
                if ( route.includes('/a_usuario') && !route.includes('/u_producto') && !route.includes('/u_categoria')) {
                    return [ [{value: 2, option: 'Categoría'},{value: 3, option: 'Producto'}], {'id_anterior' : ids.id_area }, {'secuencia_ant' : 1}]
                }
                // AREA - USUARIO - PRODUCTO
                if ( route.includes('/a_usuario') && route.includes('/u_producto') && !route.includes('/u_categoria')) {
                    return [ [], {'id_anterior' : ids.id_user }, {'secuencia_ant' : 4}]
                }
                // AREA - USUARIO - CATEGORIA
                if ( route.includes('/a_usuario') && route.includes('/u_categoria') && !route.includes('/u_producto') 
                    && !route.includes('/c_producto')) {
                    return [ [{value: 3, option: 'Producto'}], {'id_anterior' : ids.id_user }, {'secuencia_ant' : 4}]
                }
                // AREA - USUARIO - CATEGORIA - PRODUCTO
                if ( route.includes('/a_usuario') && route.includes('/u_categoria') && route.includes('/c_producto')) {
                    return [ [], {'id_anterior' : ids.id_category }, {'secuencia_ant' : 2}]
                }
                // AREA - CATEGORIA
                if ( route.includes('/a_categoria') && !route.includes('/c_producto') && !route.includes('/c_usuario')) {
                    return [ [{value: 3, option: 'Producto'}, {value: 4, option: 'Usuario'}], {'id_anterior' : ids.id_area }, {'secuencia_ant' : 1}]
                }
                // AREA - CATEGORIA - PRODUCTO
                if ( route.includes('/a_categoria') && route.includes('/c_producto') && !route.includes('/c_usuario')) {
                    return [ [], {'id_anterior' : ids.id_category }, {'secuencia_ant' : 2}]
                }
                // AREA - CATEGORIA - USUARIO
                if ( route.includes('/a_categoria')&& route.includes('/c_usuario') && !route.includes('/c_producto') && !route.includes('/u_categoria')) {
                    return [ [{value: 2, option: 'Categoría'}], {'id_anterior' : ids.id_category }, {'secuencia_ant' : 2}]
                }
                // AREA - CATEGORIA - USUARIO - CATEGORIA
                if ( route.includes('/a_categoria')&& route.includes('/c_usuario') && route.includes('/u_categoria') && !route.includes('/c_producto')) {
                    return [ [{value: 3, option: 'Producto'}], {'id_anterior' : ids.id_user }, {'secuencia_ant' : 4}]
                }
                // AREA - CATEGORIA - USUARIO - CATEGORIA - PRODUCTO
                if ( route.includes('/a_categoria')&& route.includes('/c_usuario') && route.includes('/u_categoria') && route.includes('/c_producto')) {
                    return [ [], {'id_anterior' : ids.id_category2 }, {'secuencia_ant' : 2}]
                }
                
            } 
            //CATEGORIA
            else if(route.includes('/categoria')){
                if (!route.includes('/c_producto')) {
                    return [ [{value: 3, option: 'Producto'}], {'id_anterior' : ids.id_event }, {'secuencia_ant' : 0}]
                }
                //CATEGORIA - PRODUCTO
                else {
                    return [ [], {'id_anterior' : ids.id_category }, {'secuencia_ant' : 2}]
                }
            }
            
            //USUARIO
            else if(route.includes('/eusuario')){
                if (!route.includes('/u_producto') && !route.includes('/u_categoria')) {
                    return [ [{value: 3, option: 'Producto'}, {value: 2, option: 'Categoría'}], {'id_anterior' : ids.id_event }, {'secuencia_ant' : 0}]
                }
                // USUARIO - PRODUCTO
                if (route.includes('/u_producto') && !route.includes('/u_categoria')) {
                    return [ [], {'id_anterior' : ids.id_user }, {'secuencia_ant' : 4}]
                }
                // USUARIO - CATEGORIA
                if (!route.includes('/u_producto') && route.includes('/u_categoria')) {
                    return [ [{value: 3, option: 'Producto'}], {'id_anterior' : ids.id_user }, {'secuencia_ant' : 4}]
                }
                // USUARIO - CATEGORIA - PRODUCT
                if (route.includes('/u_producto') && route.includes('/u_categoria')) {
                    return [ [], {'id_anterior' : ids.id_category }, {'secuencia_ant' : 2}]
                }
            }

            


        //NO - USUARIO
        
        } else {
            //AREA 
            if (route.includes('/area')) {
                
                if ( !route.includes('/a_categoria') && !route.includes('/a_producto')) {
                    return [ [{value: 2, option: 'Categoría'}, {value: 3, option: 'Producto'}], {'id_anterior' : ids.id_event }, {'secuencia_ant' : 0}]
                }
                // AREA - PRODUCTO
                if ( route.includes('/a_producto')) {
                    return [ [], {'id_anterior' : ids.id_area }, {'secuencia_ant' : 1}]
                }

                // AREA - CATEGORIA
                if ( route.includes('/a_categoria') && !route.includes('/c_producto')) {
                    return [ [{value: 3, option: 'Producto'}], {'id_anterior' : ids.id_area }, {'secuencia_ant' : 1}]
                }
                // AREA - CATEGORIA - PRODUCTO
                if ( route.includes('/a_categoria') && route.includes('/c_producto')) {
                    return [ [], {'id_anterior' : ids.id_category }, {'secuencia_ant' : 2}]
                }

            } 
            //CATEGORIA
            else if(route.includes('/categoria')){
                if ( !route.includes('/c_producto')) {
                    return [ [{value: 3, option: 'Producto'}], {'id_anterior' : ids.id_event }, {'secuencia_ant' : 0}]
                }
                //CATEGORIA - PRODUCTO
                else {
                    return [ [], {'id_anterior' : ids.id_category }, {'secuencia_ant' : 2}]
                }
            } 
            // PRODUCTO
            else {
                return [ [], {'id_anterior' : ids.id_event }, {'secuencia_ant' : 0}]   
            }  
        } 
        return []
    }

    //RETORNA LA NUEVA RUTA
    //id_actual = id desde donde estoy => nuevo id_busqueda
    //id_opcion = id hacia donde me quiero mover
    get_ruta(ruta_actual: string, id_actual: number, id_opcion: number, pre: string) : string {
        
        if (id_opcion == 1) {
            ruta_actual = ruta_actual + "/" + id_actual + "/area"
        } 
        else if(id_opcion == 2) {
            ruta_actual = ruta_actual + "/" + id_actual + "/" + pre + "categoria"    
        }
        else if(id_opcion == 3) {
            ruta_actual = ruta_actual + "/" + id_actual + "/" + pre + "producto"
        }
        else if(id_opcion == 4) {
            ruta_actual = ruta_actual + "/" + id_actual + "/" + pre + "usuario"    
        }
        return ruta_actual
    }

    //Funcion que retorna la hora
    get_hour(): string {
        return this.currentTime = this.today.getHours() + ":" + this.today.getMinutes() + ":" + this.today.getSeconds()
    }
    get_date(): string {
        var month: any = Number(this.today.getMonth()) + 1
        if (month < 10) {
            month = '0' + month
        }
        return this.currentDate = this.today.getFullYear() + "-" + month + "-" + this.today.getDate()
    }


    get_route(): string {
        if (localStorage.getItem('currentUser')) {
            // Si hay un usuario logueado retorna true
            if (localStorage.getItem('admin') == '1') {
                return 'home'
            } else {
                return 'eventos-generales'
            }
        }
        return 'login'
    }

}