//SWAL
declare var swal: any;
//JQUERY
declare var $: any;


export class AlertController {





    //SINGLETON
    private static instance: AlertController;

    private constructor() {
    }

    public static getInstance(): AlertController {
        if (!AlertController.instance) {
            AlertController.instance = new AlertController();
        }
        return AlertController.instance;
    }



    // funcion que muestra las alertas
    show_swall(title: string, text: string, type: string) {
        swal(
            {
                title: title,
                text: text,
                type: type,
                confirmButtonColor: "#ea7118",
                confirmButtonText: "Aceptar",
                closeOnConfirm: true
            });
    }    
    show_error(){
        this.show_swall('Error','Ha ocurrido un error, inténtelo de nuevo más tarde.','error')
    }

}