import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController } from 'src/app/controller/alert.controller';
import { RoutesController } from 'src/app/controller/routes.controller';
import { ProductService } from 'src/app/services/product.service';
import { UploadFileService } from 'src/app/services/upload-file.service';

//JQUERY
declare var $: any;

@Component({
  selector: 'app-producto-evento',
  templateUrl: './producto-evento.component.html'
})

export class ProductoEventoComponent implements OnInit {

  //VARIABLES GENERALES

  opciones_ruta: any = []        //guarda las opciones de ruta a partir de donde estoy
  id_anterior: number = 0;    //guarda el id anterior a la secuencia
  product_list: any[] = []    //guarda la lista de usuarios
  secuencia_ant: any;          //guarda la secuencia desde donde vengo
  product_id: number = 0;
  product_name: string = ''

  //VARIABLES DEL FORMULARIO DE AREA
  product_form: any;
  formDataDelete: any
  searchText: any;
  //VARIABLES DEL FORMULARIO PARA EDITAR
  estoy_edit: number = 0    //bandera para decidir si estoy editando o no  
  //VARIABLES DE PRODUCTO
  product_tipe_list: any[] = []

  file: any = null;
  strImage: any;
  subiendo_imagen: boolean = false;


  constructor(
    private formBuilder: FormBuilder,
    private activ_route: ActivatedRoute,
    private router: Router,
    private upload_service: UploadFileService,
    private product_service: ProductService) {

    this.activ_route.params.subscribe(param => {
      // SE ENVIA A EVALUAR LA RUTA PARA OBTENER LAS OPCIONES DE SECUENCIA Y EL ID DE SECUENCIA ANTERIOR
      this.opciones_ruta = RoutesController.getInstance().evaluate_route(this.router.url, param)
    })
    //SE ASIGNA EL ID DE LA SECUENCIA ANTERIOR
    this.id_anterior = this.opciones_ruta[1].id_anterior
    this.secuencia_ant = this.opciones_ruta[2].secuencia_ant
    //inicializa la data
    this.inicialize();
  }

  ngOnInit(): void {
    this.reset_form('', '', 'https://res.cloudinary.com/dksstmpfu/image/upload/v1609783374/test/categoria/iksyz7wws54irlszplkw.jpg', '', 0, '', 0, 1, 0);
    this.formDataDelete = this.formBuilder.group({
      'nombreConfirm': ['', Validators.required],
    });
  }


  // SECCION DE DATA
  inicialize() {
    /*TIPO PRODUCTO */
    this.product_service.product_tipo_get_all().subscribe(data => {
      this.product_tipe_list = data
    });

    //OBTENGO LOS PRODUCTOS DEL ID ANTERIOR, ES DECIR, LA SECUENCIA DE DONDE VENGO
    this.product_service.get_product_by_id_anterior(this.id_anterior, this.secuencia_ant).subscribe(data => {
      this.product_list = data
      if (this.product_list.length == 0) {
        AlertController.getInstance().show_swall("Alerta", "No hay datos disponibles, agrege productos en '+ Nuevo Producto'", "warning")
      }
    });

    //LIMPIA LAS VARIABLES
    $('#modal_produc').modal('hide');
    this.reset_form('', '', 'https://res.cloudinary.com/dksstmpfu/image/upload/v1609783374/test/categoria/iksyz7wws54irlszplkw.jpg', '', 0, '', 0, 1, 0)
  }


  /*//////////////////////////////////
       SECCION DE INSERSION DE DATOS
   ///////////////////////////////*/

  //FUNCION DE VALIDACION

  get f() { // Retorna los controles del formulario
    // que contiene la informacion para validar 
    return this.product_form.controls;
  }


  //Guarda el producto en la db 

  save_product() {

    if (this.estoy_edit == 0) {  // si la bandera de edicion es 0, significa que estoy agregando

      //envia el producto a la db
      this.product_service.post(JSON.stringify(this.product_form.value)).subscribe(
        (res: any) => {

          var formulario = this.get_asignation_product_event(res.params.id, this.id_anterior, this.product_form.value.estado)

          //SE HACE LA ASIGNACION
          this.product_service.product_event_post(formulario).subscribe(
            (data) => {
              //inicializa la data
              this.inicialize()

              AlertController.getInstance().show_swall("Producto Agregado", "El producto se agrego correctamente", "success");
            },
            (err) => {
              AlertController.getInstance().show_swall("Error", "El producto se ha agregado pero no se ha podido asignar al evento", "error");
            }
          );
        },
        (err) => {
          AlertController.getInstance().show_swall("Error", "Ha ocurrido un error, intentelo de nuevo mas tarde", "error");
        });

    } else { //si la bandera de edicion es 1 significa que estoy editando

      this.product_service.put(JSON.stringify(this.product_form.value), this.product_id).subscribe(
        (res) => {

          //inicializa la data
          this.inicialize()
          //muestra mensaje de success
          AlertController.getInstance().show_swall("Producto Actualizado", "El producto se actualizo correctamente", "success");

        },
        (err) => {
          AlertController.getInstance().show_swall("Error", "Ha ocurrido un error, intentelo de nuevo mas tarde", "error");
        });
    }
  }
  /*
  ///////////////////////////
    SECCION DE ELIMINACION
  //////////////////////////
  */
  data_to_delete(id: number, titulo: string) {
    this.product_id = id;
    this.product_name = titulo;
    this.formDataDelete.reset();
  }
  delete_product() {
    this.product_service.delete(this.product_id).subscribe(
      (res) => {
        this.inicialize()
        AlertController.getInstance().show_swall("Producto Eliminado", "El producto se elimino correctamente", "success");
        $('#modal-eliminar').modal('hide');
      },
      (err) => {
        AlertController.getInstance().show_error();

      })
  }
  //CARGAR IMAGEN
  seleccionImage(archivo: any) {

    if (!(archivo.target.files[0])) {
      return;
    }
    this.file = archivo.target.files[0]
    let reader = new FileReader();
    reader.readAsDataURL(this.file);
    reader.onloadend = () => this.strImage = reader.result;
  }
  uploadImage() {
    AlertController.getInstance().show_swall('Subiendo Imagen', 'Espere por favor', "warning")
    this.subiendo_imagen = true
    this.upload_service.post(this.file, 'test/categoria')
      .then((res: any) => {
        AlertController.getInstance().show_swall('Imagen subida', 'La imagen se subió con éxito', "success")
        this.product_form.get('img').setValue(res.secure_url);
        this.subiendo_imagen = false
      })
      .catch((resp) => {
        this.subiendo_imagen = false;
        AlertController.getInstance().show_error();
      });
  }

  //limpia el formulario
  reset_form(titulo: string, descripcion: string, img: string, precio: string, tipo: number, cantidad: any,
    action: number, est: number, id_to_edit: number) {

    this.estoy_edit = action;
    this.product_id = id_to_edit;
    this.file = null;
    this.strImage = img
    this.product_form = this.formBuilder.group({
      titulo: [titulo, Validators.required],
      descripcion: [descripcion, Validators.required],
      precio: [precio, Validators.required],
      productos_tipos: [tipo, Validators.required],
      img: [img, Validators.required],
      estado: [est],
      fecha: [RoutesController.getInstance().get_date()],
      cantidad: [cantidad, Validators.required],
    });
  }

  get_asignation_product_event(producto: number, buscar: number, est: number): any {
    return {
      productos_id: producto,
      buscar_id: buscar,
      secuencias_id: this.secuencia_ant,
      estado: est,
      fecha: RoutesController.getInstance().get_date()
    }
  }
}
