import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from 'src/app/controller/alert.controller';
import { EventService } from 'src/app/services/event.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-eventos-asignados',
  templateUrl: './eventos-asignados.component.html'
})
export class EventosAsignadosComponent implements OnInit {

  //VARIABLES
  user_event_list: any[] = [];    // GUARDA LOS EVENTOS DONDE EL USAURIO ESTA ASIGNADO
  searchText:any    //variable usada para buscar los eventos
  constructor(
    private router: Router,
    
    private user_service: UserService,
    private event_service: EventService) {

    this.initialize();
  }

  ngOnInit(): void {
  }

  initialize(){
    this.user_service.get_all_user_asig().subscribe(
      (data: any[]) => {
        data.filter(p=> {

          if (p.usuarios_id == Number(localStorage.getItem('id')) && p.estado == 1) {

            this.event_service.get_event_by_id(p.buscar_id).subscribe(
              (data: any) => {
                if (data.length != 0) {
                  this.user_event_list.push(data)
                }
            });
          }
        })

        setTimeout(() => {
          if (this.user_event_list.length == 0) {
            AlertController.getInstance().show_swall('No hay datos disponibles','No se ha asignado ningún evento','warning')
          }  
        }, 2000)
    });


    
  }

  get_id_event(id_evento:number){ // obtiene el id del evento que se quiere visualizar
    localStorage.setItem('permitido', '1')
    this.router.navigate(['evento/detalle/', id_evento])
  }

}
