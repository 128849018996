
<div class="row">
    <div class="col-12 m-t-30">
        
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Lista de Usuarios</h4>
                <p class="card-text">Usuarios asignados a este evento, las opciones disponibles incluyen agregar nuevos usuarios, asignar usuarios existentes y eliminar la asignación</p>
                
                <div class="btn-group" role="group" aria-label="Basic example">
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="input-group">
                            <span class="input-group-btn">
                                <a type="button" class="btn btn-info" [routerLink]="['/evento/multiusuario/asignar-usuario/' + id_anterior + '/sec/' + secuencia_ant]" routerLinkActive="router-link-active"  ><i class="icon-user-following"></i> Asignar</a>
                                <button type="button" class="btn btn-warning" data-toggle="modal" (click)="reset_form('','','','','','', '', 1);"
                                data-target="#modal_user"><i class="icon-user-follow"></i> Nuevo</button>
                            </span>
                            <input type="text" class="form-control" [(ngModel)]="searchText" placeholder="Buscar Usuario">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="row">
    <div class="col-12 m-t-30">
        <div class="card-columns el-element-overlay">
            <div *ngFor="let user of user_list | filter:searchText">
                <div class="card">
                    <div class="el-card-item">
                        <div class="el-card-avatar el-overlay-1">
                            <a class="image-popup-vertical-fit"> <img src="{{user.img}}" alt="user" /> </a>
                            <div class="el-overlay">
                                <ul class="el-info">
                                    <li><a class="btn default btn-outline" (click)="delete_user(user.id)"><i class="icon-trash"></i> Eliminar asignación</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="el-card-content">
                            
                            <h4 class="box-title">{{ user.nombres }} {{ user.apellidos }}</h4> <small>Email: {{ user.email }}</small>
                            <br> 
                            Tel: {{ user.numero }}
                            <hr>
                            <div class="btn-group" role="group" aria-label="Basic example">
                                <button class="btn btn-warning" *ngIf="user.secuencias_egr_id == 2" (click)="get_ruta(user.id, user.secuencias_egr_id)"><i class="ti-arrow-circle-right"></i> Ir a
                                    Categorías</button>
                                <button class="btn btn-warning" *ngIf="user.secuencias_egr_id == 3" (click)="get_ruta(user.id, user.secuencias_egr_id)"><i class="ti-arrow-circle-right"></i> Ir a
                                    Productos</button>
                            </div>                            
                    </div>
                </div>
            </div>

        </div>

    </div>
</div>




<div class="row">
    <br *ngFor="let i of [].constructor(10)">
</div>






<!-- ============================================================== -->
<!--                  MODAL RESPONSIVE AGREGAR                      -->
<!-- ============================================================== -->
<div id="modal_user" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"
    style="display: none;">
    <div *ngIf="user_form">
        <form class="modal-dialog modal-lg" [formGroup]="user_form">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="modal_areaLabel1">Formulario de Área</h4>
                    <button type="button" class="close" (click)="reset_form('','','','','','', '', 1);"
                        data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-5">
                            <div class="row el-element-overlay" style="margin-bottom: 0px !important;">
                                <div class="col-md-2">
                                </div>
                                <div class="col-md-8">
                                    <div class="card" style="margin-bottom: 0px !important;">
                                        <div class="el-card-item" style="margin-bottom: 0px !important;">
                                            <div class="el-card-avatar el-overlay-1"
                                                style="margin-bottom: 0px !important;">
                                                <img *ngIf="!file"
                                                    src="https://res.cloudinary.com/dksstmpfu/image/upload/v1609783937/test/categoria/sz78pbe1n76bqt58mgjm.jpg"
                                                    alt="Usuario" />
                                                <img *ngIf="file" src="{{strImage}}" alt="Usuario" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                </div>
                            </div>
                            <div class="form-group text-center">
                                <label>Foto del usuario (opcional)</label>
                                <div class="fileupload btn btn-danger btn-rounded waves-effect waves-light"><span><i
                                    class="fa fa-camera"></i> Seleccionar Imagen</span>
                                    <input type="file" accept="image/*"  (change)="seleccionImage($event)"  class="upload"> 
                                </div>

                            </div>
                        </div>
                        <div class="col-lg-7">
                            <div class="form-group">
                        
                                <div class="row">
                                    <div class="col-md-6">
                                        <label for="user_name" class="control-label">Nombres:</label>
                                        <input type="text" formControlName="nombres" 
                                        maxlength="25"
                                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                        placeholder="Máximo 25 caracteres"
                                        onkeypress="return (event.charCode != 34 && event.charCode != 39) "

                                        class="form-control" id="user_name1">
                                        <div *ngIf="f.nombres.invalid && (f.nombres.dirty || f.nombres.touched)">
                                            <div *ngIf="f.nombres.errors.required" style="color:red">Los nombres son requeridos</div> 
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label for="user_apellido" class="control-label">Apellidos:</label>
                                        <input type="text" formControlName="apellidos"
                                            maxlength="25"
                                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                        placeholder="Máximo 25 caracteres"
                                        onkeypress="return (event.charCode != 34 && event.charCode != 39) "

                                        class="form-control" id="user_apellido">
                                        <div *ngIf="f.apellidos.invalid && (f.apellidos.dirty || f.apellidos.touched)">
                                            <div *ngIf="f.apellidos.errors.required" style="color:red">Los apellidos son requeridos</div> 
                                        </div>
                                    </div>
                                </div>
                            </div>
        
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-6">
                                        <label for="user_email" class="control-label">Correo Electrónico:</label>
                                        <input class="form-control" formControlName="email" type="email" id="user_email">
                                        <div *ngIf="f.email.invalid && (f.email.dirty || f.email.touched)">
                                            <div *ngIf="f.email.errors.required" style="color:red">El correo son requeridos</div> 
                                        </div>
                                    </div>
                                    <div class="col-md-6" >
                                        <label for="user_pass" class="control-label">Contraseña:</label>
                                        <input type="password" class="form-control" formControlName="pass" id="user_pass">
                                        <div *ngIf="f.pass.invalid && (f.pass.dirty || f.pass.touched)">
                                            <div *ngIf="f.pass.errors.required" style="color:red">La contraseña es requerida</div> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-6">
                                        <label for="user_numero" class="control-label">Número de teléfono:</label>
                                        <input type="number" min="0" step="1" class="form-control" formControlName="numero" id="user_numero">
                                        <div *ngIf="f.numero.invalid && (f.numero.dirty || f.numero.touched)">
                                            <div *ngIf="f.numero.errors.required" style="color:red">El número de teléfono es requerido</div> 
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="fecha_nac" class="control-label">Fecha Nacimiento:</label>
                                            <input type="date" class="form-control" formControlName="fecha_nac" id="user_fecha_nac">
                                            <div *ngIf="f.fecha_nac.invalid && (f.fecha_nac.dirty || f.fecha_nac.touched)">
                                                <div *ngIf="f.fecha_nac.errors.required" style="color:red">La fecha de nacimiento es requerida</div> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div class="form-group">
                                <label for="user_sec" class="control-label">Secuencia:</label>
                                <select class="form-control"  id="user_sec" formControlName="secuencias_egr_id">
                                    <option selected disabled>Select</option>
                                    <option *ngFor="let sec of opciones_ruta[0]" value="{{sec.value}}"> {{ sec.option }}</option>
                                </select>
                                <div *ngIf="f.secuencias_egr_id.invalid && (f.secuencias_egr_id.dirty || f.secuencias_egr_id.touched)">
                                    <div *ngIf="f.secuencias_egr_id.errors.required" style="color:red">La secuencia es requerida</div> 
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="estado" class="control-label">Estado:</label>
                                <select class="form-control"  id="estado" formControlName="estado">
                                    <option selected disabled>Select</option>
                                    <option value="1">Habilitado</option>
                                    <option value="2">Deshabilitado</option>
                                </select>
                                <div *ngIf="f.estado.invalid && (f.estado.dirty || f.estado.touched)">
                                    <div *ngIf="f.estado.errors.required" style="color:red">El estado es requerido</div> 
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="modal-footer">

                    <div class="btn btn-group ">
                        <button (click)="reset_form('','','','','','', '', 1)" class="btn btn-outline-secondary waves-effect waves-light" type="button" data-dismiss="modal"><span class="btn-label"><i class="ti-close"></i></span>Cancelar</button>
                        <button (click)="uploadImage()" [disabled]="!file" class="btn btn-info waves-effect waves-light" type="button"><span class="btn-label"><i class="ti-gallery"></i></span>Subir Foto</button>
                        <button (click)="save_user()" [disabled]="!user_form.valid || subiendo_imagen "   class="btn btn-warning waves-effect waves-light" type="button"><span class="btn-label"><i class="ti-save"></i></span>Guardar</button>
                    </div>


                </div>
            </div>
        </form>
    </div>
</div>
<!-- ============================================================== -->
<!--                  MODAL RESPONSIVE AGREGAR                      -->
<!-- ============================================================== -->



