import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/services/auth.service';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AlertController } from 'src/app/controller/alert.controller';
import { UserService } from 'src/app/services/user.service';

import * as firebase from 'firebase/app';
import 'firebase/auth';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  //VARIABLES
  loginForm: any;
  error: boolean = false   // sirve para mostrar el texto en la vista en caso de que haya error (ngif)
  mensaje: string = ""    //sirve para mostrar el error de autenticacion
  constructor(private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private userService: UserService,
    private router: Router) { }

  ngOnInit(): void {
    // Inicializa el formulario
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  get f() { // Retorna los controles del formulario
    // que contiene la informacion para validar 
    return this.loginForm.controls;
  }

  login_with_email() {

    this.authService.login(this.f.email.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        (data: any) => {

          AlertController.getInstance().show_swall('Bienvenido', 'Acaba de iniciar sesión', 'success')
          //VISTA CLIENTE
          if (data.tipo_id == 2) {

            this.router.navigate(['/eventos-generales']);
            //VISTA ADMIN
          } else if (data.tipo_id == 1) {
            this.router.navigate(['/home']);
          }
        },
        (err: any) => {
          this.error = true
          this.mensaje = err.error.error
        });
  }

  login_with_google = async (): Promise<any> => {

    const { user } = await firebase.default.auth().signInWithPopup(new firebase.default.auth.GoogleAuthProvider())
    this.verify_user(user)
  }


  verify_user = async (user: any): Promise<any> => {
  
    localStorage.clear();
    await this.userService.get_all().subscribe(
      (data: any[]) => {
        data.some((u: any) => {
          if (u.email == user?.email && u.tocken == user?.uid) {
            this.authService.save_data(u, u.id, 2);
          }
        });
      })

    setTimeout(() => {
      if (localStorage.getItem('currentUser')) {
        AlertController.getInstance().show_swall('Bienvenido', 'Acaba de iniciar sesión', 'success')
        this.router.navigate(['/eventos-generales'], { queryParams: { 'refresh': 1 } })

      } else {
        AlertController.getInstance().show_swall('Error', "Usted no esta registrado, utilize la opción 'Crear con Google' para crear su cuenta", 'error');
      }
    }, 2000);
  }
}
