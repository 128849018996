import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/services/auth.service';
import { AlertController } from '../../controller/alert.controller';

@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.css']
})
export class PasswordRecoveryComponent implements OnInit {

  //VARIABLES 
  recovery_form:    any;
  submitted:        boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthenticationService
  ) {
   }

  ngOnInit(): void {
    // Inicializa el formulario
    this.recovery_form = this.formBuilder.group({
      email: ['', Validators.required]
    });
  }

  get f() { // Retorna los controles del formulario
    // que contiene la informacion para validar 
    return this.recovery_form.controls;
  }


  onSubmit() {
    this.submitted = true;
    // Se detiene aqui si el forumlario es invalido
    if (this.recovery_form.invalid) {
      this.submitted = false;
      return;
    }

    this.authService.recovery_password(this.recovery_form.value).subscribe(
      (data: any) => {
        AlertController.getInstance().show_swall('Contraseña Recuperada', data.message, 'success')
        this.recovery_form.reset();
      }, 
      (err: any) => {
        AlertController.getInstance().show_error();
      }
    )
    this.submitted = false;
  }

}
