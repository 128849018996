import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-comprar-app',
  templateUrl: './comprar-app.component.html'
})
export class ComprarAppComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }


  log_in(){
    if (localStorage.getItem('currentUser')) {
      this.router.navigate(['/user/dashboard/home'])      
    } else {
      this.router.navigate(['/login'])
    }
  }

}
