<div class="card" *ngIf="!estoy_editando && !mostrar_form">
    <div class="card-body">
        <div class="row">
            <div class="col-md-2 offset-md-3"></div>
            <div class="col-md-8 offset-md-3">

                <!-- form card cc payment -->
                <div class="card card-outline-secondary">
                    <div class="card-body" *ngIf="pago_form">
                        <h3 class="text-center">Pago del Evento</h3>

                        <form [formGroup]="pago_form" role="form" data-parsley-validate="" novalidate="false">
                            <div class="form-body">

                                <div class="form-group">
                                    <label for="cc_name">Nombre (en la tarjeta)</label>
                                    <input formControlName="nombre" type="text" class="form-control" id="cc_name">
                                    <div *ngIf="g.nombre.invalid && (g.nombre.dirty || g.nombre.touched)">
                                        <div *ngIf="g.nombre.errors.required" style="color:red">El nombre es requerido
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Número de la tarjeta</label>
                                    <input type="number" formControlName="tarjeta" maxlength="16"
                                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                        class="form-control">
                                    <div *ngIf="g.tarjeta.invalid && (g.tarjeta.dirty || g.tarjeta.touched)">
                                        <div *ngIf="g.tarjeta.errors.required" style="color:red">La tarjeta es requerida
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-12">Fecha de expiración</label>
                                    <div class="col-md-4">
                                        <select formControlName="mes" class="form-control" name="cc_exp_mo" size="0">
                                            <option value="01">01</option>
                                            <option value="02">02</option>
                                            <option value="03">03</option>
                                            <option value="04">04</option>
                                            <option value="05">05</option>
                                            <option value="06">06</option>
                                            <option value="07">07</option>
                                            <option value="08">08</option>
                                            <option value="09">09</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                        </select>
                                        <div *ngIf="g.mes.invalid && (g.mes.dirty || g.mes.touched)">
                                            <div *ngIf="g.mes.errors.required" style="color:red">El mes de expiración es
                                                requerido</div>
                                        </div>

                                    </div>
                                    <div class="col-md-4">
                                        <select formControlName="year" class="form-control" name="cc_exp_yr" size="0">
                                            <option value="2021">2021</option>
                                            <option value="2022">2022</option>
                                            <option value="2023">2023</option>
                                            <option value="2024">2024</option>
                                            <option value="2025">2025</option>
                                            <option value="2026">2026</option>
                                            <option value="2027">2027</option>
                                            <option value="2028">2028</option>
                                            <option value="2029">2029</option>
                                            <option value="2030">2030</option>
                                        </select>
                                        <div *ngIf="g.year.invalid && (g.year.dirty || g.year.touched)">
                                            <div *ngIf="g.year.errors.required" style="color:red">El año de expiración
                                                es requerido</div>
                                        </div>

                                    </div>
                                    <div class="col-md-4">
                                        <input type="number" formControlName="cvv" min="0" class="form-control"
                                            maxlength="3"
                                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                            placeholder="CVV">
                                        <div *ngIf="g.cvv.invalid && (g.cvv.dirty || g.cvv.touched)">
                                            <div *ngIf="g.cvv.errors.required" style="color:red">El cvc es requerido
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>

                            <div class="form-actions">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <button (click)="reset_form_pago()"
                                                class="btn btn-default btn-lg btn-block">Cancelar</button>
                                        </div>
                                        <div class="col-md-6">
                                            <button [disabled]="pago_form.invalid" (click)="validate_card()"
                                                class="btn btn-primary btn-lg btn-block">Verificar</button>

                                        </div>
                                    </div>

                                </div>

                            </div>
                        </form>
                    </div>
                </div>
                <!-- /form card cc payment -->
            </div>
            <div class="col-md-2 offset-md-3"></div>

        </div>
    </div>
</div>

<br>
<br>



<!-- ============================================================== -->
<!-- Start Page Content -->
<!-- ============================================================== -->
<div class="row" >
    <div class="col-lg-12">
        <div class="card">
            <div class="card-header bg-warning">
                <h4 class="m-b-0 text-white">Formulario de Evento</h4>
            </div>
            <div class="card-body" *ngIf="evento_form">
                <form [formGroup]="evento_form">
                    <div class="form-body">
                        <h3 class="card-title">{{ mensaje }}</h3>
                        <hr>
                        <div class="row">
                            <div class="col-lg-3">

                            </div>
                            <div class="col-lg-6">
                                <div class="row el-element-overlay" style="margin-bottom: 0px !important;">
                                    <div class="col-md-2">
                                    </div>
                                    <div class="col-md-8">
                                        <div class="card" style="margin-bottom: 0px !important;">
                                            <div class="el-card-item" style="margin-bottom: 0px !important;">
                                                <div *ngIf="!estoy_editando" class="el-card-avatar el-overlay-1"
                                                    style="margin-bottom: 0px !important;">
                                                    <img *ngIf="!file"
                                                        src="https://res.cloudinary.com/dksstmpfu/image/upload/v1609965264/test/categoria/sldl1o85ruzeockszmth.jpg"
                                                        alt="area" />
                                                    <img *ngIf="file" src="{{strImage}}" alt="area" />
                                                </div>
                                                <div *ngIf="estoy_editando" class="el-card-avatar el-overlay-1"
                                                    style="margin-bottom: 0px !important;">
                                                    <img src="{{strImage}}" alt="area" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                    </div>
                                </div>
                                <div class="form-group text-center">
                                    <label>Foto del Evento (opcional)</label>
                                    <br>
                                    <div class="fileupload btn btn-danger btn-rounded waves-effect waves-light"><span><i
                                                class="fa fa-camera"></i> Seleccionar Imagen</span>
                                        <input type="file" accept="image/*" (change)="seleccionImage($event)"
                                            class="upload">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3">

                            </div>
                        </div>

                        <hr>
                        <div class="row p-t-20">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="control-label">Título</label>
                                    <input type="text" formControlName="titulo" id="firstName" class="form-control"
                                        placeholder="">
                                    <div *ngIf="f.titulo.invalid && (f.titulo.dirty || f.titulo.touched)">
                                        <div *ngIf="f.titulo.errors.required" style="color:red">El título es requerido
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!--/span-->
                        </div>

                        <!--/row-->
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="control-label">Descripción</label>
                                    <textarea formControlName="descripcion" 
                                    maxlength="150"
                                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                    placeholder="Máximo 150 caracteres"
                                    onkeypress="return (event.charCode != 34 && event.charCode != 39) "

                                    class="form-control" cols="30"
                                        rows="3"></textarea>
                                    <div
                                        *ngIf="f.descripcion.invalid && (f.descripcion.dirty || f.descripcion.touched)">
                                        <div *ngIf="f.descripcion.errors.required" style="color:red">La descripción es
                                            requerida</div>
                                    </div>
                                </div>
                            </div>
                            <!--/span-->
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label">Fecha de Inicio</label>
                                    <input (change)="get_fecha_inicio($event)" on type="date" class="form-control"
                                        formControlName="fech_ini" placeholder="dd/mm/yyyy">
                                    <div *ngIf="f.fech_ini.invalid && (f.fech_ini.dirty || f.fech_ini.touched)">
                                        <div *ngIf="f.fech_ini.errors.required" style="color:red">La fecha deinicio es
                                            requerida</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label">Fecha de Finalización</label>
                                    <input min="{{fecha_inicio}}" type="date" class="form-control"
                                        formControlName="fech_fin" placeholder="dd/mm/yyyy">
                                    <div *ngIf="f.fech_fin.invalid && (f.fech_fin.dirty || f.fech_fin.touched)">
                                        <div *ngIf="f.fech_fin.errors.required" style="color:red">La fecha finalización
                                            es requerida</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--/row-->
                        <div class="row" *ngIf="!estoy_editando">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="control-label">Tipo del Evento</label>
                                    <select formControlName="eventos_tipos_id" class="form-control"
                                        (change)="select_tipo_evento($event)" style="width: 100%;">
                                        <option disabled selected> Seleccione uno </option>
                                        <option value="1"> Sin Usuario </option>
                                        <option value="2"> Multiusuario </option>
                                    </select>
                                    <div
                                        *ngIf="f.eventos_tipos_id.invalid && (f.eventos_tipos_id.dirty || f.eventos_tipos_id.touched)">
                                        <div *ngIf="f.eventos_tipos_id.errors.required" style="color:red">El tipo del
                                            evento es requerido</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row" *ngIf="tipo_evento_seleccionado != 0">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="control-label">Secuencia</label>
                                    <select formControlName="secuencias_id" class="form-control" style="width: 100%;"
                                        (change)="get_secuencia($event)">
                                        <option value="0" selected> Seleccione uno</option>
                                        <option value="1">Área</option>
                                        <option value="2">Categoría</option>
                                        <option value="3" *ngIf="tipo_evento_seleccionado == 1">
                                            Producto</option>
                                        <option value="4" *ngIf="tipo_evento_seleccionado == 2">
                                            Usuario</option>
                                    </select>

                                    <div
                                        *ngIf="f.secuencias_id.invalid && (f.secuencias_id.dirty || f.secuencias_id.touched)">
                                        <div *ngIf="f.secuencias_id.errors.required" style="color:red">La secuencia del
                                            evento es requerida</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="control-label">Estado</label>
                                    <select class="form-control" formControlName="estado">
                                        <option value="1">Activado</option>
                                        <option value="2">Desactivado</option>
                                    </select>

                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="form-group text-center">
                        <div class="btn btn-group">
                            <button [routerLink]="['/mis-eventos']" class="btn btn-secondary waves-effect waves-light"
                                type="button"><span class="btn-label"><i class="ti-close"></i></span>Cancelar</button>
                            <button [disabled]="evento_form.invalid || subiendo_imagen" (click)="create_event()"
                                class="btn btn-warning waves-effect waves-light" type="button"><span
                                    class="btn-label"><i class="ti-check"></i></span>Guardar Evento</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- ============================================================== -->
<!-- End PAge Content -->
<!-- ============================================================== -->