import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class TrasladosService {

    //VARIABLES
    URI = 'https://foxylabs-eventos.herokuapp.com/api/eventos_traslados_dinero';
   
    
    constructor(private http: HttpClient) {

    }

    get_all(){
        return this.http.get<any>(`${this.URI}/get_all`).pipe(map(data => {
            return data;
        }));    
    }

   
    post(data:any) {
        
        var headers = new HttpHeaders({'Content-Type': 'application/json' });        
        return this.http.post(`${this.URI}/add`, data, { headers }).pipe(map(res => {
            return res;
        }));
    }
}