import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ProductService {

    //VARIABLES
    private URI_PRODUCT = 'https://foxylabs-eventos.herokuapp.com/api/productos';
    private URI_PRODUCT_EVENT = 'https://foxylabs-eventos.herokuapp.com/api/productos_eventos';
    private URI_PRODUCT_TIPO = 'https://foxylabs-eventos.herokuapp.com/api/productos_tipos';

    constructor(private http: HttpClient) {

    }

    //HTTP OPTIONS
    private httpOptions = {

        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    //HANDLE ERROR
    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('Un error ha ocurrido:', error.error.message);
        } else {
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: `, error.error);
        }
        return throwError(
            error);
    };



    //SECCION PRODUCTO

    product_get_all() {
        return this.http.get<any>(`${this.URI_PRODUCT}/get_all`).pipe(map(data => {
            return data;
        }));
    }

    product_get_by_id(id_product: number) {
        return this.http.get<any>(`${this.URI_PRODUCT}/get_${ id_product }`).pipe(map(data => {
            return data;
        }));
    }


    get_product_by_id_anterior(id_anterior:number, secuencia_ingreso: number){
        console.log()
        return this.http.get<any>(`https://foxylabs-eventos.herokuapp.com/api/secuencia/secuencia/3/secuencia_anterior/${ secuencia_ingreso }/buscar/${ id_anterior }`).pipe(map(data => {
            return data;
        }));    
    }

    //CREATE
    post(data: any) {

        return this.http.post(`${this.URI_PRODUCT}/add`, data, this.httpOptions)
            .pipe(map(
                (data) => {
                    return data;
                }, (err: any) => {
                    catchError(this.handleError)
                }));
    }
    //UPDATE
    put(data: any, id_product: number) {

        return this.http.put(`${this.URI_PRODUCT}/update_${id_product}`, data, this.httpOptions)
            .pipe(map(
                (data) => {
                    return data;
                }, (err: any) => {
                    catchError(this.handleError)
                }));
    }

    delete(id: number) {
        return this.http.delete(`${this.URI_PRODUCT}/delete_${id}`, this.httpOptions).pipe(map(
            (data) => {
            return data;
            },
            (err: any) => {
                catchError(this.handleError)
            }));
    }



    // SECCION PRODUCTO-EVENTO



    product_event_post(data: any) {
        return this.http.post(`${this.URI_PRODUCT_EVENT}/add`, data, this.httpOptions).pipe(map(
            (data) => {
                return data;
            }, (err: any) => {
                catchError(this.handleError)
            }));
    }

    product_event_put(data: any, id_product_event: number) {
        return this.http.post(`${this.URI_PRODUCT_EVENT}/update_${ id_product_event }`, data, this.httpOptions).pipe(map(
            (data) => {
                return data;
            }, (err: any) => {
                catchError(this.handleError)
            }));
    }

    //SECCION PRODUCTO-TIPO
    
    product_tipo_get_all() {
        return this.http.get<any>(`${this.URI_PRODUCT_TIPO}/get_all`).pipe(map(data => {
            return data;
        }));
    }

    //create
    post_product_tipo(data: any) {
        return this.http.post(`${this.URI_PRODUCT_TIPO}/add`, data, this.httpOptions).pipe(map(
            (data) => {
                return data;
            }, (err: any) => {
                catchError(this.handleError)
            }));
    }
    //delete
    delete_product_tipo(id: number){
        return this.http.delete(`${this.URI_PRODUCT_TIPO}/delete_${id}`, this.httpOptions).pipe(map(
            (data) => {
            return data;
            },
            (err: any) => {
                catchError(this.handleError)
            }));
    }

    //update
    put_product_tipo(data: any, id_tipo: number) {
        return this.http.put(`${this.URI_PRODUCT_TIPO}/update_${id_tipo}`, data, this.httpOptions)
            .pipe(map(
                (data) => {
                    return data;
                }, (err: any) => {
                    catchError(this.handleError)
                }));
    }




}