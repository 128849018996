import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './session/login/login.component';
import { SigninComponent } from './session/signin/signin.component';
import { PasswordRecoveryComponent } from './session/password-recovery/password-recovery.component';
import { PageComponent } from './page/page.component';
import { NopagefoundComponent } from './shared/nopagefound/nopagefound.component';
import { PageModule } from './page/page.module';




//      SERVICES
import { AuthenticationService } from './services/auth.service'
import { AuthGuardService } from './services/auth.guard.service';
import { UserService } from './services/user.service';
import { EventService } from './services/event.service';
import { SecuenceService } from './services/secuence.service';
import { AreaService } from './services/area.service';
import { CategoriaService } from './services/categoria.service';
import { ProductService } from './services/product.service';
import { UploadFileService } from './services/upload-file.service';
import { CompraService } from './services/compra.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { TrasladosService } from './services/traslados.service';
import { ProfileComponent } from './shared/profile/profile.component';

// FIREBASE
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';

const firebaseConfig = {
  apiKey: "AIzaSyAN2md9l2r4Yz2nrEIDMNjOQwYFcSmnrmE",
  authDomain: "eventos-71fa1.firebaseapp.com",
  projectId: "eventos-71fa1",
  storageBucket: "eventos-71fa1.appspot.com",
  messagingSenderId: "1052139661203",
  appId: "1:1052139661203:web:488b6fde16af14265f6a66",
  measurementId: "G-06Y1VJ5ZV8"
};


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SigninComponent,
    PasswordRecoveryComponent,
    PageComponent,
    NopagefoundComponent,
    NavbarComponent,
    ProfileComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule, 
    PageModule,
    ReactiveFormsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFirestoreModule, // firestore
    AngularFireAuthModule, // auth
    AngularFireStorageModule // storage
  ],
  providers: [
    AuthenticationService,
    AuthGuardService,
    UserService,
    EventService,
    SecuenceService,
    AreaService,
    CategoriaService,
    ProductService,
    UploadFileService,
    CompraService,
    TrasladosService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
