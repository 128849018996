import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError} from 'rxjs/operators';


@Injectable()
export class CategoriaService {

    //VARIABLES
    private URI_CATEGORIA = 'https://foxylabs-eventos.herokuapp.com/api/categorias';
    private URI_CATEGORIA_EVENTO = 'https://foxylabs-eventos.herokuapp.com/api/secuencia/secuencia/2'
    
    //HTTP OPTIONS
     private httpOptions = {

        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    //HANDLE ERROR
    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('Un error ha ocurrido:', error.error.message);
        } else {
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: `, error.error);
        }
        return throwError(
            error);
    };

    constructor(private http: HttpClient) {

    }

    get_categoria_by_id(id_categoria:number){
        return this.http.get<any>(`${this.URI_CATEGORIA}/get_${id_categoria}`).pipe(map(data => {
            return data;
        }));    
    }
    get_categoria_by_id_anterior(id_anterior:number, secuencia_ingreso: number){
        return this.http.get<any>(`${ this.URI_CATEGORIA_EVENTO }/secuencia_anterior/${ secuencia_ingreso }/buscar/${ id_anterior }`).pipe(map(data => {
            return data;
        }));    
    }

    //CREATE
    post(data:any) {
        return this.http.post(`${this.URI_CATEGORIA}/add`, data, this.httpOptions)
        .pipe(map(
            (data) => {
                return data;
            }, 
            (err: any)=> {
                catchError(this.handleError)
        }));
    }

    //UPDATE
    put(data:any, id_category: number) {
        
        return this.http.put(`${this.URI_CATEGORIA}/update_${id_category}`, data, this.httpOptions)
        .pipe(map(
            (data) => {
                return data;
            }, 
            (err: any)=> {
                catchError(this.handleError)
        }));
    }

    //DELETE
    delete(id: number) {
        return this.http.delete(`${this.URI_CATEGORIA}/delete_${id}`, this.httpOptions).pipe(map(data => {
            return data;
        }));
    }
}