<div class="fix-header card-no-border fix-sidebar">
    
    <div id="main-wrapper">
    
        <app-navbar></app-navbar>        
    
        <br>
        <br>
        <br>        
        <div class="page-wrapper">
            <!-- ============================================================== -->
            <!-- Container fluid  -->
            <!-- ============================================================== -->
            <div class="container-fluid">
                <div class="container">
                    <router-outlet></router-outlet>
                </div>
            </div>
           
            <!-- ============================================================== -->
            <!-- footer -->
            <!-- ============================================================== -->
            <footer class="footer"> © 2020 FoxyLabs</footer>
        </div>
        <!-- ============================================================== -->
        <!-- End Page wrapper  -->
        <!-- ============================================================== -->
    </div>

</div>




