<br>
<br>

<div class="error-box">
    <div class="error-body text-center">
        <img src="../../../assets/images/background/error-bg.jpg" width="50%" height="50%" alt="">
        <br>
        <br>
        <h1 class="error-title text-warning">404</h1>
        <h3 class="text-uppercase error-subtitle">PAGINA NO ENCONTRADA !</h3>
        <p class="text-muted m-t-30 m-b-30">PARECE ESTAR TRATANDO DE ENCONTRAR SU CAMINO A CASA</p>
        <a [routerLink]="['/login']"  class="btn btn-warning btn-rounded waves-effect waves-light m-b-40">Volver al inicio</a> 
    </div>
</div>
