

<header class="topbar">
    
    <nav class="navbar top-navbar navbar-light text-light py-3   navbar-expand-md">
        <div class="container-nav topheader">
            <div class="d-flex justify-content-center">
                <div >
                    <img src="../../../../../../assets/images/logo.png" width="auto" height="70px" alt="">
                </div>
            </div>
        </div>
    </nav >
    <div class="bottom sticky-top shadow-sm" style="background-color: #242a33;">
        <nav class="container-nav" >
            <ul  class="nav nav-bottom nav-fill nav-list">
                <li [hidden]="!es_admin" class="nav-item">
                    <a class="nav-link text-light active" [routerLink]="['/home']" routerLinkActive="router-link-active" ><i class="ti-home"></i><span class="d-none d-sm-inline-block d-md-inline-block">Home</span></a>
                </li>
                <li [hidden]="es_admin" class="nav-item">
                    <a class="nav-link text-light active" [routerLink]="['/eventos-generales']" routerLinkActive="router-link-active" ><i class="ti-calendar"></i> <span class="d-none d-sm-inline-block d-md-inline-block">Eventos Generales</span> </a>                
                </li>
                <li [hidden]="!es_admin" class="nav-item">
                    <a class="nav-link text-light active" [routerLink]="['/listado-eventos']" routerLinkActive="router-link-active" ><i class="ti-calendar"></i> <span class="d-none d-sm-inline-block d-md-inline-block">Eventos Generales</span> </a>                
                </li>
                
                <li [hidden]="es_admin" class="nav-item dropdown">
                    <a class="nav-link text-light dropdown-toggle" href="#" id="dropdownEvent" data-toggle="dropdown"  aria-haspopup="true" aria-expanded="false">
                      <i class="icon-calender"></i> <span class="d-none d-sm-inline-block d-md-inline-block"> Mis Eventos</span>
                    </a>
                  
                    <div class="dropdown-menu" aria-labelledby="dropdownEvent">
                      <a class="dropdown-item" [routerLink]="['/mis-eventos']" routerLinkActive="router-link-active"><i class="ti-book"></i> Mis Eventos</a>
                      <a class="dropdown-item" [routerLink]="['/evento/create']" routerLinkActive="router-link-active"><i class="icon-note"></i> Nuevo Evento</a>
                      <a class="dropdown-item" [routerLink]="['/eventos-asignados']" routerLinkActive="router-link-active"><i class="ti-agenda"></i> Eventos Asignados</a>
                    </div>
              </li>

                <li class="nav-item">
                    <a class="nav-link text-light" [routerLink]="['/envios']" routerLinkActive="router-link-active" ><i class="ti-gift"></i><span class="d-none d-sm-inline-block d-md-inline-block"> Envíos</span></a>
                
                </li>
                <li [hidden]="!es_admin" class="nav-item">
                    <a class="nav-link text-light " [routerLink]="['/tipo-productos']" routerLinkActive="router-link-active" ><i class="ti-tag"></i><span class="d-none d-sm-inline-block d-md-inline-block">Tipos de Producto</span></a>
                </li>
                <li [hidden]="!es_admin"> 
                    <a class="nav-link text-light" [routerLink]="['/lista-usuarios']" routerLinkActive="router-link-active" ><i class="ti ti-user"></i><span class="d-none d-sm-inline-block d-md-inline-block"> Usuarios</span></a>
                </li>
                
                <li class="nav-item dropdown">
                    <a class="nav-link text-light dropdown-toggle" href="#" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="ti ti-user"></i> <span class="d-none d-sm-inline-block d-md-inline-block"> Perfil</span>
                    </a>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <ul class="dropdown-user">
                            <li>
                                <div class="dw-user-box" *ngIf="user_logged != null">
                                    <div class="u-img"><img src="{{ user_logged.img ==''||user_logged.img ==null?'assets/images/default-user.jpg':user_logged.img}} " alt="user"></div>
                                    <div class="u-text">
                                        <h4>{{ user_logged.nombres }} {{ user_logged.apellidos }}</h4>
                                        <p class="text-muted"> {{ user_logged.email }} </p></div>
                                </div>
                            </li>
                            <li role="separator" class="divider"></li>
                            <li><a [routerLink]="['/mi-perfil']" routerLinkActive="router-link-active" ><i class="ti-user"></i> Mi Perfil</a></li>
                            <li><a [routerLink]="['/buyapp']" routerLinkActive="router-link-active" ><i class="ti-wallet"></i> Comprar App</a></li>
                            <li role="separator" class="divider"></li>
                            <li><a (click)="log_out()" [routerLink]="['/login']" routerLinkActive="router-link-active"  ><i class="fa fa-power-off"></i> Logout</a></li>
                        </ul>
                      </div>
                </li>
            </ul>
        </nav>
    </div>
</header>