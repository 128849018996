
<div class="row">
    <div class="col-12 m-t-30">
        
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Lista de Usuarios</h4>
                <p class="card-text">Listado Completo de usuarios dentro de la aplicación</p>
                <div class="btn-group" role="group" aria-label="Basic example">
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="input-group">
                            <span class="input-group-btn">
                                <button type="button" class="btn btn-info"><i class="icon-user-following"></i> Buscar</button>
                                
                            </span>
                            <input type="text" class="form-control" [(ngModel)]="searchText" placeholder="Nombre, apellido, etc">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="row">
    <div class="col-12 m-t-30">
        <div class="card-columns el-element-overlay">
            <div *ngFor="let user of user_list | filter:searchText">
                <div class="card">
                    <div class="el-card-item">
                        <div class="el-card-avatar el-overlay-1">
                            <a class="image-popup-vertical-fit"> <img src="{{user.img}}" alt="user" /> </a>
                            <div *ngIf="es_admin" class="el-overlay">
                                <ul class="el-info">
                                    <li><a class="btn default btn-outline"  (click)="delete_user(user.id)"><i class="icon-trash"></i> Eliminar Usuario</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="el-card-content">
                            <h4 class="box-title">{{ user.nombres }}</h4> <h4 class="box-tittle">{{ user.apellidos }}</h4> <small>{{ user.email }}</small>
                            <br> 
                            Tel: {{ user.numero }}
                            <br>
                            <div *ngIf="!es_admin"  class="row">
                                <div class="col-md-1">
                                    
                                </div>
                                <div class="col-md-10">
                                    <button class="btn btn-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="true" ><i class="ti-plus"></i> Asignar
                                    </button>
                                    <div class="dropdown-menu">
                                        <button class="dropdown-item" (click)="asignate_user(user.id, 2)">Usuario por Categoria</button>
                                        <button class="dropdown-item" (click)="asignate_user(user.id, 3)">Usuario por Producto</button>
                                    </div>        
                                </div> 
                                <div class="col-md-1">
                                    
                                </div>
                                
                            </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</div>

