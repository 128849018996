<div class="row">
    <div class="col-12">
        <div class="card">
            <!-- .left-right-aside-column-->
            <div class="event-page-aside">
                <div class="right-aside ">
                    <div class="right-page-header">
                        <div class="d-flex">
                            <div class="align-self-center">
                                <h4 class="card-title m-t-10">Eventos que me fueron asignados </h4>
                            </div>
                            <div class="ml-auto">
                                <input type="text" id="demo-input-search2" [(ngModel)]="searchText" placeholder="Buscar Evento"
                                    class="form-control"> </div>
                        </div>
                    </div>

                </div>
                <!-- /.left-right-aside-column-->
            </div>
        </div>
    </div>
</div>
<!-- ============================================================== -->
<!-- Start Page Content -->
<!-- ============================================================== -->
<div class="row">
    <div class="col-lg-4" *ngFor="let event of user_event_list | filter:searchText">
        <div class="card">
            <div class="card-body little-profile text-center">
                <div class="pro-img m-t-20"><img src="{{event.img}}" alt="event"></div>
                <h3 class="m-b-0">{{event.titulo}}</h3>
                <h6 class="text-muted">{{event.descripcion}}</h6>
                    <small *ngIf="event.eventos_tipos_id == 2"><span
                        class="label label-warning label-rounded">MultiUsuario</span></small>
                    <small *ngIf="event.eventos_tipos_id == 1"><span
                            class="label label-danger label-rounded">Sin Usuarios</span></small>
            </div>
            <div class="text-center bg-light">
                <div class="row">
                    <div class="col-6  p-20 b-r">
                        <h5 class="m-b-0 font-medium">{{event.fech_ini | date: 'short' }}</h5><small>Inicio</small></div>
                    <div class="col-6  p-20">
                        <h5 class="m-b-0 font-medium">{{event.fech_fin | date: 'short'}}</h5><small>Fin</small></div>
                </div>
            </div>
            <div class="card-body text-center">
                <div class="btn-group btn-group" role="group" aria-label="Basic example">
                    <button type="button" class="m-t-10 m-b-20 waves-effect waves-dark btn-sm btn-rounded btn btn-warning" (click)="get_id_event(event.id)" ><i class="fa fa-plus"></i> Ver Evento</button>
                </div>
            </div>
        </div>
    </div>
    
    <!--Little Profile widget-->
</div>

<div>
    <br *ngFor="let i of [].constructor(14)">
</div>

<!-- ============================================================== -->
<!-- End PAge Content -->
<!-- ============================================================== -->