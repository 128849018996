import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError} from 'rxjs/operators';

@Injectable()
export class EventService {

    //URL
    URI_EVENTO = 'https://foxylabs-eventos.herokuapp.com/api/eventos';
    URI_EVENTO_TIPOS = 'https://foxylabs-eventos.herokuapp.com/api/eventos_tipos' 
    URI_EVENTO_PRECIO = 'https://foxylabs-eventos.herokuapp.com/api/eventos_precio'
    //HTTP OPTIONS
    private httpOptions = {

        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
    };

    //HANDLE ERROR
    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
        console.error('Un error ha ocurrido:', error.error.message);
        } else {
        console.error(
        `Backend returned code ${error.status}, ` +
        `body was: `, error.error);
        }
        return throwError(
        error);
    };
    
    //CONSTRUCTOR
    constructor(private http: HttpClient) {

    }
    // EVENTOS
    get_all(){
        return this.http.get<any>(`${this.URI_EVENTO}/get_all`).pipe(map(data => {
            return data;
        }));
    }
 
    get_my_events(id_user: number){
        return this.http.get<any>(`${this.URI_EVENTO}/usuario_${ id_user }`).pipe(map(data => {
            return data;
        }));
    }

    get_event_by_id(id: number) {
        return this.http.get<any>(`${this.URI_EVENTO}/get_${id}`).pipe(map(data => {
            return data;
        }));
        
    }

    get_data_secuencia(id_elemento: number, secuencia_id: number, secuencia_anterior: number){
        return this.http.get<any>(`https://foxylabs-eventos.herokuapp.com/api/secuencia/secuencia/${ secuencia_id }/secuencia_anterior/${ secuencia_anterior }/buscar/${ id_elemento }`).pipe(map(data => {
            return data;
        })); 
    }

    /* DELETE */
    delete_by_id(id: number){
        return this.http.delete(`${this.URI_EVENTO}/delete_${id}`, this.httpOptions ).pipe(map(data => {
            return data;
        }));
    }

    /* UPDATE */
    public put(data:any, id_evento: number) {
        return this.http.put(`${this.URI_EVENTO}/update_${ id_evento }`, data,  this.httpOptions)
        .pipe(map(
            (data) => {
                return data;
            }, (err: any)=> {
                catchError(this.handleError)
        }));
    }

    //CREATE
    public post(data:any) {
        return this.http.post(`${this.URI_EVENTO}/add`, data,  this.httpOptions )
        .pipe(map(
            (data) => {
                return data;
            }, (err: any)=> {
                catchError(this.handleError)
        }));
    }


    //EVENTO TIPOS

    get_event_tipo_get_all() {
        return this.http.get<any>(`${this.URI_EVENTO_TIPOS}/get_all`).pipe(map(data => {
            return data;
        }));
        
    }

    get_event_tipo(id_tipo: number) {
        return this.http.get<any>(`${this.URI_EVENTO_TIPOS}/get_${id_tipo}`).pipe(map(data => {
            return data;
        }));
        
    }
    

    /* EVENTO - PRECIO */

    post_evento_precio(data: any){
        return this.http.post(`${this.URI_EVENTO_PRECIO}/add`, data,  this.httpOptions )
        .pipe(map(
            (data) => {
                return data;
            }, (err: any)=> {
                catchError(this.handleError)
        }));
    }
}