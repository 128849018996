import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController } from 'src/app/controller/alert.controller';
import { RoutesController } from 'src/app/controller/routes.controller';
import { ProductService } from 'src/app/services/product.service';

//JQUERY
declare var $: any;

@Component({
  selector: 'app-admin-tipo-producto',
  templateUrl: './admin-tipo-producto.component.html'
})
export class AdminTipoProductoComponent implements OnInit {
  //VARIABLES GLOBALES
  tipo_list:  any[]  = [];   // guarda la lista de tipos
  p:          number = 1;            //  sirve para hacer la paginacion
  searchText: any;         //  sirve para buscar
  saltos      = new Array(9) 
  //VARIABLES DE FORMULARIO
  tipo_form: any;
  submitted: boolean = false;
  loading:   boolean = false;
  estado:    number = 1;
  //VARIABLES PARA EDICION
  id_tipo:   number = 0;
  estoy_edit:number = 0;   //bandera que se usa para agregar o editar, 0 = agregar; 1 = editar

  constructor(
    private product_service: ProductService,
    private formBuilder: FormBuilder) {
      this.initialize()
     }

  ngOnInit(): void {
    this.reset_form('', '', 0, 1)
  }

  initialize(){
    this.estado = 1;
    this.product_service.product_tipo_get_all().subscribe(data => {
      this.tipo_list = data;

      if (this.tipo_list.length == 0) {
        AlertController.getInstance().show_swall('No hay datos disponibles', "No se encuentra información para mostrar, agregue tipos en 'Nuevo tipo'", 'warning')
      }
    })
  }


  reset_form(titu: string, desc: string, action: number, est: number){
    this.loading = this.submitted = false;
    this.estoy_edit = action;
    this.estado = est;
    // Inicializa el formulario
    this.tipo_form = this.formBuilder.group({
      titulo            : [ titu, Validators.required],
      descripcion       : [ desc, Validators.required],
      estado            : [ this.estado ],
      fecha             : [ RoutesController.getInstance().get_date() ]
    });
  }


  /*//////////////////////////////////
      SECCION DE INSERSION DE DATOS
  ///////////////////////////////*/

  //FUNCION DE VALIDACION
  get f() { // Retorna los controles del formulario
    // que contiene la informacion para validar 
    return this.tipo_form.controls;
  }

 

  save_tipo(){

    this.submitted = true;
    // Se detiene aqui si el forumlario es invalido
    if (this.tipo_form.invalid) {
      AlertController.getInstance().show_swall("Error", "Debe llenar todos los campos", "error")
      this.submitted = false;
      return;
    }
    this.loading = true; //bloque el boton de boton hasta enviar los datos

    this.tipo_form.get('estado').setValue(this.estado);

    if (this.estoy_edit == 0) {
      this.product_service.post_product_tipo(this.tipo_form.value).subscribe(
        (data) => {
          this.initialize()
          AlertController.getInstance().show_swall('Tipo de producto agregado','El tipo se agregó correctamente','success')
        },
        (err) => {
          AlertController.getInstance().show_error()
        }
      )
    } else {
      this.product_service.put_product_tipo(this.tipo_form.value, this.id_tipo).subscribe(
        (data) => {
          this.initialize()
          AlertController.getInstance().show_swall('Tipo de producto editado','El tipo se editó correctamente','success')
        },
        (err) => {
          AlertController.getInstance().show_error()
        }
      )
    }


    $('#modal_tipo').modal('hide');
  }


   /*//////////////////////////////////
      SECCION DE ELIMINACION DE DATOS
  ///////////////////////////////*/
  delete_tipo(id_tipo: number){
    this.product_service.delete_product_tipo(id_tipo).subscribe(
      (data) => {
        this.initialize();
        AlertController.getInstance().show_swall('Tipo eliminado', 'El tipo se eliminó correctamente','success')
      },
      (err) => {
        AlertController.getInstance().show_error()
      }
    )
  }


  get_tipo_to_edit(tipo: any){
    this.id_tipo = tipo.id;
    this.reset_form(tipo.titulo, tipo.descripcion, 1, tipo.estado);
  }
}
