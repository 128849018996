import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PAGES_ROUTES } from './page.routes';
// COMPONENTES DE USUARIO
import { EventosGeneralesComponent } from './eventos-generales/eventos-generales.component';
import { MisEventosComponent } from './mis-eventos/mis-eventos.component';
import { NuevoEventoComponent } from './nuevo-evento/nuevo-evento.component';
import { AreaEventoComponent } from './nuevo-evento/area-evento/area-evento.component';
import { CategoriaEventoComponent } from './nuevo-evento/categoria-evento/categoria-evento.component';
import { ProductoEventoComponent } from './nuevo-evento/producto-evento/producto-evento.component';
import { UsuarioEventoComponent } from './nuevo-evento/usuario-evento/usuario-evento.component';
import { ListaUsuariosComponent } from './lista-usuarios/lista-usuarios.component';
import { EventosAsignadosComponent } from './eventos-asignados/eventos-asignados.component';



// search module
import { Ng2SearchPipeModule } from 'ng2-search-filter';
//pagination module
import { NgxPaginationModule } from 'ngx-pagination';
import { EnviosComponent } from './envios/envios.component';
import { EnviosDetalleComponent } from './envios-detalle/envios-detalle.component';
import { EventoDetalleComponent } from './evento-detalle/evento-detalle.component';
import { ComprarAppComponent } from './comprar-app/comprar-app.component';
import { AdminHomeComponent } from './admin-home/admin-home.component';
import { AdminTipoProductoComponent } from './admin-tipo-producto/admin-tipo-producto.component';
import { AdminListadoEventosComponent } from './admin-listado-eventos/admin-listado-eventos.component';
import { ManagerEventosGeneralesComponent } from './manager-eventos-generales/manager-eventos-generales.component';
import { ManagerEventoDetalleComponent } from './manager-evento-detalle/manager-evento-detalle.component';


@NgModule({
  declarations: [
    EventosGeneralesComponent,
    MisEventosComponent,
    NuevoEventoComponent,
    AreaEventoComponent,
    CategoriaEventoComponent,
    ProductoEventoComponent,
    UsuarioEventoComponent,
    ListaUsuariosComponent,
    EventosAsignadosComponent,
    EnviosComponent,
    EnviosDetalleComponent,
    EventoDetalleComponent,
    ComprarAppComponent,
    AdminHomeComponent,
    AdminTipoProductoComponent,
    AdminListadoEventosComponent,
    ManagerEventosGeneralesComponent,
    ManagerEventoDetalleComponent
  ],
  exports: [
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    PAGES_ROUTES,
    Ng2SearchPipeModule,
    NgxPaginationModule
  ]
})
export class PageModule { }
