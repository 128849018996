



<div class="row">
    <div class="col-12">
        <div class="card">
            <!-- .left-right-aside-column-->
            <div class="event-page-aside">
                <div class="right-aside ">
                    <div class="right-page-header">
                        <div class="d-flex">
                            <div class="align-self-center">
                                <h4 class="card-title m-t-10">Mis Eventos </h4>
                            </div>
                            <div class="ml-auto">
                                <input type="text" id="demo-input-search2" [(ngModel)]="searchText" placeholder="Buscar Evento"
                                    class="form-control"> </div>
                        </div>
                    </div>

                </div>
                <!-- /.left-right-aside-column-->
            </div>
        </div>
    </div>
</div>
<!-- ============================================================== -->
<!-- Start Page Content -->
<!-- ============================================================== -->
<div class="row" >
    <div class="col-lg-4" *ngFor="let event of event_list | filter:searchText">
        <div class="card">
            <div class="card-body little-profile text-center">
                <div class="pro-img m-t-20"><img src="{{event.img}}" alt="event"></div>
                <h3 class="m-b-0">{{event.titulo}}</h3>
                <h6 class="text-muted">{{event.descripcion}}</h6>
                <h6 *ngIf="event.estado == 2"> <span class="label label-primary">Desactivado</span> </h6>
                <small *ngIf="event.eventos_tipos_id == 2"><span
                    class="label label-warning label-rounded">MultiUsuario</span></small>
                <small *ngIf="event.eventos_tipos_id == 1"><span
                        class="label label-danger label-rounded">Sin Usuarios</span></small>
            </div>
            <div class="text-center bg-light">
                <div class="row">
                    <div class="col-6  p-20 b-r">
                        <h5 class="m-b-0 font-medium">{{event.fech_ini | date: 'short' }}</h5><small>Inicio</small></div>
                    <div class="col-6  p-20">
                        <h5 class="m-b-0 font-medium">{{event.fech_fin | date: 'short'}}</h5><small>Fin</small></div>
                </div>
            </div>
            <div class="card-body text-center">
                <div class="btn-group btn-group" role="group" aria-label="Basic example">
                    <button type="button" class="m-t-10 m-b-20 waves-effect waves-dark btn-sm btn-rounded btn btn-warning" (click)="data_to_delete(event.id, event.titulo)" data-toggle="modal" data-target="#modal-eliminar" ><i class="fa fa-trash"></i>Eliminar</button>
                    <button type="button" class="m-t-10 m-b-20 waves-effect waves-dark btn-sm btn-rounded btn btn-warning" (click)="update_event(event.id)" ><i class="fa fa-search"></i> Editar </button>
                    <a type="button" class="m-t-10 m-b-20 waves-effect waves-dark btn-sm btn-rounded btn btn-warning" [routerLink]="['/evento/detalle', event.id]" routerLinkActive="router-link-active"   ><i class="fa fa-plus"></i> Ver Evento</a>
                </div>
            </div>
        </div>
    </div>
    
    <!--Little Profile widget-->
</div>

<div class="row">
    <br *ngFor="let i of [].constructor(14)">
</div>
<!-- ============================================================== -->
<!-- End PAge Content -->
<!-- ============================================================== -->



<div id="modal-eliminar" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="display: none;">
    <form class="modal-dialog" [formGroup]="formDataDelete">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="mySmallModalLabel">Eliminar Evento</h4>
                <button type="button" (click)="evento_id = 0; evento_name = '';" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="alert alert-warning" role="alert">
                <a href="#" class="alert-link">¡Cosas inesperadas sucederán si no lees esto!</a>
            </div>
            <div class="modal-body">
                <strong>Esta acción no se puede deshacer.</strong>
                <br>
                <p>Esto eliminará permanentemente el área y todo lo relacionado
                    con ella dejará de ser accesible.</p>
                <p>Escriba "<strong *ngIf="evento_name">{{evento_name}}</strong>" para confirmar.</p>
                <div class="form-group">
                    <input type="text" class="form-control" id="recipient-name" formControlName="nombreConfirm"
                        name="nombreConfirm">
                    <div *ngIf="formDataDelete.controls.nombreConfirm.invalid && (formDataDelete.controls.nombreConfirm.dirty || formDataDelete.controls.nombreConfirm.touched)">
                        <div class="alert alert-danger" role="alert" *ngIf="formDataDelete.controls.nombreConfirm.errors.required">
                            El título es requerido
                        </div>
                    </div>
                    <div *ngIf="!formDataDelete.controls.nombreConfirm.invalid && formDataDelete.controls.nombreConfirm.value != evento_name">
                        <div class="alert alert-danger" role="alert">
                            Los nombres no coinciden
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="modal-footer">
                <button type="button" (click)="evento_id = 0; evento_name = '';" class="btn btn-secondary text-uppercase btn-rounded"
                    data-dismiss="modal">Cerrar</button>
                <button type="submit" (click)="delete_event()" [disabled]="formDataDelete.controls.nombreConfirm.value != evento_name"
                    class="btn btn-warning text-uppercase btn-rounded">Eliminar</button>
            </div>
        </div>
    </form>
</div>