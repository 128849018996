import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController } from 'src/app/controller/alert.controller';
import { RoutesController } from 'src/app/controller/routes.controller';
import { CompraService } from 'src/app/services/compra.service';
import { EventService } from 'src/app/services/event.service';
import { ProductService } from 'src/app/services/product.service';
import { TrasladosService } from 'src/app/services/traslados.service';

//JQUERY
declare var $: any;

@Component({
  selector: 'app-envios-detalle',
  templateUrl: './envios-detalle.component.html'
})

export class EnviosDetalleComponent implements OnInit {

  //VARIABLES PARA ENVIO
  user_id:                number = 0;
  event_id:               number = 0;
  array_compras:          any[]= []
  array_compras_by_date:  any[]= []
  array_products:         any[] = []
  array_detalle:          any[] = []
  total_product:          number = 0;
  total_money:            number = 0;
  event_detail:           any    = null;
  es_envio:               boolean     = true;

  //VARIABLES PARA TRANSFERENCIA

  constructor(
    private router: Router,
    private compras_Service: CompraService,
    private event_Service: EventService,
    private product_Service: ProductService,
    private traslado_service: TrasladosService,
    private activ_route: ActivatedRoute
  ) { 
    
    //SE OBTIENE EL ID USUARIO
    this.user_id = Number(localStorage.getItem('id'))
    this.initialize();

    //VERIFICO SI LO QUE QUIERO VER SON ENVIOS O RECAUDACIONES
    if (this.router.url.includes('recaudaciones')) {
      this.es_envio = false; 
    }
  }


  ngOnInit(): void {
  }


  /* 
    //////////////////////
      SECCION DE ENVIOS
    /////////////////////
  */

  initialize(){
    
    //SE OBTIENE EL ID DEL EVENTO
    this.activ_route.params.subscribe(param => {
      this.event_id = param['id_event']

      this.event_Service.get_event_by_id(this.event_id).subscribe(data => {
        this.event_detail = data
        console.log(this.event_detail)
      });
    })
    
    this.total_money = this.total_product = 0;
    //OBTENGO LAS COMPRAS
    this.compras_Service.get_all().subscribe(
      (data: any[]) => {

        //FILTRO LAS COMPRAS QUE COINCIDAN CON EL ID USUARIO LOGUEADO Y EVENTO ACTUAL
        for (let i = 0; i < data.length; i++) {
          const compra = data[i];

          //VEFIFICO QUE NO SEA ADMIN, SI ES ADMIN O SI EL USUARIO LOGUEADO ES EL PROPIETARIO DEL EVENTO, 
          //TRAE TODOS LOS ENVIOS SIN IMPORTAR EL ID. SI ES USER, SI IMPORTA EL ID
          var condicion = false;
          if (localStorage.getItem('admin') == '1'  || this.event_detail.usuarios_id == this.user_id) {

            condicion = (compra.evento_id == this.event_id);
          
          } else {
            condicion = (compra.evento_id == this.event_id) && (compra.usuarios_id == this.user_id) 
          }

          if (condicion) {
            var comp = {
              id: compra.id,
              total: compra.total,
              fecha: compra.fecha
            }
            this.total_money = this.total_money + compra.total
            this.array_compras.push(comp)
          }
        }

        //ORDENO LAS COMPRAS POR FECHA

        for (let i = 0; i < this.array_compras.length; i++) {
          const compra = this.array_compras[i];
          
          var array = []


          for (let j = 0; j < this.array_compras.length; j++) {
            const el = this.array_compras[j];
            if (compra.fecha == el.fecha) {
              array.push({ id: el.id, total: el.total   })
            }
          }
          var fech = String(compra.fecha).split('T')

          if (!(this.array_compras_by_date.some(p => p.fecha == fech[0]))) {
            this.array_compras_by_date.push({fecha: fech[0], compras: array })
          }
        }

        this.compras_Service.get_compra_detalle()
          .subscribe(
            (detalle: any[]) => {
              for (let i = 0; i < detalle.length; i++) {
                const compra = detalle[i];

                for (let j = 0; j < this.array_compras.length; j++) {
                  const comp = this.array_compras[j];

                  if (compra.compras_id == comp.id) {
                    this.total_product = this.total_product + compra.cantidad
                    this.array_detalle.push(compra)
                  }
                }
              }
            });
      });
  }
  get_products(compras: any[]){
    this.array_products = [];

    for (let i = 0; i < compras.length; i++) {
      const compra = compras[i];

      for (let j = 0; j < this.array_detalle.length; j++) {
        const detalle = this.array_detalle[j];
        
        if (compra.id == detalle.compras_id) {
          this.product_Service.product_get_by_id(detalle.productos_id).subscribe(data => {
            this.array_products.push({product: data, cantidad: detalle.cantidad})
          })
        }
      } 
    }
  }
  /*
    ////////////////////////////////
      SECCION DE TRANSFERENCIAS
    ///////////////////////////////
  */

  send_transaction(name: string, number: any, total: any, bank: string){
    if (name ==''|| number == ''|| bank == ''|| total == '') {
      AlertController.getInstance().show_swall('Error', 'Debe llenar todos los campos', 'error');
      return
    }

    if (total > this.total_money) {
      AlertController.getInstance().show_swall('Error', 'El monto a transferir excede lo recaudado por el evento', 'error');
      return; 
    }

    var transferencia = {
      "recaudado": String(Number(this.event_detail.recaudado) - Number(total)),
      "transferido": String(Number(this.event_detail.transferido) + Number(total))
    }


    this.event_Service.put(transferencia, this.event_id).subscribe(
      (data: any) => {

        let traslado = {
          "id_eventos" : this.event_id,
          "monto" : total,
          "fecha" : RoutesController.getInstance().get_date(),
          "hora"  :RoutesController.getInstance().get_hour(),
          "estado": 2
        }
        this.traslado_service.post(traslado).subscribe(
          (data: any)=> {
            if (data.ok) {
              $('#transaction-modal').modal('hide');
              this.initialize();
              AlertController.getInstance().show_swall('Transferencia enviada', 'La transferencia se completó correctamente', 'success')                        
            }
          })
      }
    );


  }
}
