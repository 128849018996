<div *ngIf="event_detail && user_event" class="row page-titles">
    <div class="col-md-5 align-self-center">
        <h3 class="text-themecolor">Detalle de Evento</h3>
    </div>
    <div class="col-md-7 align-self-center">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a>Home</a></li>
            <li class="breadcrumb-item">Evento</li>
            <li class="breadcrumb-item ">Detalle</li>
            <li class="breadcrumb-item active">{{ event_detail.titulo }}</li>
        </ol>
    </div>
</div>
<!-- ============================================================== -->
<!-- Start Page Content -->
<!-- ============================================================== -->
<div class="row" *ngIf="event_detail && user_event">
    <div class="col-lg-4 col-xlg-3 col-md-5">
        <div class="card">
            <img class="card-img" src="{{ event_detail.img }}" alt="Card image">
        </div>
        <div *ngIf="event_detail.usuarios_id == id_user_logueado">
            <div><hr></div>
                <div class="card">
                    <a type="button" [routerLink]="['/evento/' + event_detail.id + '/recaudaciones']" routerLinkActive="router-link-active"   class="btn btn-warning"><i class="ti-wallet"></i> Ver Recaudaciones y Transferencias</a>
                </div>
        </div>
        
    </div>

    <!-- Column -->
    <div class="col-lg-8 col-xlg-9 col-md-7">
        <div class="card">
            <!-- Nav tabs -->
            <ul class="nav nav-tabs profile-tab" role="tablist">
                <li class="nav-item"> <a class="nav-link active" data-toggle="tab" href="#home" role="tab">Detalles</a>
                </li>
                <li class="nav-item"> <a class="nav-link" data-toggle="tab" href="#perfil" role="tab">Perfil de
                        Usuario</a> </li>
                <li class="nav-item"> <a class="nav-link" data-toggle="tab" href="#items" role="tab">Secuencia</a> </li>
            
            </ul>
            <!-- Tab panes -->
            <div class="tab-content">
                <div class="tab-pane active" id="home" role="tabpanel">
                    <div class="card-body">
                        <div class="card-body">
                            <div class="comment-widgets m-b-20">
                                <!-- Comment Row -->
                                <div class="d-flex flex-row comment-row">
                                    <div class="p-2">
                                        <div class="round align-self-center round-info"><i class="ti-comments"></i>
                                        </div>
                                    </div>
                                    <div class="comment-text w-100">
                                        <h5>Título del Evento</h5>
                                        <p class="m-b-5 m-t-10">{{ event_detail.titulo }}</p>
                                    </div>
                                </div>
                                <!-- Comment Row -->
                                <div class="d-flex flex-row comment-row">
                                    <div class="p-2">
                                        <div class="round align-self-center round-warning"><i class="ti-align-left"></i>
                                        </div>
                                    </div>
                                    <div class="comment-text w-100">
                                        <h5>Descripción del Evento</h5>
                                        <p class="m-b-5 m-t-10">{{ event_detail.descripcion }}</p>
                                    </div>
                                </div><!-- Comment Row -->
                                <div class="d-flex flex-row comment-row">
                                    <div class="p-2">
                                        <div class="round align-self-center round-danger"><i class="ti-calendar"></i>
                                        </div>
                                    </div>
                                    <div class="comment-text w-100">
                                        <h5>Fecha de Inicio</h5>
                                        <p class="m-b-5 m-t-10">{{ event_detail.fech_ini | date:'short' }}</p>
                                    </div>
                                </div><!-- Comment Row -->
                                <div class="d-flex flex-row comment-row">
                                    <div class="p-2">
                                        <div class="round align-self-center round-danger"><i class="ti-calendar"></i>
                                        </div>
                                    </div>

                                    <div class="comment-text w-100">
                                        <h5>Fecha de Finalización</h5>
                                        <p class="m-b-5 m-t-10">{{ event_detail.fech_fin | date:'short' }}</p>
                                    </div>
                                </div><!-- Comment Row -->
                                <div class="d-flex flex-row comment-row">
                                    <div class="p-2">
                                        <div *ngIf="event_detail.eventos_tipos_id == 2"
                                            class="round align-self-center round-info"><i class="ti-user"></i></div>
                                        <div *ngIf="event_detail.eventos_tipos_id == 1"
                                            class="round align-self-center round-primary"><i class="ti-close"></i></div>
                                    </div>

                                    <div class="comment-text w-100">
                                        <h5>Tipo de Evento</h5>
                                        <p *ngIf="event_detail.eventos_tipos_id == 2" class="m-b-5 m-t-10"> MultiUsuario
                                        </p>
                                        <p *ngIf="event_detail.eventos_tipos_id == 1" class="m-b-5 m-t-10"> Sin Usuario
                                        </p>
                                    </div>
                                </div><!-- Comment Row -->
                                <div *ngIf="event_detail.usuarios_id == id_user_logueado"
                                    class="d-flex flex-row comment-row">
                                    <div class="p-2">
                                        <div class="round align-self-center round-success"><i class="ti-wallet"></i>
                                        </div>
                                    </div>
                                    <div class="comment-text w-100">
                                        <h5>Dinero Recaudado</h5>
                                        <p class="m-b-5 m-t-10">Q {{ event_detail.recaudado }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--second tab-->
                <div class="tab-pane" id="perfil" role="tabpanel">
                    <br>
                    <br>
                    <div class="row">
                        <div class="col-lg-4 m-b-30">
                        </div>
                        <div class="col-lg-4 text-center m-b-30">
                            <br>
                            <img src="{{ user_event.img }}" alt="image" class="img-circle" height="290" width="290">
                            <p class="m-t-15 m-b-0">
                            </p>
                        </div>
                        <div class="col-lg-4 m-b-30">

                        </div>
                    </div>
                    <br><br>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-4 col-xs-6 b-r"> <strong>Nombre Completo</strong>
                                <br>
                                <p class="text-muted">{{ user_event.nombres }} {{ user_event.apellidos }}</p>
                            </div>
                            <div class="col-md-4 col-xs-6 b-r"> <strong>Número de Teléfono</strong>
                                <br>
                                <p class="text-muted">(502) {{ user_event.numero }}</p>
                            </div>
                            <div class="col-md-4 col-xs-6 b-r"> <strong>Email</strong>
                                <br>
                                <p class="text-muted">{{ user_event.email }}</p>
                            </div>

                        </div>
                    </div>
                    <br>
                    <br>
                    <br>
                </div>
                <div class="tab-pane" id="items" role="tabpanel">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-3 col-xs-6 b-r"> <strong>Vista actual</strong>
                                <br>
                                <p class="text-muted">{{ secuencia }}</p>
                            </div>
                            <div class="col-md-3 col-xs-6 b-r"> <strong>Cantidad de datos</strong>
                                <br>
                                <p class="text-muted"> {{ initi_list.length }} </p>
                            </div>
                            <div class="col-md-6 col-xs-6 b-r"> <strong>Secuencia Actual</strong>
                                <br>
                                <p class="text-muted">Evento <a *ngFor="let i of array_titulo_secuencia"> {{ i }}</a></p>
                            </div>
                        </div>
                        <hr>
                        <div class="profiletimeline">
                            <div *ngIf="initi_list.length > 0">
                                <div *ngFor="let item of initi_list">

                                    <div class="sl-item" *ngIf="item.estado == 1">
                                        <div class="sl-left">
                                            <div class="round align-self-center {{ tipo_icono }}"><i
                                                    class="{{ icono_mostrar }}"></i></div>

                                        </div>
                                        <div class="sl-right">
                                            <div>
                                                <a class="link" *ngIf="!mostrar_usuarios">Título: {{ item.titulo }} </a>
                                                <a class="link" *ngIf="mostrar_usuarios">Nombre: {{ item.nombres }}
                                                    {{ item.apellidos }} </a>
                                                <div class="m-t-20 row">
                                                    <div class="col-md-3 col-xs-12"><img src="{{ item.img }}" alt="user"
                                                            class="img-responsive radius"></div>
                                                    <div class="col-md-9 col-xs-12">
                                                        <p *ngIf="!mostrar_usuarios"> {{ item.descripcion }} </p>
                                                        <p *ngIf="mostrar_usuarios"> {{ item.email }} </p>

                                                        <a *ngIf="mostrar_productos && (event_detail.usuarios_id != id_user_logueado || permitido_seguir_sec == '1')"
                                                            style="color: white;" (click)="save_product(item)"
                                                            class="btn btn-success"> Agregar para Enviar</a>

                                                        <a *ngIf="item.secuencias_egr_id == 2" style="color: white;"
                                                            (click)="avanzar(item.secuencias_egr_id, item.id)"
                                                            class="btn btn-warning"> Ver Categorias</a>
                                                        <a *ngIf="item.secuencias_egr_id == 3" style="color: white;"
                                                            (click)="avanzar(item.secuencias_egr_id, item.id)"
                                                            class="btn btn-warning"> Ver Productos</a>
                                                        <a *ngIf="item.secuencias_egr_id == 4" style="color: white;"
                                                            (click)="avanzar(item.secuencias_egr_id, item.id)"
                                                            class="btn btn-warning"> Ver Usuarios</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="btn-group" role="group" aria-label="Basic example">
                                <button class="btn btn-info" [disabled]='index == 0' (click)="regresar()"><i
                                    class="fa fa-mail-reply"></i> Regresar</button>
                            <button
                                *ngIf="event_detail.usuarios_id == id_user_logueado || permitido_seguir_sec == '1'"
                                class="btn btn-warning" (click)="agregar()"><i class="fa fa-plus"></i>
                                Agregar {{ secuencia }}</button>
                            <button
                                *ngIf="mostrar_productos && (event_detail.usuarios_id != id_user_logueado || permitido_seguir_sec == '1')"
                                [disabled]="array_products.length == 0" class="btn btn-warning"
                                data-toggle="modal" data-target=".bs-example-modal-lg"><i
                                    class="fa fa-plus"></i> Enviar Productos</button>
                              </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade bs-example-modal-lg" tabindex="-1" id="modal_envio" role="dialog" aria-labelledby="myLargeModalLabel"
    aria-hidden="true" style="display: none;">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-body">
                <div class="card" >
                    <div class="card-body">
                        <div class="d-flex">
                            <div>
                                <h4 class="card-title"><span class="lstick"></span>Detalles de envío</h4>
                            </div>
                            <div class="ml-auto">
                                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                            </div>
                        </div>
                    </div>
                </div>                
                <div class="container">
                    <div class="stepwizard">
                        <div class="stepwizard-row setup-panel">
                            <div class="stepwizard-step">
                                <a href="#step-1" type="button" class="btn btn-primary btn-circle">1</a>
                                <p>Productos</p>
                            </div>
                            <div class="stepwizard-step">
                                <a href="#step-2" type="button" class="btn btn-default btn-circle disabled">2</a>
                                <p>Método de pago</p>
                            </div>
                            <div class="stepwizard-step">
                                <a href="#step-3" type="button" class="btn btn-default btn-circle disabled">3</a>
                                <p>Detalles</p>
                            </div>
                        </div>
                    </div>
                    <form role="form">
                        <div class="row setup-content" id="step-1">
                            <div class="col-xl-12">
                                <div class="col-md-12">
                                    <div class="table-responsive m-t-20 no-wrap">
                                        <table class="table vm no-th-brd pro-of-month">
                                            <thead>
                                                <tr>
                                                    <th colspan="2">Producto</th>
                                                    <th>Precio</th>
                                                    <th>Cant sol</th>
                                                    <th>Cant env</th>
                                                    <th>Total</th>
                                                    <th>Opciones</th>
                                                </tr>
                                            </thead>
                                            <tbody *ngIf="array_products.length != 0">
                                                <tr *ngFor="let p of array_products" class="active">
                                                    <td>
                                                        <div class="user-img"> 
                                                            <img src="{{ p.img }}" alt="user" width="50" height="50"  class="img-circle"> 
                                                        </div> 
                                                    </td>
                                                    <td>
                                                        <h5>{{ p.titulo }}</h5>
                                                    </td>
                                                    <td><span class="label label-info label-rounded">Q {{ p.precio }}</span></td>
                                                    <td>{{p.cantidad_max}}</td>
                                                    <td> <input type="number" #cant value="1" id="form_name_{{p.productos_id}}"   
                                                        (input)="update_total(cant.value, p.productos_id)" min="1" max="{{p.cantidad_max}}" 
                                                        oninput="javascript: if (this.value > this.max) this.value = this.max"  
                                                        style="width: 100px;" class="form-control"> </td>
                                                    <td>Q {{ p.total }}</td>
                                                    <td><button type="button" class="btn btn-danger"   (click)="delete_product(p.productos_id)" ><i class="fa fa-trash"></i></button></td>
                                                </tr>
                                            </tbody>
                                            <tbody *ngIf="array_products.length == 0">
                                                <tr>
                                                    <td>Sin datos </td>
                                                    <td></td>
                                                    <td>Sin datos</td>
                                                    <td>Sin datos</td>
                                                    <td>Sin datos</td>
                                                    <td>Sin datos</td>
                                                    <td>Sin datos</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="modal-footer">
                                        <div class="btn btn-group">
                                            <button type="button" class="btn btn-danger waves-effect text-left"
                                                data-dismiss="modal">Cerrar</button>
                                            <button type="button" [disabled]='array_products.length == 0'  class="btn btn-primary  nextBtn waves-effect text-left"> <i
                                                    class="fa fa-check"></i> Siguiente</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row setup-content" id="step-2">

                            <div class="col-xl-12">
                                <div class="col-md-12">
                                    <article class="card">
                                        <div class="card-body p-5">
                                            <ul class="nav bg-light nav-pills rounded nav-fill mb-3" role="tablist">
                                                <li class="nav-item">
                                                    <a class="nav-link active" data-toggle="pill" href="#nav-tab-card">
                                                        <i class="fa fa-credit-card"></i> Tarjeta de Crédito</a></li>
                                            </ul>
        
                                            <div class="tab-content">
                                                <div class="tab-pane fade show active" id="nav-tab-card">
                                                    
                                                    <form role="form">
                                                        <div class="form-group">
                                                            <label for="username">Nombre completo (en la tarjeta)</label>
                                                            <input #nombre type="text" class="form-control" name="username"
                                                                placeholder="" required="">
                                                        </div> <!-- form-group.// -->
        
                                                        <div class="form-group">
                                                            <label for="cardNumber">Número de tarjeta</label>
                                                            <div class="input-group">
                                                                <input #tarjeta type="number" maxlength="16"  
                                                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" 
                                                                class="form-control" name="cardNumber" placeholder="">
                                                                <span class="input-group-btn">
                                                                    <button class="btn btn-info" type="button"><i
                                                                            class="fa fa-credit-card"></i></button>
                                                                </span>
                                                            </div>
                                                        </div> <!-- form-group.// -->
        
                                                        <div class="row">
                                                            <div class="col-sm-8">
                                                                <div class="form-group">
                                                                    <label><span class="hidden-xs">Fecha de expiración</span>
                                                                    </label>
                                                                    <div class="input-group">
                                                                        <input #date1 type="number" maxlength="2" class="form-control"
                                                                        oninput="javascript: if (this.value > 12) this.value = 12" 
                                                                        placeholder="MM" name="">
                                                                        <input #date2 type="number" maxlength="2" class="form-control"
                                                                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" 
                                                                        placeholder="YY" name="">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-4">
                                                                <div class="form-group">
                                                                    <label data-toggle="tooltip" title=""
                                                                        data-original-title="3 digits code on back side of the card">CVV
                                                                        <i class="fa fa-question-circle"></i></label>
                                                                    <input #cvv type="number" minlength="3" maxlength="3"  class="form-control"
                                                                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" 
                                                                    required="">
                                                                </div> <!-- form-group.// -->
                                                            </div>
                                                        </div> <!-- row.// -->
                                                        <button (click)="verify_credit_card(nombre.value, tarjeta.value, date1.value, date2.value, cvv.value)" class="subscribe btn btn-primary btn-block"
                                                            type="button"> Verificar </button>
                                                    </form>
                                                </div> <!-- tab-pane.// -->
                                            </div> <!-- tab-content .// -->
        
                                        </div> <!-- card-body.// -->
                                    </article> <!-- card.// -->
        
        
                                    <div class="modal-footer">
                                        <div class="btn btn-group">
                                            <button type="button" class="btn btn-danger backBtn waves-effect text-left" ><i class="fa fa-mail-reply"></i>Regresar </button>
                                            <button type="button" [disabled]="error_tarjeta" (click)="update_total_dinero()"  class="btn btn-primary  nextBtn waves-effect text-left"> <i
                                                    class="fa fa-check"></i> Siguiente</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                      


                        </div>
                        <div class="row setup-content" id="step-3">


                            <div class="col-xl-12" >
                                <div class="col-md-12">
                                    
                                    <div class="card card-body printableArea">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="pull-left">
                                                    <address *ngIf="usuario_tarjeta != null">
                                                        <h3> &nbsp;<b class="text-danger">Detalles de Usuario</b></h3>
                                                        <p class="text-muted m-l-5">Nombre: {{usuario_tarjeta.nombre}},
                                                            <br> N. Tarjeta: {{usuario_tarjeta.tarjeta}},
                                                            <br> F. expiración: {{usuario_tarjeta.expira }}
                                                    </address>
                                                </div>
                                                <div class="pull-right text-right">
                                                    <address>
                                                        <p class="m-t-30"><b>Fecha :</b> <i class="fa fa-calendar"></i> {{fecha_actual}}</p>
                                                    </address>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="table-responsive m-t-40" style="clear: both;">
                                                    <table class="table table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th class="text-center">#</th>
                                                                <th>Producto</th>
                                                                <th class="text-right">Cantidad</th>
                                                                <th class="text-right">Precio</th>
                                                                <th class="text-right">Total</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let prod of array_products; let i = index">
                                                                <td class="text-center">{{ i + 1 }}</td>
                                                                <td>{{ prod.titulo }}</td>
                                                                <td class="text-right"> {{ prod.cantidad }} </td>
                                                                <td class="text-right">Q {{ prod.precio }}</td>
                                                                <td class="text-right">Q {{ prod.total}} </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="pull-right m-t-30 text-right">
                                                    <h3><b>Total :</b> Q {{ total_dinero }}</h3>
                                                </div>
                                                <div class="clearfix"></div>
                                            </div>
                                        </div>
                                    </div>
        
                                    <div class="modal-footer">
                                        <div class="btn btn-group">
                                            <button type="button" class="btn btn-danger backBtn waves-effect text-left" ><i class="fa fa-mail-reply"></i>Regresar </button>
                                            <button type="button" (click)="send_product()" class="btn btn-primary  waves-effect text-left"> <i
                                                    class="fa fa-check"></i> Enviar Productos</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>

