<div class="row">
    <div class="col-12">
        <div class="card">
            <!-- .left-right-aside-column-->
            <div class="event-page-aside">
                <div class="right-aside ">
                    <div class="right-page-header">
                        <div class="d-flex">
                            <div class="align-self-center">
                                <h4 class="card-title m-t-10">Lista de Categorías </h4>
                            </div>
                            <div class="ml-auto">
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Buscar categoría" [(ngModel)]="searchText">
                                    <span class="input-group-btn">
                                        <button type="button" (click)="reset_form('','', secuencia_ant ,'',1, 'https://res.cloudinary.com/dksstmpfu/image/upload/v1609777772/test/categoria/ihswb9x5tunj2dgzpyxf.jpg', 0 , 0);;" class="btn btn-warning" data-toggle="modal"
                                        data-target="#modal_categoria"><i class="ti-plus"></i> Nueva Categoría</button>
                                            </span>
                                </div>
                           </div>


                        </div>
                    </div>
                    <!-- /.left-right-aside-column-->
                </div>
            </div>
        </div>
    </div>
</div>


<div class="row">
    <div class="col-12 m-t-30">
        <div class="card-columns el-element-overlay">
            <div *ngFor="let category of category_list | filter:searchText">
                <div class="card">
                    <div class="el-card-item">
                        <div class="el-card-avatar el-overlay-1">
                            <a class="image-popup-vertical-fit"> <img src="{{ category.img }}" alt="Categoría" /> </a>
                            <div class="el-overlay">
                                <ul class="el-info">
                                    <li><a class="btn default btn-outline" (click)="data_to_delete(category.id, category.titulo)" data-toggle="modal" data-target="#modal-eliminar"><i class="icon-trash"></i></a></li>
                                    <li>
                                        <a class="btn default btn-outline" data-toggle="modal" data-target="#modal_categoria" (click)="reset_form(category.titulo, category.descripcion, category.secuencias_ing_id, category.secuencias_egr_id, category.estado, category.img, 1, category.id)">
                                        <i class="icon-magnifier-add"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="el-card-content">
                            
                            <h4 class="box-title">{{ category.titulo }}</h4> 
                            <div class="card-body">
                                {{ category.descripcion }}
                            </div>
                            <small *ngIf="category.estado == 1"><span class="label label-warning label-rounded">Habilitada</span></small>
                            <small *ngIf="category.estado == 2"><span class="label label-danger label-rounded">Deshabilitada</span></small>
                            <hr>
                            <button class="btn btn-warning btn-sm" (click)="get_ruta(category.id, category.secuencias_egr_id)" *ngIf="category.secuencias_egr_id == 2" ><i class="ti-arrow-circle-right"></i> Ir a Categorías</button>
                            <button class="btn btn-warning btn-sm" (click)="get_ruta(category.id, category.secuencias_egr_id)" *ngIf="category.secuencias_egr_id == 3" ><i class="ti-arrow-circle-right"></i> Ir a Productos</button>
                            <button class="btn btn-warning btn-sm" (click)="get_ruta(category.id, category.secuencias_egr_id)" *ngIf="category.secuencias_egr_id == 4" ><i class="ti-arrow-circle-right"></i> Ir a Usuarios</button>
    
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</div>


<div class="row">
    <br *ngFor="let i of [].constructor(16)">
</div>





<!-- ============================================================== -->
<!--                  MODAL RESPONSIVE AGREGAR                      -->
<!-- ============================================================== -->
<div id="modal_categoria" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="display: none;">
    <div *ngIf="categoryForm">
        <form class="modal-dialog modal-lg" [formGroup]="categoryForm" >
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Formulario Categoría</h4>
                    <button type="button" (click)="reset_form('','', secuencia_ant ,'',1, 'https://res.cloudinary.com/dksstmpfu/image/upload/v1609777772/test/categoria/ihswb9x5tunj2dgzpyxf.jpg', 0 , 0);" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="row el-element-overlay"  style="margin-bottom: 0px !important;">
                                <div class="col-md-2">
                                </div>
                                <div class="col-md-8">
                                    <div class="card" style="margin-bottom: 0px !important;">
                                        <div *ngIf="estoy_edit == 0" class="el-card-item"  style="margin-bottom: 0px !important;">
                                            <div class="el-card-avatar el-overlay-1"  style="margin-bottom: 0px !important;"> 
                                                <img *ngIf="!file" src="https://res.cloudinary.com/dksstmpfu/image/upload/v1609777772/test/categoria/ihswb9x5tunj2dgzpyxf.jpg" alt="user" />
                                                <img *ngIf="file" src="{{strImage}}" alt="user" />
                                            </div>
                                        </div>
                                        <div *ngIf="estoy_edit==1" class="el-card-item"  style="margin-bottom: 0px !important;">
                                            <div class="el-card-avatar el-overlay-1"  style="margin-bottom: 0px !important;"> 
                                                <img  src="{{strImage}}" alt="user" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                </div>
                            </div>
                            <div class="form-group text-center">
                                <label>Foto de la categoría (opcional)</label>
                                <div class="fileupload btn btn-danger btn-rounded waves-effect waves-light"><span><i
                                    class="fa fa-camera"></i> Seleccionar Imagen</span>
                                    <input type="file" accept="image/*"  (change)="seleccionImage($event)"  class="upload"> 
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label for="category_name" class="control-label">Título:</label>
                                    <input type="text" formControlName="titulo" class="form-control" id="category_name1"
                                    maxlength="25"
                                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                    placeholder="Máximo 25 caractereres"
                                    onkeypress="return (event.charCode != 34 && event.charCode != 39) "
                                
                                    >
                                    <div *ngIf="f.titulo.invalid && (f.titulo.dirty || f.titulo.touched)">
                                        <div *ngIf="f.titulo.errors.required" style="color:red">El título es requerido</div> 
                                    </div>
                            </div>
                            <div class="form-group">
                                <label for="category_estado" class="control-label">Estado:</label>
                                    <br>
                                    <select formControlName="estado" class="form-control" id="category_est">
                                        <option value="1">Habilitada</option>
                                        <option value="2">No Habilitada</option>
                                    </select>
                                    <div *ngIf="f.estado.invalid && (f.estado.dirty || f.estado.touched)">
                                        <div *ngIf="f.estado.errors.required" style="color:red">El estado es requerido</div> 
                                    </div>
                            </div>
                            <div class="form-group">
                                <label for="category_descripcion" class="control-label">Descripción:</label>
                                <textarea class="form-control" formControlName="descripcion" 
                                id="category_descripcion1"
                                maxlength="150"
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                placeholder="Máximo 150 caracteres"
                                onkeypress="return (event.charCode != 34 && event.charCode != 39) "

                                ></textarea>
                                <div *ngIf="f.descripcion.invalid && (f.descripcion.dirty || f.descripcion.touched)">
                                    <div *ngIf="f.descripcion.errors.required" style="color:red">La descripción es requerida</div> 
                                </div>
                            </div>
                            
                            <div class="form-group" *ngIf="estoy_edit == 0">
                                <label for="category_sec"  class="control-label">Secuencia:</label>
                                <select formControlName="secuencias_egr_id"  class="form-control" id="category_sec">
                                    <option selected disabled>Select</option>
                                    <option *ngFor="let route of opciones_ruta[0]" value="{{route.value}}">{{ route.option }}</option>
                                
                                </select>
                                <div *ngIf="f.secuencias_egr_id.invalid && (f.secuencias_egr_id.dirty || f.secuencias_egr_id.touched)">
                                    <div *ngIf="f.secuencias_egr_id.errors.required" style="color:red">La secuencia es requerida</div> 
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="btn btn-group ">
                        <button (click)="reset_form('','', secuencia_ant ,'',1, 'https://res.cloudinary.com/dksstmpfu/image/upload/v1609777772/test/categoria/ihswb9x5tunj2dgzpyxf.jpg', 0 , 0);" class="btn btn-outline-secondary waves-effect waves-light" type="button" data-dismiss="modal"><span class="btn-label"><i class="ti-close"></i></span>Cancelar</button>
                        <button (click)="uploadImage()" [disabled]="!file" class="btn btn-info waves-effect waves-light" type="button"><span class="btn-label"><i class="ti-gallery"></i></span>Subir Foto</button>
                        <button (click)="save_category()" [disabled]="!categoryForm.valid || subiendo_imagen" class="btn btn-warning waves-effect waves-light" type="button"><span class="btn-label"><i class="ti-save"></i></span>Guardar</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- ============================================================== -->
<!--                  MODAL RESPONSIVE AGREGAR                      -->
<!-- ============================================================== -->



<!-- ============================================================== -->
<!--                  MODAL RESPONSIVE ELIMINAR                     -->
<!-- ============================================================== -->


<div id="modal-eliminar" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="display: none;">
    <form class="modal-dialog" [formGroup]="formDataDelete">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="mySmallModalLabel">Eliminar Categoría</h4>
                <button type="button" (click)="category_id = 0; category_name = '';" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="alert alert-warning" role="alert">
                <a href="#" class="alert-link">¡Cosas inesperadas sucederán si no lees esto!</a>
            </div>
            <div class="modal-body">
                <strong>Esta acción no se puede deshacer.</strong>
                <br>
                <p>Esto eliminará permanentemente la categoría y todo lo relacionado
                    con ella dejará de ser accesible.</p>
                <p>Escriba "<strong *ngIf="category_name">{{category_name}}</strong>" para confirmar.</p>
                <div class="form-group">
                    <input type="text" class="form-control" id="recipient-name" formControlName="nombreConfirm"
                        name="nombreConfirm">
                    <div *ngIf="formDataDelete.controls.nombreConfirm.invalid && (formDataDelete.controls.nombreConfirm.dirty || formDataDelete.controls.nombreConfirm.touched)">
                        <div class="alert alert-danger" role="alert" *ngIf="formDataDelete.controls.nombreConfirm.errors.required">
                            El título es requerido
                        </div>
                    </div>
                    <div *ngIf="!formDataDelete.controls.nombreConfirm.invalid && formDataDelete.controls.nombreConfirm.value != category_name">
                        <div class="alert alert-danger" role="alert">
                            Los nombres no coinciden
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="modal-footer">
                <button type="button" (click)="category_id = 0; category_name = '';" class="btn btn-secondary text-uppercase btn-rounded"
                    data-dismiss="modal">Cerrar</button>
                <button type="submit" (click)="delete_category()" [disabled]="formDataDelete.controls.nombreConfirm.value != category_name"
                    class="btn btn-warning text-uppercase btn-rounded">Eliminar</button>
            </div>
        </div>
    </form>
</div>

<!-- ============================================================== -->
<!--                  MODAL RESPONSIVE ELIMINAR                     -->
<!-- ============================================================== -->
