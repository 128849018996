import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController } from 'src/app/controller/alert.controller';
import { RoutesController } from 'src/app/controller/routes.controller';
import { UploadFileService } from 'src/app/services/upload-file.service';
import { UserService } from 'src/app/services/user.service';

//JQUERY
declare var $: any;

@Component({
  selector: 'app-usuario-evento',
  templateUrl: './usuario-evento.component.html'
})
export class UsuarioEventoComponent implements OnInit {

  //VARIABLES GENERALES
  opciones_ruta:    any[] = [];          // guarda las opciones de ruta desde donde estoy y el id de la secuencia anterior
  id_anterior:      number = 0;         //guarda el id del objeto anterior actual
  searchText:       any    //variable usada para buscar los eventos
  secuencia_ant:    number = 0; //guarda el id de la secuencia desde donde vengo
  //VARIABLES DEL FORMULARIO DE USUARIO
  user_form:        any;

  //VARIABLES DE USUARIO
  user_list:        any[] = [];
  assignation_list: any[] = [];


  file:     any = null;
  strImage: any;
  subiendo_imagen: boolean = false;



  // Constructor
  constructor(
    private router: Router, private activ_route : ActivatedRoute,
    private formBuilder: FormBuilder,  private user_service:UserService,
    private upload_service: UploadFileService) {

      this.activ_route.params.subscribe(param => {
        //OBTENGO LAS OPCIONES HACIA DONDE DIRIGIRME
        this.opciones_ruta = RoutesController.getInstance().evaluate_route(this.router.url, param)
        //OBTENGO EL ID DE DONDE VENGO
        this.secuencia_ant = this.opciones_ruta[2].secuencia_ant
        this.id_anterior = this.opciones_ruta[1].id_anterior
      });
      //Se inicializa la data
      this.inicialize();
   
  }
   /* ///////////////////////////////////////////////////////        
              SECCION DE INICIALIZAR DATA 
  ///////////////////////////////////////////////////////*/

  ngOnInit(): void {
  }


  reset_form(n: string, ap: string, em: string, pas: string, num: string, fecha: string, sec: string, estado: number){


    this.user_form = this.formBuilder.group({
      nombres:           [ n, Validators.required],
      apellidos:         [ ap, Validators.required],
      email:             [ em, Validators.required],
      pass:              [ pas, Validators.required],
      img:               ['https://res.cloudinary.com/dksstmpfu/image/upload/v1609783937/test/categoria/sz78pbe1n76bqt58mgjm.jpg', Validators.required],
      numero:            [ num, Validators.required],
      tocken:            [ ',' ],
      fecha_nac:         [ fecha, Validators.required],
      estado:            [ estado ],
      fecha:             [ RoutesController.getInstance().get_date() ],
      hora:              [ RoutesController.getInstance().get_hour() ],
      tipo_id:           [ '2' ],
      secuencias_egr_id: [ sec, Validators.required]
    });
  }

  //trae a los usuarios de la db
  inicialize(){
    this.user_list = [];
    this.user_service.get_user_by_id_anterior(this.id_anterior, this.secuencia_ant).subscribe(data => {
      this.user_list = data;
      if (this.user_list.length == 0) {
        AlertController.getInstance().show_swall("Alerta", "No hay datos disponibles, agrege usuarios en '+ Nuevo' o asigue uno existente en '✓ Asignar'", "warning")
      }
    });
    
    $('#modal_user').modal('hide');
    this.reset_form('','','','','','', '', 1);
  }
  /*
  ///////////////////////////////////////////////////////
            
              SECCION DE INSERSION DE DATOS 
  
  ///////////////////////////////////////////////////////
  */
  //FUNCION DE VALIDACION

  get f() { // Retorna los controles del formulario que contiene la informacion para validar 
    return this.user_form.controls;
  }
  //Guarda el usuario en la db 
  save_user() {
    
    this.user_service.post(JSON.stringify(this.user_form.value)).subscribe(
      (data: any) => {
          
          // SI SE AGREGA EL USUARIO, LO MANDA A ASIGNAR
          var form = {
            usuarios_id:          data.params.id,
            buscar_id:            this.id_anterior,
            secuencias_ing_id:    this.secuencia_ant,
            secuencias_egr_id:    this.user_form.value.secuencias_egr_id,
            estado:               this.user_form.value.estado,
            fecha:                RoutesController.getInstance().get_date()
          }

          this.user_service.post_user_asig(form).subscribe(
          (data) => {
            //SE INICIALIZA LA DATA NUEVAMENTE
            this.inicialize()
            AlertController.getInstance().show_swall("Usuario Agregado", "El usuario se agregó correctamente", "success");              
          }, 
          (err) => {
            AlertController.getInstance().show_swall("Error", "El usuario se agregó pero no pudo ser asignado a este evento", "error");    
          }
        )
      }, 
      (err) => {
        AlertController.getInstance().show_swall("Error", "Ha ocurrido un error, intentelo de nuevo más tarde", "error");
      }
    );

  }


  /*///////////////////////////////////////////////////////
              SECCION DE ELIMINACION DE ASIGNACION 
  ///////////////////////////////////////////////////////*/

  delete_user(id_user:number){
    //TRAE LAS ASIGNACIONES DE LA DB
    this.user_service.get_all_user_asig().subscribe(
      (data: any[]) => {
        data.some(p=> { //BUSCO LA SIGNACION DEL USUARIO AL EVENTO
          if (p.buscar_id == this.id_anterior && p.usuarios_id == id_user) {
            this.user_service.delete(p.id, 1).subscribe(
              (data) => { //LA MANDO A ELIMINAR
                this.inicialize()
                AlertController.getInstance().show_swall("Usuario Eliminado", "La Asignación de usuario se eliminó correctamente", "success");            
              },
              (err) => {
                AlertController.getInstance().show_swall("Error", "Ha ocurrido un error, intentelo de nuevo más tarde", "error");
              }
            );
          }
        });
    });
  }
  
  /*///////////////////////////////////////////////////////          
              SECCION DE RUTAS 
  //////////////////////////////////////////////////////*/
  get_ruta(id_user: number, secuence: number){
    this.router.navigate([RoutesController.getInstance().get_ruta(this.router.url, id_user, secuence, 'u_')])
  }
  
  //CARGAR IMAGEN
  seleccionImage(archivo: any) {

    if (!(archivo.target.files[0])) {
      return;
    }
    this.file = archivo.target.files[0]
    let reader = new FileReader();
    reader.readAsDataURL( this.file );
    reader.onloadend = () => this.strImage = reader.result;
  }
  uploadImage(){
    this.subiendo_imagen = true;
    AlertController.getInstance().show_swall('Subiendo Imagen', 'Espere por favor', "warning")
      
    this.upload_service.post(this.file, 'test/categoria')
    .then((res: any) => {
      AlertController.getInstance().show_swall('Imagen subida', 'La imagen se subió con éxito', "success")
      this.user_form.get('img').setValue(res.secure_url);
      this.subiendo_imagen = false;
    })
    .catch((resp) => {
      AlertController.getInstance().show_error()
      this.subiendo_imagen = false;
    });
  }
}
