import { Component, OnInit } from '@angular/core';
import { FormBuilder,  Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertController } from 'src/app/controller/alert.controller';
import { AuthenticationService } from 'src/app/services/auth.service';
import { UploadFileService } from 'src/app/services/upload-file.service';
import { UserService } from 'src/app/services/user.service';
import { RoutesController } from '../../controller/routes.controller';
import * as firebase from 'firebase/app';
import 'firebase/auth';

declare var $: any;

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {
  
  //VARIABLES
  signinForm:   any;
  file: any;
  strImage: any;
  constructor(
    private router: Router,
    private upload_service: UploadFileService,
    private formBuilder: FormBuilder, 
    private userService: UserService) {
  
     }

  ngOnInit(): void {
    this.reset_form('','','','','https://res.cloudinary.com/dksstmpfu/image/upload/v1610211147/test/categoria/sshhivfnkjoqjo13cenf.jpg','','','')
  }

  reset_form(n: any, ap: string, em: any, pas: string, img: any, num: any, tok: any, fech: string){
    // Inicializa el formulario
    this.signinForm = this.formBuilder.group({
      nombres:      [n, Validators.required],
      apellidos:    [ap, Validators.required],
      email:        [em, Validators.required],
      pass:         [pas, Validators.required],
      img:          [img ],
      numero:       [num, Validators.required],
      tocken:       [ ',' ],
      fecha_nac:    [ RoutesController.getInstance().get_date() ],
      estado:       [ '1' ],
      fecha:        [ RoutesController.getInstance().get_date() ],
      hora:         [ RoutesController.getInstance().get_hour() ],
      tipo_id:      [ '2' ]
    });
  }

  get f() { // Retorna los controles del formulario
    // que contiene la informacion para validar 
    return this.signinForm.controls; 
  }


  onSubmit() {
    this.post_user();
  }
  //CARGAR IMAGEN
  seleccionImage(archivo: any) {

    if (!(archivo.target.files[0])) {
      return;
    }
    this.file = archivo.target.files[0]
    let reader = new FileReader();
    reader.readAsDataURL(this.file);
    reader.onloadend = () => this.strImage = reader.result;
  }
  uploadImage() {
    this.upload_service.post(this.file, 'test/categoria')
      .then((res: any) => {
        AlertController.getInstance().show_swall('Imagen subida', 'La imagen se subió con éxito', "success")
        this.signinForm.get('img').setValue(res.secure_url);
        $('#modal-photo').modal('hide');
      })
      .catch((resp) => {
        AlertController.getInstance().show_error()
      });
  }

  signin_with_google = async (): Promise<any> => {

    const { user } = await firebase.default.auth().signInWithPopup(new firebase.default.auth.GoogleAuthProvider())
    await this.verify_user(user)
  }

  verify_user = async (user: any): Promise<any> => {
    var encontrado: boolean = false; 
    await this.userService.get_all().subscribe(
      (data: any[]) => {
        data.some((u: any) => {
          if (u.email == user?.email && u.tocken == user?.uid) {
            encontrado = true;
          }
        });
        if (encontrado) {
          AlertController.getInstance().show_swall('Error', 'El usuario ya está registrado', 'error');
        } else {
          this.reset_form(user?.displayName, '', user?.email, '', user?.photoURL, user?.phoneNumber, user?.uid, RoutesController.getInstance().get_date())
          this.post_user();
        }
    });
  }
  post_user(){
    this.userService.post(JSON.stringify(this.signinForm.value)).subscribe(
      (res: any) => {
        AlertController.getInstance().show_swall('Registro exitoso', 'Ya puede iniciar sesión, actualize sus datos en el perfil de usuario.', 'success');
        setTimeout(()=> {
          this.router.navigate(['/login'])
        }, 2500)
      }, 
      error => {
        AlertController.getInstance().show_error();
      });
  }
  
}
