<div class="row">
    <div class="col-12">
        <div class="card">
            <!-- .left-right-aside-column-->
            <div class="event-page-aside">
                <div class="right-aside ">
                    <div class="right-page-header">
                        <div class="d-flex">
                            <div class="align-self-center">
                                <h4 class="card-title m-t-10">Lista de Productos </h4>
                            </div>
                            <div class="ml-auto">
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Buscar Producto" [(ngModel)]="searchText">
                                    <span class="input-group-btn">
                                        <button type="button" (click)="reset_form('', '', 'https://res.cloudinary.com/dksstmpfu/image/upload/v1609783374/test/categoria/iksyz7wws54irlszplkw.jpg', '', 0, '', 0, 1, 0)" class="btn btn-warning" data-toggle="modal"
                                    data-target="#modal_produc"><i class="ti-plus"></i> Nuevo Producto</button>
                                            </span>
                                </div>
                           </div>
                        </div>
                    </div>
                    <!-- /.left-right-aside-column-->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row el-element-overlay">
   
    <div class="col-lg-4 col-md-6" *ngFor="let product of product_list| filter:searchText">
        <div class="card">
            <div class="el-card-item">
                <div class="el-card-avatar el-overlay-1"> <img src="{{ product.img }}" alt="user">
                    <div class="el-overlay">
                        <ul class="el-info">
                            <li><a class="btn default btn-outline image-popup-vertical-fit" data-toggle="modal"
                                data-target="#modal_produc" (click)="reset_form(product.titulo, product.descripcion, product.img, product.precio, product.productos_tipos, product.cantidad, 1, product.estado, product.id)" ><i class="icon-magnifier"></i></a></li>
                            <li><a class="btn default btn-outline" (click)="data_to_delete(product.id, product.titulo)" data-toggle="modal" data-target="#modal-eliminar"><i class="icon-trash"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div class="el-card-content">
                    <div class="card-body">
                        <h4 class="box-title">{{ product.titulo }}</h4> 
                    </div>
                    <small>
                        <div class="card-body">
                            {{ product.descripcion }}

                        </div>
                    </small>
                    Precio: Q {{ product.precio }}
                    <br>
                    Cantidad: {{ product.cantidad }}
                    <br>
                    <small *ngIf="product.estado == 1"><span class="label label-warning label-rounded">Habilitado</span></small>
                    <small *ngIf="product.estado == 2"><span class="label label-danger label-rounded">Deshabilitado</span></small>
                    
                </div>
            </div>
        </div>
    </div>
</div>



<!-- ============================================================== -->
<!--                  MODAL RESPONSIVE ELIMINAR                     -->
<!-- ============================================================== -->


<div id="modal-eliminar" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="display: none;">
    <form class="modal-dialog" [formGroup]="formDataDelete">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="mySmallModalLabel">Eliminar Categoría</h4>
                <button type="button" (click)="product_id = 0; product_name = '';" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="alert alert-warning" role="alert">
                <a href="#" class="alert-link">¡Cosas inesperadas sucederán si no lees esto!</a>
            </div>
            <div class="modal-body">
                <strong>Esta acción no se puede deshacer.</strong>
                <br>
                <p>Esto eliminará permanentemente el producto.</p>
                <p>Escriba "<strong *ngIf="product_name">{{product_name}}</strong>" para confirmar.</p>
                <div class="form-group">
                    <input type="text" class="form-control" id="recipient-name" formControlName="nombreConfirm"
                        name="nombreConfirm">
                    <div *ngIf="formDataDelete.controls.nombreConfirm.invalid && (formDataDelete.controls.nombreConfirm.dirty || formDataDelete.controls.nombreConfirm.touched)">
                        <div class="alert alert-danger" role="alert" *ngIf="formDataDelete.controls.nombreConfirm.errors.required">
                            El título es requerido
                        </div>
                    </div>
                    <div *ngIf="!formDataDelete.controls.nombreConfirm.invalid && formDataDelete.controls.nombreConfirm.value != product_name">
                        <div class="alert alert-danger" role="alert">
                            Los nombres no coinciden
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="modal-footer">
                <button type="button" (click)="product_id = 0; product_name = '';" class="btn btn-secondary text-uppercase btn-rounded"
                    data-dismiss="modal">Cerrar</button>
                <button type="submit" (click)="delete_product()" [disabled]="formDataDelete.controls.nombreConfirm.value != product_name"
                    class="btn btn-warning text-uppercase btn-rounded">Eliminar</button>
            </div>
        </div>
    </form>
</div>

<!-- ============================================================== -->
<!--                  MODAL RESPONSIVE ELIMINAR                     -->
<!-- ============================================================== -->












<!-- ============================================================== -->
<!--                  MODAL RESPONSIVE AGREGAR                      -->
<!-- ============================================================== -->
<div id="modal_produc" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="display: none;">
    <div *ngIf="product_form">
        <form class="modal-dialog modal-lg" [formGroup]="product_form" >
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="modal_producLabel1">Formulario de Producto</h4>
                    <button type="button" class="close" (click)="reset_form('', '', 'https://res.cloudinary.com/dksstmpfu/image/upload/v1609783374/test/categoria/iksyz7wws54irlszplkw.jpg', '', 0, '', 0, 1, 0)" data-dismiss="modal" aria-label="Close"><span
                            aria-hidden="true">&times;</span></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="row el-element-overlay"  style="margin-bottom: 0px !important;">
                                <div class="col-md-2">
                                </div>
                                <div class="col-md-8">
                                    <div class="card" style="margin-bottom: 0px !important;">
                                        <div *ngIf="estoy_edit == 0" class="el-card-item"  style="margin-bottom: 0px !important;">
                                            <div class="el-card-avatar el-overlay-1"  style="margin-bottom: 0px !important;"> 
                                                <img *ngIf="!file" src="https://res.cloudinary.com/dksstmpfu/image/upload/v1609783374/test/categoria/iksyz7wws54irlszplkw.jpg" alt="user" />
                                                <img *ngIf="file" src="{{strImage}}" alt="user" />
                                            </div>
                                        </div>
                                        <div *ngIf="estoy_edit==1" class="el-card-item"  style="margin-bottom: 0px !important;">
                                            <div class="el-card-avatar el-overlay-1"  style="margin-bottom: 0px !important;"> 
                                                <img  src="{{strImage}}" alt="user" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                </div>
                            </div>
                            <div class="form-group text-center">
                                <label>Foto del producto (opcional)</label>
                                <div class="fileupload btn btn-danger btn-rounded waves-effect waves-light"><span><i
                                    class="fa fa-camera"></i> Seleccionar Imagen</span>
                                    <input type="file" accept="image/*"  (change)="seleccionImage($event)"  class="upload"> 
                                </div>

                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-6">
                                        <label for="producto_name" class="control-label">Título:</label>
                                        <input type="text" formControlName="titulo" 
                                            maxlength="50"
                                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                        placeholder="Máximo 50 caracteres"
                                        onkeypress="return (event.charCode != 34 && event.charCode != 39)  "

                                        class="form-control" id="producto_name1">
                                        <div *ngIf="f.titulo.invalid && (f.titulo.dirty || f.titulo.touched)">
                                            <div *ngIf="f.titulo.errors.required" style="color:red">El título es requerido</div> 
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label for="producto_tipo" class="control-label">Tipo:</label>
                                        <select class="form-control" id="producto_tipo" formControlName="productos_tipos" >
                                            <option selected disabled>Select</option>
                                            <option  *ngFor="let tipe of product_tipe_list" value="{{ tipe.id }}"> {{ tipe.titulo }} </option>
                                        </select>
                                        <div *ngIf="f.productos_tipos.invalid && (f.productos_tipos.dirty || f.productos_tipos.touched)">
                                            <div *ngIf="f.productos_tipos.errors.required" style="color:red">El tipo es requerido</div> 
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div class="form-group">
                                <label for="producto_descripcion" class="control-label">Descripción:</label>
                                <textarea class="form-control" formControlName="descripcion" 
                                id="producto_descripcion1"
                                maxlength="150"
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                placeholder="Máximo 150 caracteres"
                                onkeypress="return (event.charCode != 34 && event.charCode != 39)  "

                                ></textarea>
                                <div *ngIf="f.descripcion.invalid && (f.descripcion.dirty || f.descripcion.touched)">
                                    <div *ngIf="f.descripcion.errors.required" style="color:red">La descripción es requerida</div> 
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="row">
                                     <div class="col-md-6">
                                        <label for="producto_precio" class="control-label">Precio:</label>
                                        <input type="number" min="1" class="form-control" formControlName="precio" id="producto_precio">
                                        <div *ngIf="f.precio.invalid && (f.precio.dirty || f.precio.touched)">
                                            <div *ngIf="f.precio.errors.required" style="color:red">El precio es requerido</div> 
                                        </div>       
                                     </div>
                                     <div class="col-md-6">
                                        <label for="producto_cant" class="control-label">Cantidad:</label>
                                        <input type="number" min="1" class="form-control" formControlName="cantidad" id="producto_cant">
                                        <div *ngIf="f.cantidad.invalid && (f.cantidad.dirty || f.cantidad.touched)">
                                            <div *ngIf="f.cantidad.errors.required" style="color:red">La cantidad es requerida</div> 
                                        </div>
                                     </div>
                                </div>
                                
                            </div>
                            
                            <div class="form-group">
                                <label for="area_estado" class="control-label">Estado:</label>
                                <br>
                                <select formControlName="estado" class="form-control" id="area_est">
                                    <option selected disabled>Select</option>
                                    <option value="1">Habilitado</option>
                                    <option value="2">No Habilitado</option>
                                </select>
                                <div *ngIf="f.estado.invalid && (f.estado.dirty || f.estado.touched)">
                                    <div *ngIf="f.estado.errors.required" style="color:red">El estado es requerido</div> 
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="btn btn-group ">
                        <button (click)="reset_form('', '', 'https://res.cloudinary.com/dksstmpfu/image/upload/v1609783374/test/categoria/iksyz7wws54irlszplkw.jpg', '', 0, '', 0, 1, 0)"  class="btn btn-outline-secondary waves-effect waves-light" type="button" data-dismiss="modal"><span class="btn-label"><i class="ti-close"></i></span>Cancelar</button>
                        <button (click)="uploadImage()" [disabled]="!file" class="btn btn-info waves-effect waves-light" type="button"><span class="btn-label"><i class="ti-gallery"></i></span>Subir Foto</button>
                        <button (click)="save_product()" [disabled]="!product_form.valid || subiendo_imagen" class="btn btn-warning waves-effect waves-light" type="button"><span class="btn-label"><i class="ti-save"></i></span>Guardar</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- ============================================================== -->
<!--                  MODAL RESPONSIVE AGREGAR                      -->
<!-- ============================================================== -->

