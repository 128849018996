import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from 'src/app/controller/alert.controller';
import { CompraService } from 'src/app/services/compra.service';
import { EventService } from 'src/app/services/event.service';


@Component({
  selector: 'app-envios',
  templateUrl: './envios.component.html'
})
export class EnviosComponent implements OnInit {
  //VARIABLES
  array_compras: any[] = []; // guarda la lista de compras del usario
  array_eventos: any[] = []; // guarda la lista de eventos donde usuario ha mandado
  user_id:       number = 0     //guarda el id del usuario logueado
  searchText:    any    //variable usada para buscar los eventos
  saltos:        any = new Array(16)
  
  constructor(
    private router: Router,
    private evento_service: EventService,
    private compra_service: CompraService) {
      this.user_id = Number(localStorage.getItem('id'))
      this.inicialize()
  }

  ngOnInit(): void {

  }


  inicialize(){
   
    //TRAIGO TODAS LAS COMRPAS
    this.compra_service.get_all().subscribe(
      (data : any[]) => {
        //FILTRO LAS COMPRAS DEL USUARIO LOGUEADO
        //VERIFICANDO SI ES USER O ADMIN        
        if (localStorage.getItem('admin') == '1') {
          this.array_compras = data
        } else {
          this.array_compras = data.filter(compra => compra.usuarios_id == this.user_id);
        }
        
        if (this.array_compras.length == 0) {
          AlertController.getInstance().show_swall('No hay datos disponibles', 'No se ha enviado productos a ningún evento', 'warning');

        } else {
          this.array_eventos = []
            
          for (let i = 0; i < this.array_compras.length; i++) {

    
            const compra = this.array_compras[i];
             
                 
             //TRAIGOS LOS EVENTOS
             this.evento_service.get_event_by_id(compra.evento_id).subscribe(
               (data: any) => {
                 if (data.length != 0) {
                  if (!(this.array_eventos.some(event => event.id == compra.evento_id))) {
                    this.array_eventos.push(data);
                    this.saltos = new Array(0)
                  }
                 }
               }
             )
           }

           setTimeout(()=> {
            if (this.array_eventos.length == 0) {
              AlertController.getInstance().show_swall('No hay datos disponibles', 'No se ha enviado productos a ningún evento', 'warning');
            }
           }, 1500)
        }
      });    
  }

}
