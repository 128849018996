import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from 'src/app/controller/alert.controller';
import { RoutesController } from 'src/app/controller/routes.controller';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'app-admin-listado-eventos',
  templateUrl: './admin-listado-eventos.component.html'
})
export class AdminListadoEventosComponent implements OnInit {

   //VARIABLES
   event_list: any = [] // contiene los eventos traidos de la base de datos
   p: number = 1;      //  sirve para hacer la paginacion
   searchText: any;    //  sirve para buscar
   mostrar_loader: boolean = false;  //sirve para mostrar el loader
   constructor( private router: Router, private event_service: EventService) {
     //Se inicializa la data
     this.inicialize();
    }
 
   ngOnInit(): void {
 
   }
 
 
   inicialize(){
     this.event_service.get_all().subscribe(data => {
       this.mostrar_loader = false
       this.event_list = data;
     })
   }
   //obtiene el id del evento a visualizar
   detail_event(id_event: number){
     localStorage.setItem('permitido', '0')
     this.router.navigate(['/evento/detalle/', id_event])
   }
 
   //Elimina el evento
   delete_event(id_event: number) {
     this.mostrar_loader = true
     this.event_service.delete_by_id(id_event).subscribe(
       (data: any)=> {
       this.inicialize()
       AlertController.getInstance().show_swall('Evento Eliminado', 'Se ha eliminado el evento correctamente', 'success')
     })
   }
 
   activ_noActiv(estado: number, id_event: number){
     if (estado == 1) {
       estado = 2
     } else {
       estado = 1
     }
     
     var data = { 
       "estado": estado,
       "fecha" : RoutesController.getInstance().get_date(),
       "hora"  : RoutesController.getInstance().get_hour()
     }
     this.event_service.put(data, id_event).subscribe(
       (data: any) => {
         this.inicialize()
         AlertController.getInstance().show_swall('Evento Actualizado', 'El estado del evento se ha actualizado', 'success')
       });
   }
 

}
